export const en = {
  faq: {
    question: "Question",
    answer: "Answer",
    added: "FAQ added",
    delete: "Delete",
    manage: "Manage Faqs",
  },
  analytics: {
    reportAndanalytics: "Reports and Analytics",
    users: "Users",
    daily: "Daily",
    monthly: "Monthly",
    yearly: "Yearly",
    weekly: "Weekly",
    exportCSV: "Export CSV",
    Select: "Select",
    loanRequest: "Loan Requests",
    csvSelect: "Please select CSV to download",
  },
  changePassword: {
    title: "Change Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    oldPasswordRequired: "Old password is required",
    newPasswordRequired: "New password is required",
    newPasswordDifferent: "New password cannot be the same as old password",
    passwordCriteria:
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character",
    passwordsMustMatch: "Passwords must match",
    confirmPasswordRequired: "Confirm password is required",
    oldPasswordPlaceholder: "Enter old password",
    newPasswordPlaceholder: "Enter new password",
    confirmPasswordPlaceholder: "Confirm your password",
    submit: "Submit",
    success: "Password changed successfully",
  },
  forgotPassword: {
    title: "Forgot Password",
    emailLabel: "Email Address",
    emailPlaceholder: "Enter your email address",
    emailRequired: "Email is required",
    emailInvalid: "Enter a valid email address",
    sendButton: "Send",
    successMessage: "Verification email sent successfully",
  },
  login: {
    title: "Log in to QFI Admin",
    emailLabel: "Email Address",
    emailPlaceholder: "Email Address",
    emailRequired: "Email is required",
    emailInvalid: "Enter a valid email address",
    passwordLabel: "Password",
    passwordPlaceholder: "Password",
    passwordRequired: "Password is required",
    passwordMinLength: "Password must be at least 6 characters",
    rememberMe: "Remember me",
    forgotPassword: "Forgot Password?",
    success: "Login Successfully.",
    error: "Error",
  },
  dashboard: {
    title: "Dashboard",
    totalCustomer: "Total Customer",
    preCustomer: "Total Prequalify Requests",
    newCustomer: "New Customer",
    totalLoanRequest: "Total Loan Requests",
    totalUser: "Total Users",
  },
  tableHeads: {
    sno: "S.No",
    fName: "Full Name",
    phone: "Phone No.",
    email: "Email",
    approvalStatus: "Approval Status",
    accountStatus: "Account Status",
    actions: "Actions",
    image: "Image",
    categoryName: "Category Name",
    status: "Status",
    tagName: "Tag Name",
    applicationNumber: "Application Number",
    vehicleName: "Vehicle Name",
    userEmail: "User Email",
    loanStatus: "Loan Status",
    loanDate: "Loan Date",
    loanType: "Loan Type",
    loanAmount: "Loan Amount",
    downPayment: "Down Payment",
    amountPaid: "Amount Paid",
    pendingAmount: "PendingAmount",
    interestRate: "Interest Rate",
    emiAmount: "EMI Amount",
    totalInstallment: "Total Installment",
    installmentPaid: "Installement Paid",
    remainingInstallment: "Remaining Installment",
    stepOneDate: "Step 1 Document upload Date",
    stepTwoDate: "Step 2 Document upload Date",
    stepOneApproveDate: "Step 1 Document Approve Date",
    stepTwoApproveDate: "Step 2 Document Approve Date",
    loanComplete: "Loan Completed At",
    carImage: "Car Image",
    name: "Name",
    category: "Category",
    tag: "Tag",
    model: "Model",
    price: "Price",
    date: "Date",
    userDetails: "User Details",
    carName: "Car Name",
    currentStatus: "Current Status",
    chatStatus: "Chat Status",
    drag: "Drag",
    criteriaName: "Criteria Name",
    factors: "Factors",
    interestRatePa: "Interest Rate (p.a)",
    fieldName: "Field Name",
    fieldType: "Field Type",
    stepType: "Step Type",
    imageType: "Image Type",
    loanTerm: "Loan Term",
    phoneNumber: "Phone Number",
    address: "Address",
    userName: "User Name",
    queries: "Queries",
    title: "Title",
    bannerImage: "Banner Image",
    bannerTitle: "Banner Title",
    notificationTitle: "Notification Title",
    notificationMessage: "Notification Message",
    role: "Role",
    paymentMode: "Payment Mode",
    viewMode: "View Mode",
    description: "Description",
    factorName: "Factor Name",
    score: "Score",
    delete: "Delete",
  },

  tabs: {
    all: "All",
    new: "New",
    existing: "Existing",
    pending: "Pending",
    approved: "Approved",
    rejected: "Rejected",
    completed: "Completed",
    admin: "Admin",
    user: "User",
    queriesRecieved: "Queries Recieved",
    complaints: "Complaints/Dispute",
    contactus: "Contact Us",
    aboutUs: "About Us",
    privacy: "Privacy Policy",
    termsAndCondition: "Terms and Conditions",
  },

  buttons: {
    addCustomer: "Add Customer",
    exportCsv: "Export CSV",
    addCategory: "Add Category",
    addTag: "Add Tag",
    addCar: "Add Car",
    clearFilter: "Clear Filter",
    addCriteriaAdmin: "Add Criteria For Admin",
    addCriteriaSignUp: "Add Criteria For SignUp",
    addInterestRate: "Add Interest Rate",
    save: "Save",
    addLoanTerm: "Add Loan Term",
    addServiceStation: "Add Service Station",
    addTestimonial: "Add Testimonial",
    addBanner: "Add Banner",
    addNotification: "Add Notification",
    addSubAdmin: "Add Sub-admin",
    addPaymentWay: "Add Payment Way",
    addPaymentDetails: "Add Payment Details",
    back: "Back",
    cancel: "Cancel",
    viewDetails: "View Details",
  },

  textFields: {
    fname: "Full Name",
    email: "Email",
    phone: "Phone",
    businessName: "Business Name",
    positionWithinCompany: "Position within the company",
    website: "Website",
    address: "Address",
    addressOne: "Address Line 1",
    addressTwo: "Address line 2",
    state: "State",
    city: "City",
    landMark: "Landmark",
    search: "Search",
    categoryName: "Category Name",
    productImage: "Add Product Image",
    selectCategory: "Select Category",
    addTag: "Add Tag",
    editTag: "Edit Tag",
    selectTag: "Select Tag",
    warrntyPeriod: "Warranty Period",
    tax: "Tax",
    documentCost: "Document Cost",
    insuranceCost: "Insurance Cost",
    minimumDownPayment: "Minimum DownPayment",
    maximumDownPayment: "Maximun DownPayment",
    selectLoanTenure: "Select Loan Tenure",
    selectInterestRate: "Select Interest Rate",
    addCriteria: "Add Criteria",
    editCriteria: "Edit Criteria",
    addInterestRate: "Add Interest Rate",
    editInterestRate: "Edit Interest Rate",
    selectStep: "Select Step",
    addLoanTerm: "Add Loan Term",
    editLoanTerm: "Edit Loan Term",
    name: "Name",
    title: "Title",
    description: "Description",
    bannerTitle: "Banner Title",
    banner: "Banner",
    notificationTitle: "Notification Title",
    notificationTitleSpanish: "Notification Title (Spanish)",
    notificationMessage: "Notification Message",
    notificationMessageSpanish: "Notification Message (Spanish)",
    selectReceiver: "Select Receiver",
    sendToAll: "Send to All",
    firstName: "First Name",
    lName: "Last Name",
    addPaymentWay: "Add Paymentway",
    miminumDays: "Minimum days limit to re-apply loan",
    minimumCredit: "Minimum Credit Score",
  },
  header: {
    manageCustomer: "Manage Customer",
    manageCategories: "Manage Categories",
    manageTags: "Manage Tags",
    loanReport: "Loan Report",
    manageCars: "Manage Cars",
    manageLoans: "Manage Loans",
    creditScore: "Credit Score",
    manageInterest: "Manage Interest",
    manageForm: "Manage Forms",
    manageLoanTerm: "Manage Loan Term",
    customerSupport: "Customer Support",
    manageTestimonials: "Manage Testimonials",
    manageBanner: "Manage Banner",
    manageNotifications: "Manage Notifications",
    manageSubAdmin: "Manage SubAdmin",
    manageFaq: "Manage Faq",
    paymentWays: "Manage Paymentways",
    serviceStation: "Manage Service Station",
    manageCms: "Manage CMS",
  },
};
