import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";

const ReviewDetails = () => {
  const navigate = useNavigate();

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Review Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-reviews");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid item xs={10} className="view_box_list">
              <Grid container spacing={3}>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Review By</Typography>
                    <Typography component="p">Abdul Hussain</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      Service Provider Name
                    </Typography>
                    <Typography component="p">John</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Rating</Typography>
                    <Rating
                      initialValue={4}
                      // onClick={(newRating: number) =>
                      //   setratingCount(newRating)
                      // }
                      iconsCount={5}
                      size={23}
                      allowHover={false}
                      readonly
                      // transition={true}
                    />
                  </Box>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <Typography component="h5">Review</Typography>
                    <Typography component="p">
                      {" "}
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit
                      dolor sit amet consectetur. Lorem ipsum dolor sit amet
                      consectetur, adipisicing elit dolor sit amet consectetur.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ReviewDetails;
