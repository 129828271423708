import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import {
  CommonBody,
  getServiceStationResponse,
  serviceStationResponse,
} from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type QueryParams = {
  page: number;
  size: number;
  search?: string;
};

export const serviceStationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addServiceStation: builder.mutation<
      CommonResponseType & { data: serviceStationResponse },
      { body: CommonBody }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.serviceStation}`,
        method: "POST",
        body,
      }),
    }),
    updateServiceStation: builder.mutation<
      CommonResponseType & { data: serviceStationResponse },
      { body: CommonBody; id: string }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.serviceStation}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    getStation: builder.query<
      CommonResponseType & { data: getServiceStationResponse },
      QueryParams
    >({
      query: ({ search, page, size }) => ({
        url: `${END_POINTS.serviceStation}?search=${search}&page=${page}&size=${size}`,
        method: "GET",
      }),
    }),
    getStationById: builder.query<
      CommonResponseType & { data: serviceStationResponse },
      {id:string}
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.serviceStation}/${id}`,
        method: "GET",
      }),
    }),
    deleteStation: builder.query<
      CommonResponseType & { data: serviceStationResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.serviceStation}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddServiceStationMutation,
  useLazyGetStationQuery,
  useUpdateServiceStationMutation,
  useLazyDeleteStationQuery,
  useLazyGetStationByIdQuery
} = serviceStationApi;
