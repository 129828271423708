import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Tabs,
  Tab,
  TextField,
  Dialog,
  FormControl,
  DialogContent,
  Select,
  MenuItem,
  SelectChangeEvent,
  Modal,
  Divider,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Checkbox,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import MainContainer from "../../layout/MainContainer";
import { DocumentViewer } from "../../components";
import DocumentDoc from "../../components/DownloadDocs";
import { LoanAPi } from "../../services/loans";
import { showError, showToast } from "../../constants/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CommonBody,
  Criteria,
  DocsRes,
  DocumentData,
  Loan,
  LoanCreditDetails,
  Statement,
  addCustomerResponse,
} from "../../types/General";
import moment from "moment";
import { ManageCustomerApi } from "../../services/manageCustomers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { isString } from "../../utils/validations";
import {
  generateResponsiveStyle,
  generateResponsiveStyleModal,
} from "../../utils/ModalStyle";
import { CreditApi } from "../../services/credit";
import Loader from "../../constants/Loader";
import Pagination from "../../components/Pagination";
import DocumentSlider from "../../components/DocumentSlider";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  //COmments

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type ScoreType = {
  criteriaId: string;
  rangeId: string;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "none", // Remove the black border
  boxShadow: 24,
  borderRadius: "10px", // Add border-radius for rounded corners
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const LoanDetails = () => {
  const location = useLocation();
  const { id } = useParams();
  const { state } = location;
  const navigate = useNavigate();

  //states
  const [value, setValue] = useState<number>(0);
  const [step, setStep] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [image, setImage] = useState<string>("");
  const [load, setLoad] = useState<boolean>(false);
  const [receipt, setReceipt] = useState<string>("");
  const [data, setData] = useState<Loan>();
  const [page, setPage] = useState<number>(1);
  const [userData, setUserData] = useState<addCustomerResponse>();
  const [docs, setDocs] = useState<DocumentData[]>([]);
  const [src, setSrc] = useState<string[]>([]);
  const [dialog, setDialog] = useState<boolean>(false);
  const [loanId, setLoanId] = useState<string>("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [creditScore, setCreditScore] = useState<string>("");
  const [statement, setStatement] = useState<Statement[]>([]);
  const [statId, setStatId] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open1, setOpen1] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  //API Hooks
  const [getById] = LoanAPi.useLazyGetLoanByIdQuery();
  const [getDocs] = LoanAPi.useLazyGetLoanDocsQuery();
  const [getUserDetails] = ManageCustomerApi.useLazyGetCustomerByIdQuery();
  const [updateStatus] = LoanAPi.useUpdateLoanMutation();
  const [updateStatement] = LoanAPi.useUpdateStatementMutation();
  const [getCriteriaAdmin] = CreditApi.useLazyGetCriteriaAdminQuery();
  const [getAmount] = LoanAPi.useLazyGetAmountStatementQuery();
  const [criterias, setCriterias] = useState<Criteria[]>([]);
  const [question, setQuestion] = useState<any[]>([]);
  const [getCriteria] = LoanAPi.useLazyGetLoanCreditQuery();
  const [details, setDetails] = useState<LoanCreditDetails>();
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [score, setScore] = useState<ScoreType[]>([
    {
      criteriaId: "",
      rangeId: "",
    },
  ]);
  console.log(creditScore, "creditScore");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: "",
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("This field is required"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = !approvalStatus
        ? {
            loanStatus: 3,
            rejectedReason: values.reason,
          }
        : { rejectedReason: values.reason, status: approvalStatus };

      console.log(body, "body");
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      if (approvalStatus) {
        try {
          const response = await updateStatement({
            body: encryptedBody,
            id: statId,
          }).unwrap();
          if (response?.statusCode === 200) {
            setStatId("");
            setApprovalStatus("");
            setDialog(false);
            formik.resetForm();
            fetchStatement(loanId);
            return;
          }
        } catch (error: any) {
          showError(error?.data?.message);
          return;
        }
      } else {
        try {
          const res = await updateStatus({
            id: loanId,
            body: encryptedBody,
          }).unwrap();
          if (res?.statusCode === 200) {
            formik.resetForm();
            showToast("Documents Rejected");
            fetchLoan(loanId);
            fetchDocs(loanId);
            setDialog(false);
            setApprovalStatus("");
            navigate("/manage-loans");
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }
    },
  });

  const PriceFormatter = (number: number) => {
    const formatter = new Intl.NumberFormat("en-US");
    const formattedNumber = formatter.format(number);

    return formattedNumber;
  };

  const fetchLoan = async (id: string) => {
    setLoad(true);
    try {
      const res = await getById({
        id: id,
      }).unwrap();
      if (res?.statusCode === 200) {
        setData(res?.data?.data[0]);
        const UserId = res?.data?.data[0]?.userId;
        fetchUser(UserId);
        setLoad(false);
      } else {
        setData(undefined);
        setLoad(false);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoad(false);
    }
  };
  const fetchDocs = async (id: string) => {
    try {
      const res = await getDocs({
        id: id,
        step: step === 0 ? 1 : 2,
      }).unwrap();
      if (res?.statusCode === 200) {
        setDocs(() => {
          return res?.data?.data?.filter((item) => item?.type !== "QUESTION");
        });
        setQuestion(() => {
          return res?.data?.data?.filter((item) => item?.type === "QUESTION");
        });
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const fetchUser = async (id: string) => {
    try {
      const res = await getUserDetails({
        id: id,
      }).unwrap();
      if (res?.statusCode === 200) {
        setUserData(res?.data || {});

        setImage(res?.data?.image || "");
      }
    } catch (error: any) {
      showError(error?.data?.manage);
    }
  };

  const fetchShow = async () => {
    let body = {
      vehicleId: data?.vehicleId,
      loanAmount: data?.totalLoanAmount,
      loanTenure: data?.tenure,
      interestRate: data?.interestRate,
    };
    console.log(body, "lllllllllllllllllll");
    const EncryptedKeyBody = generateEncryptedKeyBody(body) as CommonBody;
    try {
      const res = await getCriteria({
        body: EncryptedKeyBody,
      }).unwrap();
      if (res?.statusCode === 200) {
        setDetails(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCredit = async () => {
    try {
      const res = await getCriteriaAdmin({}).unwrap();
      if (res?.statusCode === 200) {
        const { data } = res?.data;

        setCriterias(data);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const fetchStatement = async (id: string) => {
    try {
      const res = await getAmount({
        page: page,
        id: id,
      }).unwrap();
      if (res?.statusCode === 200) {
        const { count, statement } = res?.data;
        setStatement(statement);
        setTotalCount(count);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleChangeStep = (event: React.SyntheticEvent, newValue: number) => {
    setStep(newValue);
  };

  const handleDialog = () => {
    setDialog((prev) => !prev);
    setApprovalStatus("");
    formik.resetForm();
  };

  const handleApproval = async () => {
    if (!selectedOption && data?.loanApprovedStage === 3) {
      showError("Select Interest  type ");
      return;
    }
    try {
      let body = {
        loanApprovedStage: step === 0 ? 2 : 4,
        ...(selectedOption ? { loanType: selectedOption } : ""),
        creditScoreChange : step=== 0 ? checked:checked2
      };
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await updateStatus({
        id: loanId,
        body: encryptedBody,
      }).unwrap();
      if (res?.statusCode === 200) {
        if (step === 1) {
          showToast("Documents approved");
          fetchLoan(loanId);
          fetchDocs(loanId);
          navigate("/manage-loans");
        } else {
          showToast("Documents approved");
          fetchLoan(loanId);
          fetchDocs(loanId);
        }
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const handleReceiptApproval = (event: SelectChangeEvent) => {
    const val = event.target.value;
    setApprovalStatus(val);
    if (val === "2") {
      setModalOpen((prev) => !prev);
    } else {
      setDialog(true);
    }
  };

  const handleScoreChange = (event: any, id: string) => {
    const { value } = event.target;

    setScore((prev) => {
      const existingIndex = prev.findIndex((score) => score.criteriaId === id);

      if (existingIndex !== -1) {
        return prev.map((score, index) =>
          index === existingIndex ? { ...score, rangeId: value } : score
        );
      } else {
        return [...prev, { criteriaId: id, rangeId: value }];
      }
    });
  };

  const areAllCriteriaChecked = () => {
    const criteriaIds = criterias
      .filter((item) => item.subcriteria && item.subcriteria.length > 0)
      .map((item) => item._id);
    const checkedCriteriaIds = score?.slice(1)?.map((item) => item.criteriaId);

    console.log(criteriaIds, "dssdd");
    console.log(checkedCriteriaIds, "score dssd");
    // Check if every criteriaId from criterias has a corresponding entry in the score state
    return criteriaIds.every((id) => checkedCriteriaIds.includes(id));
  };

  const receiptApproval = async () => {
    let body = {
      adminCriteriaArray: score?.slice(1),
      status: approvalStatus,
    };

    if (!areAllCriteriaChecked()) {
      showError("Please complete all criteria before submitting.");
      setApprovalStatus("");
      setModalOpen(false);

      return;
    }

    console.log(body, "bodyyyyy");
    let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
    try {
      const response = await updateStatement({
        body: encryptedBody,
        id: statId,
      }).unwrap();
      if (response?.statusCode === 200) {
        setStatId("");
        setApprovalStatus("");
        setDialog(false);
        fetchStatement(loanId);
        formik.resetForm();
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };


  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleCheckChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked2(event.target.checked);
  };
  const handleChangeInterest = (event: any) => {
    setSelectedOption(event.target.value);
  };
console.log(checked,"Checked")
  useEffect(() => {
    if (id) {
      fetchLoan(id);
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchDocs(id);
    }
  }, [step]);

  useEffect(() => {
    if (id) {
      setLoanId(id);
    }
  }, []);
  useEffect(() => {
    if (state?.step && state?.value) {
      setStep(state.step);
      setValue(state.value);
    }
  }, []);

  useEffect(() => {
    if (data) {
      fetchShow();
    }
  }, [data]);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const totalpages = Math.ceil(totalCount / 10);
  useEffect(() => {
    if (value === 0 && state.userStatus === "ACCEPTED" && loanId) {
      fetchStatement(loanId);
      fetchCredit();
    }
  }, [page, loanId]);
  console.log(receipt, "statement");
  return (
    <MainContainer>
      <Loader isLoad={load} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Loan Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-loans");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Loan Details" {...a11yProps(0)} />

                <Tab label="Document Verification" {...a11yProps(1)} />

                {/* <Tab label="Verification" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
          </Box>
          <CardContent sx={{ p: 1 }}>
            <CustomTabPanel value={value} index={0}>
              <Grid container spacing={2} className="view_box view_box_list">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Car Image</Typography>
                    <figure className="ad_img">
                      <img
                        src={
                          data?.vehicleImage
                            ? data?.vehicleImage[0]
                            : "/static/images/document.png"
                        }
                        alt="/static/images/document.png"
                      />
                    </figure>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} />
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Car Name</Typography>
                    <Typography component="p">
                      {data?.vehicleName || "---"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Category</Typography>
                    <Typography component="p">
                      {data?.categoryName || "---"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Tag</Typography>
                    <Typography component="p">
                      {data?.typeName || "---"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Price</Typography>
                    <Typography component="p">
                      ${data?.vehiclePrice || "--"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5"> Date</Typography>
                    <Typography component="p">
                      {moment(data?.createdAt).format("DD/MM/YYYY") ||
                        "---------"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Loan Status</Typography>
                    <Typography component="p">
                      {data?.loanStatus === 1
                        ? "Pending"
                        : data?.loanStatus === 2
                          ? "Approved"
                          : data?.loanStatus === 3
                            ? "Un-approved"
                            : data?.loanStatus === 4
                              ? "Completed"
                              : "---"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Down Payment</Typography>
                    <Typography component="p">
                      ${data?.downPayment || "--"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Time Interval</Typography>
                    <Typography component="p">
                      {data?.tenure || "--"}Months
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Interest Rate</Typography>
                    <Typography component="p">
                      {data?.interestRate || "--"}%
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">EMI</Typography>
                    <Typography component="p">
                      {data?.emi.toFixed(2) || "--"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Remaining amount</Typography>
                    <Typography component="p">
                      {data?.currentPendingAmount.toFixed(2) || "--"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="heading">
                    <Typography component="h5">User Details</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Username</Typography>
                    <Typography component="p">
                      {userData?.fullName || ""}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Email</Typography>
                    <Typography component="p">
                      {data?.userEmail || "------"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Phone no</Typography>
                    <Typography component="p">
                      {(data?.userCountryCode || "--") +
                        "-" +
                        (data?.userPhone || "--")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Role</Typography>
                    <Typography component="p">
                      {userData?.role === 3 ? "Business Owner" : ""}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Organization Name</Typography>
                    <Typography component="p">
                      {userData?.businessName || "---"}
                    </Typography>
                  </Box>
                </Grid>

                

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">RIF Number</Typography>
                    <Typography component="p">
                      {userData?.RIF || "--"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Address</Typography>
                    <Typography component="p">
                      {userData?.addressLine1 || "----"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">City</Typography>
                    <Typography component="p">
                      {userData?.city || "----"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Zip code</Typography>
                    <Typography component="p">
                      {userData?.zipCode || "--"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Credit Score</Typography>
                    <Typography component="p">
                      {userData?.creditScore || ""}
                    </Typography>
                  </Box>
                </Grid>

                {/* <Grid item xs={12}>
                  <Box>
                    <Typography component="h5">Documents</Typography>
                    <Box className="docs_div">
                      <figure>
                        <VisibilityIcon onClick={() => setOpen(true)} />

                        <img src="/static/images/document.png" alt="" />
                      </figure>
                      <figure>
                        <VisibilityIcon onClick={() => setOpen(true)} />
                        <img src="/static/images/document.png" alt="" />
                      </figure>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {state.userStatus === "PENDING" ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            padding: "20px 0 0",
                            fontSize: "20px",
                            color: "#1d1d1d",
                            fontWeight: "700",
                          }}
                          component="h2"
                        >
                          Approval
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                        >
                          Accept
                        </Button>
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                        >
                          Reject
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid> */}
                {/* </Grid> */}
                {/* </Grid> */}
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="custom_tabs">
                <Box className="flx_sc">
                  <Tabs
                    value={step}
                    onChange={handleChangeStep}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="basic tabs example"
                    className="custom_tabs_links"
                  >
                    <Tab label="Step 1" {...a11yProps(0)} />

                    <Tab
                      label="Step 2"
                      disabled={data?.applicationProgress === 33}
                      sx={{
                        opacity: data?.applicationProgress === 33 ? 0.5 : 1,
                      }}
                      {...a11yProps(1)}
                    />

                    {/* <Tab label="Verification" {...a11yProps(2)} /> */}
                  </Tabs>
                </Box>
              </Box>
              <CustomTabPanel value={step} index={0}>
                <Grid container spacing={2} className="view_box view_box_list">
                  {docs?.length
                    ? docs
                        .filter((item) => item.type !== "IMAGE")
                        .map((item, index) => (
                          <Grid
                            item
                            key={index}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <Box className="docs_div">
                              <Grid
                                container
                                spacing={1}
                                sx={{ marginBottom: "10px" }}
                              >
                                <Grid item xs={4}>
                                  <Typography
                                    variant="h6"
                                    className="custom_label"
                                  >
                                    {item.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    disabled
                                    variant="outlined"
                                    fullWidth
                                    className="text_field"
                                    value={item?.documentId}
                                  ></TextField>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        ))
                    : null}

                  {docs
                    ?.filter((item) => item.type === "IMAGE")
                    ?.map((item, index) => (
                      <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
                        <Box className="docs_div">
                          <Grid
                            container
                            spacing={1}
                            sx={{ marginBottom: "5px" }}
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "20px",
                                  fontWeight: 600,
                                  marginTop: "16px",
                                }}
                              >
                                {item?.name || "-"}
                              </Typography>
                            </Grid>
                            {/* Rendering front image if available */}
                            {item?.frontImage && (
                              <Grid item xs={4}>
                                <figure onClick={()=>{
                                  setOpen(true);
                                  setSrc(item?.multipleImages);
                                }}>
                                  <Typography
                                    variant="h6"
                                    className="custom_label"
                                  >
                                    Front Image
                                  </Typography>
                                  <VisibilityIcon
                                    onClick={() => {
                                      setOpen(true);
                                      setSrc([item?.frontImage]);
                                    }}
                                  />
                                  <img
                                    src={item.frontImage}
                                    alt={item.name || "Front Image"}
                                  />
                                </figure>
                              </Grid>
                            )}
                            {/* Rendering back image if available */}
                            {item?.backImage && (
                              <Grid item xs={4}>
                                <figure onClick={()=>{
                                  setOpen(true);
                                  setSrc(item?.multipleImages);
                                }}>
                                  <Typography
                                    variant="h6"
                                    className="custom_label"
                                  >
                                    Back Image
                                  </Typography>
                                  <VisibilityIcon
                                    onClick={() => {
                                      setOpen(true);
                                      setSrc([item?.backImage]);
                                    }}
                                  />
                                  <img
                                    src={item.backImage}
                                    alt={item.name || "Back Image"}
                                  />
                                </figure>
                              </Grid>
                            )}
                            {/* Rendering first image from multiple images if available */}
                            {item?.multipleImages.length > 0 && (
                              <Grid item xs={4} sx={{cursor:"pointer"}}>
                                <figure onClick={()=>{
                                  setOpen(true);
                                  setSrc(item?.multipleImages);
                                }}>
                                  <Typography
                                    variant="h6"
                                    className="custom_label"
                                    sx={{ marginTop: "20px" }}
                                  >
                                    Document Image (+{item?.multipleImages?.length})
                                  </Typography>
                                  <VisibilityIcon
                                    onClick={() => {
                                      setOpen(true);
                                      setSrc(item?.multipleImages);
                                    }}
                                  />
                                  <img
                                    src={item.multipleImages[0]}
                                    alt={item.name || "Document Image"}
                                  />
                                </figure>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                    ))}
                  {question?.length ? (
                    <Grid item xs={12} marginTop={10}>
                      <Typography
                        style={{
                          marginBottom: "5px",
                          // marginTop: "15px",
                        }}
                      >
                        {" "}
                        Question and Answers{" "}
                      </Typography>
                      <div>
                        {question?.length
                          ? question?.map((item, index) => (
                              <>
                                <Accordion defaultExpanded={index === 0}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >
                                    <Typography
                                      component="p"
                                      sx={{
                                        fontWeight: "600",
                                      }}
                                    >
                                      Q . {item?.questionName || ""}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {item?.answerName || ""}
                                  </AccordionDetails>
                                </Accordion>
                              </>
                            ))
                          : null}
                      </div>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} marginTop={5}>
                  <FormControlLabel
                  label="Will these question impact credit score or not"
                  control={

                    <Checkbox
                      checked={checked}
                      onChange={handleCheckChange}
                      inputProps={{ "aria-label": "controlled" }}
                      
                    />
                  }
                  />
                  </Grid>
                  {/* Rendering approval buttons */}
                  <Grid item xs={12}>
                    {state.userStatus === "PENDING" &&
                    data?.loanApprovedStage === 1 ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              padding: "20px 0 0",
                              fontSize: "20px",
                              color: "#1d1d1d",
                              fontWeight: "700",
                            }}
                            component="h2"
                          >
                            Approval
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Button
                            size="large"
                            type="submit"
                            className="btn btn_primary"
                            onClick={handleApproval}
                          >
                            Accept
                          </Button>
                          <Button
                            size="large"
                            type="submit"
                            className="btn btn_primary"
                            onClick={() => setDialog(true)}
                          >
                            Reject
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography
                        sx={{
                          padding: "20px 0 0",
                          fontSize: "20px",
                          color: "#1d1d1d",
                          fontWeight: "700",
                        }}
                        component="h2"
                      >
                        {data?.loanStatus === 3
                          ? " Documents Unapproved"
                          : " Documents Approved"}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={step} index={1}>
                <Grid container spacing={2} className="view_box view_box_list">
                  {docs?.length
                    ? docs
                        .filter((item) => item.type !== "IMAGE")
                        .map((item, index) => (
                          <Grid
                            item
                            key={index}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <Box className="docs_div">
                              <Grid
                                container
                                spacing={1}
                                sx={{ marginBottom: "10px" }}
                              >
                                <Grid item xs={4}>
                                  <Typography
                                    variant="h6"
                                    className="custom_label"
                                  >
                                    {item.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    disabled
                                    variant="outlined"
                                    fullWidth
                                    className="text_field"
                                    value={item?.documentId}
                                  ></TextField>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        ))
                    : null}

                  {docs
                    ?.filter((item) => item.type === "IMAGE")
                    ?.map((item, index) => (
                      <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
                        <Box className="docs_div">
                          <Grid
                            container
                            spacing={1}
                            sx={{ marginBottom: "10px" }}
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "20px",
                                  fontWeight: 600,
                                  marginTop: "16px",
                                }}
                              >
                                {item?.name || "-"}
                              </Typography>
                            </Grid>
                            {/* Rendering front image if available */}
                            {item?.frontImage && (
                              <Grid item xs={4}>
                                <figure>
                                  <Typography
                                    variant="h6"
                                    className="custom_label"
                                  >
                                    Front Image
                                  </Typography>
                                  <VisibilityIcon
                                    onClick={() => {
                                      setOpen(true);
                                      setSrc([item?.frontImage]);
                                    }}
                                  />
                                  <img
                                    src={item.frontImage}
                                    alt={item.name || "Front Image"}
                                  />
                                </figure>
                              </Grid>
                            )}
                            {/* Rendering back image if available */}
                            {item?.backImage && (
                              <Grid item xs={4}>
                                <figure>
                                  <Typography
                                    variant="h6"
                                    className="custom_label"
                                  >
                                    Back Image
                                  </Typography>
                                  <VisibilityIcon
                                    onClick={() => {
                                      setOpen(true);
                                      setSrc([item?.backImage]);
                                    }}
                                  />
                                  <img
                                    src={item.backImage}
                                    alt={item.name || "Back Image"}
                                  />
                                </figure>
                              </Grid>
                            )}
                            {/* Rendering first image from multiple images if available */}
                            {item?.multipleImages.length > 0 && (
                              <Grid item xs={4}>
                                <figure>
                                  <Typography
                                    variant="h6"
                                    className="custom_label"
                                    sx={{ marginTop: "20px" }}
                                  >
                                    Document Image
                                  </Typography>

                                  <VisibilityIcon
                                    onClick={() => {
                                      setOpen(true);
                                      setSrc(item?.multipleImages);
                                    }}
                                  />
                                  <img
                                    src={item.multipleImages[0]}
                                    alt={item.name || "Document Image"}
                                  />
                                </figure>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                    ))}
                  {question?.length ? (
                    <Grid item xs={12} marginTop={10}>
                      <Typography
                        style={{
                          marginBottom: "10px",
                          marginTop: "15px",
                        }}
                      >
                        {" "}
                        Question and Answers{" "}
                      </Typography>
                      <div>
                        {question?.length
                          ? question?.map((item, index) => (
                              <>
                                <Accordion defaultExpanded={index === 0}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >
                                    <Typography
                                      component="p"
                                      sx={{
                                        fontWeight: "600",
                                      }}
                                    >
                                      Q . {item?.questionName || ""}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {item?.answerName || ""}
                                  </AccordionDetails>
                                </Accordion>
                              </>
                            ))
                          : null}
                      </div>
                    </Grid>
                  ) : null}
                   <Grid item xs={12} marginTop={5}>
                  <FormControlLabel
                  label="Will these question impact credit score or not"
                  control={

                    <Checkbox
                      checked={checked2}
                      onChange={handleCheckChange2}
                      inputProps={{ "aria-label": "controlled" }}
                      
                    />
                  }
                  />
                  </Grid>
                  <Grid item xs={12}>
                    {state.userStatus === "PENDING" &&
                    docs?.length > 0 &&
                    data?.loanApprovedStage === 3 ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              padding: "20px 0 0",
                              fontSize: "20px",
                              color: "#1d1d1d",
                              fontWeight: "700",
                            }}
                            component="h2"
                          >
                            Approval
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Button
                            size="large"
                            type="submit"
                            className="btn btn_primary"
                            onClick={() => setOpen1(true)}
                          >
                            Accept
                          </Button>
                          <Button
                            size="large"
                            type="submit"
                            className="btn btn_primary"
                            onClick={() => setDialog(true)}
                          >
                            Reject
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Typography
                        variant="h2"
                        sx={{
                          padding: "20px 0 0",
                          fontSize: "20px",
                          color: "#1d1d1d",
                          fontWeight: "700",
                        }}
                        className="custom_label"
                      >
                        {state.userStatus === "ACCEPTED"
                          ? "Loan Approved"
                          : state.userStatus === "PENDING"
                            ? "No docuements found"
                            : state.userStatus === "REJECTED"
                              ? "Loan Rejected"
                              : null}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CustomTabPanel>
            </CustomTabPanel>
          </CardContent>
        </Card>

        {state.userStatus === "ACCEPTED" && value === 0 ? (
          <Card sx={{ mt: 4 }} className="cards">
            <Box className="custom_tabs">
              <h2 className="mn_hdng">EMI Statements</h2>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      {/* <TableCell align="center">Image</TableCell> */}
                      <TableCell>Car Name</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Total Amount</TableCell>
                      <TableCell>Loan Duration(Years)</TableCell>
                      <TableCell>Interest Rate (PA)</TableCell>
                      <TableCell>Remaining amount</TableCell>
                      <TableCell>Amount Paid</TableCell>
                      {/* <TableCell>Remaining Amount</TableCell> */}
                      <TableCell>Approval Status</TableCell>
                    </TableRow>
                  </TableHead>
                  {statement?.length ? (
                    statement?.map((item, index) => (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell>{item?.loanDetail?.vehicleName}</TableCell>
                          <TableCell>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell>
                            $
                            {PriceFormatter(
                              Number(
                                item?.loanDetail?.totalLoanAmount.toFixed(2)
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {item?.loanDetail?.tenure || ""}
                          </TableCell>
                          <TableCell>
                            {item?.loanDetail?.interestRate || ""}%
                          </TableCell>
                          <TableCell>
                            {item?.remainingAmount > 0
                              ? "$" + item?.remainingAmount.toFixed(2)
                              : "-" || "-"}
                          </TableCell>
                          <TableCell>
                            ${PriceFormatter(item?.amountPaid)}
                          </TableCell>
                          {/* <TableCell>
                            $
                            {item?.remainingAmount.toFixed(
                              2
                            ) || ""}
                          </TableCell> */}
                          <TableCell>
                            {item?.status === 1 ? (
                              <Select
                                className="select_div "
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={approvalStatus}
                                onChange={(event) => {
                                  handleReceiptApproval(event);
                                  setReceipt(item?.image);
                                  setStatId(item?._id);
                                }}
                                displayEmpty
                              >
                                <MenuItem value="" disabled>
                                  Select
                                </MenuItem>
                                <MenuItem value="2">Approve</MenuItem>
                                <MenuItem value="3">Unapproved</MenuItem>
                              </Select>
                            ) : item?.status === 2 ? (
                              "Accepted"
                            ) : (
                              "Rejected"
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        No Transactions Found
                      </TableCell>
                    </TableBody>
                  )}
                  {/* <TableBody>
                    <TableRow>
                      <TableCell align="center">{1}</TableCell>

                      <TableCell>G63</TableCell>
                      <TableCell>02/7/23</TableCell>
                      <TableCell>$30000</TableCell>
                      <TableCell>5</TableCell>
                      <TableCell>9%</TableCell>
                      <TableCell>$20000</TableCell>
                      <TableCell>$10000</TableCell>
                      <TableCell>
                        <Select
                          className="select_div "
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={approvalStatus}
                          onChange={(event) => handleReceiptApproval(event)}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          <MenuItem value="2">Approve</MenuItem>
                          <MenuItem value="3">Unapproved</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>In-progress</TableCell>
                    </TableRow>
                  </TableBody> */}
                </Table>
              </TableContainer>
            </Box>
            <Pagination
              module={statement}
              page={page}
              totalPages={totalpages}
              onPageChange={onPageChange}
            />
          </Card>
        ) : null}

        <Dialog open={dialog} onClose={handleDialog} className="revert_dialog">
          <DialogContent>
            <Typography className="custom_label">
              Reason for rejection
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <FormControl fullWidth>
                <TextField
                  className="text_field"
                  hiddenLabel
                  autoFocus
                  id="reason"
                  type="text"
                  name="reason"
                  multiline
                  minRows={1}
                  maxRows={4}
                  onBlur={formik.handleBlur}
                  value={formik.values.reason}
                  placeholder="Reason"
                  inputProps={{ maxLength: 250 }}
                  fullWidth
                  helperText={formik.touched.reason && formik.errors.reason}
                  onChange={(val) => {
                    if (val.target.value === "." || val.target.value === " ") {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                />
              </FormControl>
              <Box className="form_btn">
                <Button className="btn btn_primary" type="submit">
                  Send
                </Button>
                <Button className="btn btn_primary" onClick={handleDialog}>
                  Cancel
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>

        {/* <DocumentViewer open={open} setOpen={setOpen} src={src} /> */}
        <DocumentSlider open={open} setOpen={setOpen} images={src} />

        <Modal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
            setApprovalStatus("");
          }}
          className="modalStyle"
        >
          <Box sx={generateResponsiveStyleModal}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <figure
                  style={{ height: "100%", width: "100%", margin: "0px" }}
                >
                  <img
                    src={receipt}
                    alt="Image"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </figure>
              </Grid>
              {/* <Grid item xs={1}>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    borderRightWidth: 2,
                    borderRightColor: "gray",
                    borderRightStyle: "solid",
                  }}
                />
              </Grid> */}
              <Grid
                item
                xs={6}
                sx={{
                  maxHeight: "calc(100vh - 200px)",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {criterias?.map((item, index) =>
                  item?.subcriteria?.length ? (
                    <FormControl key={item?._id} sx={{ marginBottom: "16px" }}>
                      <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                        {item?.subcriteria?.length ? item?.criteriaName : null}
                      </FormLabel>
                      <RadioGroup
                        onChange={(e) => handleScoreChange(e, item?._id)}
                      >
                        {item?.subcriteria?.length
                          ? item?.subcriteria?.map((itx, ind) => (
                              <FormControlLabel
                                key={itx?._id}
                                value={itx?._id}
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight: "400",
                                      color: "black",
                                    }}
                                  >
                                    {itx?.range}
                                  </span>
                                }
                              />
                            ))
                          : null}
                      </RadioGroup>
                    </FormControl>
                  ) : null
                )}
              </Grid>
              <Grid
                item
                xs={12}
                alignItems={"center"}
                display="flex"
                justifyContent="center"
              >
                <Button
                  // fullWidth
                  sx={{
                    minWidth: "300px",
                  }}
                  className="btn btn_primary"
                  onClick={() => {
                    receiptApproval();
                    setModalOpen((prev) => !prev);
                  }}
                >
                  Approve
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={open1}
          onClose={() => setOpen1(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                sx={{
                  marginBottom: 2,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Choose an option
              </FormLabel>
              <RadioGroup
                row // this makes the radio buttons appear side by side
                aria-label="options"
                name="options"
                value={selectedOption}
                onChange={handleChangeInterest}
              >
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label={
                        <div>
                          <strong>Simple Interest</strong>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>EMI:</span>{" "}
                            <span>{details?.simpleEMI || ""}</span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>Interest:</span>{" "}
                            <span>{details?.simpleInterest || ""}</span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>Total Amount:</span>{" "}
                            <span>{details?.simpleTotalPay || ""}</span>
                          </div>
                        </div>
                      }
                      labelPlacement="bottom"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label={
                        <div>
                          <strong>Compound Interest</strong>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>EMI:</span>{" "}
                            <span>{details?.compoundEMI || ""}</span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>Interest:</span>{" "}
                            <span>{details?.compoundInterest || ""}</span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>Total Amount:</span>{" "}
                            <span>{details?.compoundTotalPay || ""}</span>
                          </div>
                        </div>
                      }
                      labelPlacement="bottom"
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
            <Button
              className="btn btn_primary"
              variant="contained"
              color="primary"
              onClick={handleApproval}
              sx={{
                mt: 2,
                width: "100%",
                borderRadius: "5px",
                padding: "10px 0",
                fontSize: "16px",
              }}
            >
              Submit
            </Button>
          </Box>
        </Modal>
      </div>
    </MainContainer>
  );
};

export default LoanDetails;
