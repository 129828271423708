import { showError, showToast } from "../constants/toast";
import { CommonBody, Tags } from "../types/General";
import { generateEncryptedKeyBody } from "./crypto";

export const handleDelete = async (
  deleteById: any,
  selectedId: string,
  getAllData: () => void
) => {
  console.log(selectedId, "selectedId");

  if (selectedId) {
    try {
      const response = await deleteById({ id: selectedId }).unwrap();
      if (response?.statusCode === 200) {
        showToast(`Deleted Successfully`);
        if (getAllData) {
          getAllData(); // Assuming getAllData is a function to fetch updated data
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  }
};
export const handleDeleteStories = async (
  deleteById: any,
  selectedId: string,
  Id: string | undefined,
  getAllData: () => void
) => {
  console.log(selectedId, "selectedId");

  if (selectedId) {
    try {
      const response = await deleteById({ id: selectedId }).unwrap();
      if (response?.statusCode === 200) {
        showToast(`Deleted Successfully`);
        if (getAllData) {
          getAllData(); // Assuming getAllData is a function to fetch updated data
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  }
};
// export const updateStatus = async (
//   id: string,
//   status: boolean,
//   updateStatusMutation: any,
//   setData: any,
//   data: any,
//   getTags:()=>void
// ) => {
//   console.log(id, "ididid");
//   console.log(status, "sss");
//   const response = await updateStatusMutation({
//     id,
//     // body: encryptedBody,
//   }).unwrap();
//   console.log(response);
//   if (response?.statusCode === 200) {
    
//     const updatedData = data.map((x: any) => {
//       if (x._id === id) {
//         return {
//           ...x,
//           isBlocked: status,
//         };
//       }
//       return x;
//     });
//     console.log(updatedData, "dad");
//     setData(updatedData);
//     // showToast("Status updated successfully");
//     if (!status) {
//       console.log("status: ", status);
//       getTags()
//       showToast("Enabled successfully");
//     } else {
//       getTags()
//       showToast("Disabled successfully");
//     }
//   } else {
//     showError("Please try again");
//   }
// };

export const updateStatus = async (
  id: string,
  status: boolean,
  updateStatusMutation: any,
 
  getTags:()=>void
) => {
  const body={
    isBlocked:status
  }
  console.log(body,"boggty")
  let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
  const res = await updateStatusMutation({
    id: id,
    body: encryptedBody,
  }).unwrap();
  if (res?.statusCode === 200) {
  

    if (!status) {
      console.log("status: ", status);
      getTags()
      showToast("Enabled successfully");
      
    } else {
      getTags()
      showToast("Disabled successfully");
    }
  } else {
    showError("Please try again");
  }
};

export const updateStatusChat = async (
  id: string,
  status: boolean,
  updateStatusMutation: any,
 
  getTags:()=>void
) => {
  const body={
    chatEnabled:status
  }
  console.log(body,"boggty")
  let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
  const res = await updateStatusMutation({
    id: id,
    body: encryptedBody,
  }).unwrap();
  if (res?.statusCode === 200) {
  

    if (!status) {
      console.log("status: ", status);
      getTags()
      showToast("Disabled successfully");
      
    } else {
      getTags()
      showToast("Enabled successfully");
    }
  } else {
    showError("Please try again");
  }
};

export const updateStatusFactors = async (
  id: string,
  status: boolean,
  updateStatusMutation: any,
 
  getTags:any
) => {
  const body={
    isBlocked:status
  }
  console.log(body,"boggty")
  let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
  const res = await updateStatusMutation({
    id: id,
    body: encryptedBody,
  }).unwrap();
  if (res?.statusCode === 200) {
  

    if (!status) {
      console.log("status: ", status);
      getTags(id)
      showToast("Enabled successfully");
      
    } else {
      getTags(id)
      showToast("Disabled successfully");
    }
  } else {
    showError("Please try again");
  }
};

export const handleDeleteFactors = async (
  deleteById: any,
  selectedId: string,
  parent:string,
  getByID: (id: string) => Promise<any>
) => {
  console.log(selectedId, "selectedId");

  if (selectedId) {
    try {
      const response = await deleteById({ id: selectedId }).unwrap();
      if (response?.statusCode === 200) {
        showToast(`Deleted Successfully`);
        if (getByID) {
         await getByID(parent)
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error,"del")
    }
  }
};


export const updateApprovalStatus = async (
  id: string,
  status: boolean,
  updateStatusMutation: any,
 
  getTags:()=>void
) => {
  const body={
    isApproved:status
  }
  console.log(body,"boggty")
  let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
  const res = await updateStatusMutation({
    id: id,
    body: encryptedBody,
  }).unwrap();
  if (res?.statusCode === 200) {
  

    if (status) {
      console.log("status: ", status);
      getTags()
      showToast("Approved  successfully");
      
    } else {
      getTags()
      showToast("Unapproved successfully");
    }
  } else {
    showError("Please try again");
  }
};