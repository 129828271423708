import { Box, Button, Modal, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { generateResponsiveStyle } from "../utils/ModalStyle";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleDelete: () => void;
  name: string;
};

const WarnModal = ({ open, setOpen, handleDelete, name }: props) => {
  const style = generateResponsiveStyle();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="logout_mdl"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", fontSize: "20px", color: "#1c6975" }}
        >
          Are you sure you want to delete this {name}?
        </Typography>

        <div className="flexdiv">
          <Button
            className="yesBtn"
            sx={{ color: "#252525", border: "1px solid #252525" }}
            onClick={() => {
              setOpen(false);
              handleDelete();
            }}
          >
            Yes
          </Button>

          <Button
            sx={{ color: "#252525", border: "1px solid #252525" }}
            onClick={() => setOpen(false)}
          >
            No
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default WarnModal;
