import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

type PostVerifyOtpResponse = {
    
    data: User;
};
type PostChangePasswordResponse = {
    statusCode: number;
    message: string;
    data: any;
};
type PostChangePasswordBody = {
    oldPassword: string;
    password: string;
};
type PostSetPassword = {
    message: string;
};
type forgotPassword = {
    key: string;
};
type forgotPasswordResponse = {
    message:string;
};
type PostSetPasswordResponse = {
    statusCode: number;
    message: string;
    data: any;
};
type PostVerifyOtpBody = {
    key: string;
    code: string;
};

export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({

        login:builder.mutation<
        CommonResponseType &{data:User},
        {body:CommonBody}
        >({
            query:({body})=>({
                url:`${END_POINTS.login}`,
                method:"POST",
                body
            })
        }),

        forgotPassword:builder.mutation<
        CommonResponseType &{data:forgotPasswordResponse},
        {body:CommonBody}
        >({
            query:({body})=>({
                url:`${END_POINTS.forgot}`,
                method:"POST",
                body
            })
        }),
        otp:builder.mutation<
        CommonResponseType &{data:User},
        {body:CommonBody}
        >({
            query:({body})=>({
                url:`${END_POINTS.otp}`,
                method:"POST",
                body
            })
        }),
        setPassword:builder.mutation<
        CommonResponseType &{data:PostSetPassword},
        {body:CommonBody}
        >({
            query:({body})=>({
                url:`${END_POINTS.setPassword}`,
                method:"POST",
                body
            })
        }),
        changePassword:builder.mutation<
        CommonResponseType &{data:any},
        {body:CommonBody}
        >({
            query:({body})=>({
                url:`${END_POINTS.changePassword}`,
                method:"POST",
                body
            })
        }),
        logout:builder.query<
        CommonResponseType &{data:any},
        {}
        >({
            query:({})=>({
                url:`${END_POINTS.logout}`,
                method:"GET",
                
            })
        }),
      

       

       

      


        



        

    })
})

export const {
    useLoginMutation,
    useForgotPasswordMutation,
    useOtpMutation,
    useSetPasswordMutation,
    useChangePasswordMutation,
    useLazyLogoutQuery
    
}=authApi

