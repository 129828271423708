import {
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
    SelectChangeEvent,
  } from "@mui/material";
  import MainContainer from "../../layout/MainContainer";
  import { useNavigate } from "react-router-dom";
  
  import { useState } from "react";
  
  const AddInterest = () => {
    const navigate = useNavigate();
  
    return (
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Add Interest Rate</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-interest");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">Interest Rate</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Interest Rate"
                    />
                  </Grid>
                  
                </Grid>
                <div className="form_btn">
                  <Button size="large" type="submit" className="btn btn_primary">
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    );
  };
  
  export default AddInterest;
  