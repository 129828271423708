import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { AddBanerRes, BannerResponse, CMS, CommonBody, FAQ, FAQResponse } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
    statusCode: number;
    message: string;
};
type QueryParams = {
    page: number;
    size: number;
    search: string;
  };


export const cmsApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({


      addCms:builder.mutation<
      CommonResponseType & {data:any},
      {body:CommonBody,id:string}>({
        query:({body,id})=>({
            url:`${END_POINTS.cms}`,
            method:"PUT",
            body
        })
      }),

      getCms:builder.query<
      CommonResponseType & {data:CMS},
      {}
      >({
        query:({})=>({
            url:`${END_POINTS.cms}`,
            method:"GET"
        })
      })
    

       

       

      


        



        

    })
})

export const {
useAddCmsMutation,
useLazyGetCmsQuery

    
}=cmsApi

