import React, { useEffect, useRef, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Card } from "@mui/material";
import SearchBar from "../../components/SearchBar";
import { ChatDetail } from "../../components";
import { showError } from "../../constants/toast";
import {
  useLazyGetChatByIdQuery,
  useLazyGetChatRoomQuery,
} from "../../services/chats";
import { ChatRooms, Message } from "../../types/General";
import moment from "moment";
import { socket } from "../../utils/socket";
import Loader from "../../constants/Loader";
import { useLocation } from "react-router-dom";

type recieveMessage = {
  _id: string;
  type: "TEXT" | "IMAGE" | "DOC"; // Since the type is always "TEXT" in the provided data, you can use a literal type here.
  clearedBy: string[]; // Assuming this is an array of user IDs who have cleared the message.
  isReaded: boolean; // Note: Typically, this would be 'isRead' to follow proper English.
  isDeleted: boolean;
  isBlocked: boolean;
  loanId: string;
  media: string;
  senderType: "user" | "admin"; // Assuming it can be either "user" or "system".
  roomId: string;
  senderId: string;
  text: string;
  createdAt: string; // Could also use Date if you plan to parse this string into a Date object.
  updatedAt: string; // Same as above.
  __v: number;
};

const ManageChats = () => {
  const location = useLocation();
  const { state } = location;
  const [getChatList, { isLoading }] = useLazyGetChatRoomQuery();
  const [getChatById] = useLazyGetChatByIdQuery();
  const [chatList, setChatList] = useState<ChatRooms[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ChatDetails, setChatDetails] = useState<Message[]>([]);
  const [loanId, setLoanId] = useState<string>(state?.loanId || "");
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState<string>("");
  const [load, setLoad] = useState(false);
  const [headerName, setHeaderName] = useState<string>(
    state?.name + " " + "#(" + state?.loanNumber + ")" || ""
  );
  const [headerImage, setHeaderImage] = useState<string>(state?.image || "");
  const [fileType, setFileType] = useState<string>("");
  const [doc, setDoc] = useState("");
  const [file, setFile] = useState<string>("");

  console.log(state, "chatListOpen");

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getChats = async () => {
    setLoad(true);
    try {
      const res = await getChatList({
        page: page,
      }).unwrap();
      if (res?.statusCode === 200) {
        setChatList(res?.data?.chatRooms || []);
        setLoad(false);
      } else {
        setChatList([]);
        setLoad(false);
      }
    } catch (error: any) {
      console.log(error);
      showError("");
      setLoad(false);
    }
  };

  const getChatsById = async (id: string) => {
    setLoading(true);
    try {
      const res = await getChatById({
        id: id,
        page: page,
      }).unwrap();
      if (res?.statusCode === 200) {
        const { messages, count } = res?.data;
        console.log(messages, "mesage");
        // const reversed = messages?.reverse()
        // console.log(reversed, "chats");
        setChatDetails(messages);
        setLoading(false);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false);
    }
  };

  const joinRoom = () => {
    console.log("chala");

    const data = {
      loanId: loanId,
    };

    socket?.emit("connectToChat", data);
    socket?.on("connectToChatOk", async (message: any) => {
      console.log(message, "===room join====");
    });
  };

  const leaveRoom = async () => {
    console.log("left bye bye");

    const data = {
      loanId: loanId,
    };

    socket?.emit("disConnectToChat", data);
    socket?.on("disConnectToChatOk", async (message: any) => {
      console.log(message, "===room left====");
    });
  };

  const sendMessage = async () => {
    console.log(doc, "DOCCCC");
    if (!text && !doc) {
      return;
    }
    console.log(fileType, "fileType");
    let body = {
      loanId: loanId,
      message: text,
      type:
        fileType === "IMAGE" ? "IMAGE" : fileType === "DOC" ? "DOC" : "TEXT",
      ...(fileType ? { media: doc } : null),
    };
    console.log(body, "bodysg");
    const data = ChatDetails?.filter(
      (item) => item?.senderType === "admin"
    ) as Message[];
    console.log(data, "data filter");
    const adminData =
      data.length > 0
        ? data[0]
        : {
            ...data[0],
            senderType: "admin",
          };
    const newChat = {
      ...adminData,
      text: text,

      type:
        fileType === "IMAGE" ? "IMAGE" : fileType === "DOC" ? "DOC" : "TEXT",
      ...(doc ? { media: doc } : null),
      createdAt: new Date().toISOString(),
    };
    console.log(newChat, "new Chat");

    if (socket) {
      socket?.emit("sendMessage", body);
      if (body) {
        setChatDetails((prev) => [...prev, newChat]);
        setText("");
        setDoc("");
        setFileType("");
        setFile("");
      }
    }
  };

  const receiveMessage = async () => {
    if (socket) {
      socket?.on("receiveMessage", (message: recieveMessage) => {
        console.log(message, "message>>>>>");
        if (message?.senderType === "user") {
          setChatDetails((prev) => [...prev, message]);
        }
        // setMessages(prevMessages => [message, ...prevMessages]);
      });
    }
  };

  useEffect(() => {
    getChats();
  }, []);

  useEffect(() => {
    getChatsById(loanId);
    joinRoom();
  }, [loanId]);

  useEffect(() => {
    receiveMessage();
  }, [socket]);

  console.log(chatList, "chatlist");

  return (
    <MainContainer>
      <Loader isLoad={load} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Chats</h1>
        </div>
        <Card className="cards">
          <div className="pMain" style={{ alignItems: "normal" }}>
            <div className="pLeft" style={{ padding: 10 }}>
              <div className="tabs">{/* <SearchBar /> */}</div>
              <ul className="chat">
                {chatList?.length
                  ? chatList?.map((item) => (
                      <li
                        key={item?._id}
                        onClick={() => {
                          leaveRoom();
                          setLoanId(item?.loanId);
                          setHeaderName(
                            item?.VehicleName + " " + "#(" + item?.loanNo + ")"
                          );
                          setHeaderImage(
                            item?.user?.image || item?.VehicleImage
                          );
                        }}
                      >
                        <figure>
                          <img
                            src={item?.user?.image || item?.VehicleImage}
                            alt=""
                          />
                        </figure>
                        <h5>
                          {moment(item?.lastMessage?.createdAt).format("HH:MM")}
                        </h5>
                        <div className="contnt">
                          <h3>
                            {item?.VehicleName +
                              " " +
                              "#(" +
                              item?.loanNo +
                              ")"}
                          </h3>
                          <p>
                            {item?.lastMessage?.type === "TEXT"
                              ? item?.lastMessage?.text
                              : item?.lastMessage?.type === "IMAGE"
                                ? "shared a image"
                                : item?.lastMessage?.type === "DOC"
                                  ? "shared a document"
                                  : ""}
                          </p>
                        </div>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
            <div className="pRyt" style={{ padding: 30 }}>
              {loanId ? (
                <ChatDetail
                  data={ChatDetails}
                  loading={loading}
                  setData={setChatDetails}
                  text={text}
                  setText={setText}
                  sendMessage={sendMessage}
                  headerName={headerName}
                  headerImage={headerImage}
                  fileType={fileType}
                  setFileType={setFileType}
                  setDoc={setDoc}
                  file={file}
                  setFile={setFile}
                  doc={doc}
                />
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <figure
                    style={{
                      height: "70px",
                      width: "60px",
                      paddingBottom: "10px",
                    }}
                  >
                    {/* <img
                      src="/images/misc/chat.png"
                      alt="chat"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    /> */}
                  </figure>
                  Go ahead to see conversations
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageChats;
