import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, QueryResponse } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type QueryParams = {
  page: number;
  size: number;
  search: string;
  type?: number;
};

export const customerSupportApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getQueries: builder.query<
      CommonResponseType & { data: QueryResponse },
      { type: number }
    >({
      query: ({ type }) => ({
        url: `${END_POINTS.support}?type=${type}`,
        method: "GET",
      }),
    }),
    deleteQuery: builder.query<
      CommonResponseType & { data: QueryResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.support}/${id}`,
        method: "DELETE",
      }),
    }),
    revertQuery: builder.mutation<
      CommonResponseType & { data: QueryResponse },
      { id: string; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.reply}/${id}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetQueriesQuery,
  useLazyDeleteQueryQuery,
  useRevertQueryMutation,
} = customerSupportApi;
