import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";


import { DocumentViewer } from "../../components";
import { useParams } from "react-router-dom";
import { addCustomerResponse } from "../../types/General";
import { ManageCustomerApi } from "../../services/manageCustomers";
import { showError } from "../../constants/toast";
import Loader from "../../constants/Loader";
import moment from "moment";

const Details = () => {
  const [open,setOpen]=useState<boolean>(false)
  const{id}=useParams()
  const [image, setImage] = useState<string>("");
  const [data,setData]=useState<addCustomerResponse>()
  const [getById,{isLoading}]=ManageCustomerApi.useLazyGetCustomerByIdQuery()
  const fetchUser=async(id:string)=>{
    try{
      const res=await getById({
        id:id
      }).unwrap()
      if(res?.statusCode===200){
        setData(res?.data || {})
        
        setImage(res?.data?.image||"")
        
      }
        
      

    }catch(error:any){
      showError(error?.data?.manage)
    }
  }

  useEffect(()=>{
    if(id){
      fetchUser(id)
    }
  },[])
  return (
    <Card className="cards">
      <Loader isLoad={isLoading}/>
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item lg={2} md={2} sm={6} xs={12}>
          <figure className="profile_img">
                  <img
                    src={
                      data?.image ||
                      "/static/images/user_placeholder.png"
                    }
                    alt=""
                  />
                </figure>
          </Grid>
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Full Name</Typography>
                  <Typography component="p">{data?.fullName || "-"}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Phone no</Typography>
                  <Typography component="p">{data?.countryCode} {data?.phone || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Email</Typography>
                  <Typography component="p">{data?.email ||"-"}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Account Status</Typography>
                  <Typography component="p">{data?.isBlocked?"Inactive":"Active"}</Typography>
                </Box>
              </Grid>
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Role</Typography>
                  <Typography component="p">{data?.role===3?"Business Owner":"_"}</Typography>
                </Box>
              </Grid> */}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Business Name</Typography>
                  <Typography component="p">{data?.businessName || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">
                    Position within the Company
                  </Typography>
                  <Typography component="p">{data?.position || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Date of Birth</Typography>
                  <Typography component="p">{moment(data?.dob).format("DD/MM/YYYY")}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Business Email</Typography>
                  <Typography component="p">{data?.businessEmail || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5" >Website</Typography>
                  <a href={data?.website} target="_blank" rel="noopener noreferrer">
                  <Typography style={{ wordWrap: 'break-word' }} component="p">{data?.website || "--"}</Typography>
                  </a>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">RIF number</Typography>
                  <Typography component="p">{data?.RIF}</Typography>
                </Box>
              </Grid>
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Social Security Number</Typography>
                  <Typography component="p">{data?.socialSecurityNumber}</Typography>
                </Box>
              </Grid> */}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Full Address</Typography>
                  <Typography component="p">{data?.addressLine1}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Zip code</Typography>
                  <Typography component="p">{data?.zipCode}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Credit Score</Typography>
                  <Typography component="p">{data?.creditScore}</Typography>
                </Box>
              </Grid>

              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">PAN</Typography>
                  <Typography component="p">7876756545</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Aadhar No. </Typography>
                  <Typography component="p">765654534389</Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <Typography component="h5">Documents</Typography>
                  <Box className="docs_div">
                    <figure>
                      <VisibilityIcon onClick={() => setOpen(true)} />

                      <img src="/static/images/document.png" alt="" />
                    </figure>
                    <figure>
                      <VisibilityIcon onClick={() => setOpen(true)} />
                      <img src="/static/images/document.png" alt="" />
                    </figure>
                  </Box>
                </Box>
              </Grid> */}
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography component="h5">Bio</Typography>
                  <Typography component="p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <DocumentViewer open={open} setOpen={setOpen} />
    </Card>
  );
};

export default Details;
