import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import {
  CommonBody,
  settings

} from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type QueryParams = {
  page: number;
  size: number;
  search?: string;
};

export const settingsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSettings:builder.query<
    CommonResponseType & {data:settings},
    {}>({
        query:({})=>({
            url:`${END_POINTS.settings}`,
            method:"GET"
        })
    }),

    updateSetting:builder.mutation<
    CommonResponseType & {data:settings},
    {body:CommonBody}>({
        query:({body})=>({
            url:`${END_POINTS.settings}`,
            method:"PUT",
            body
        })
    })
  }),
});

export const {
useLazyGetSettingsQuery,
useUpdateSettingMutation
} = settingsApi;
