import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { isValidInput } from "../../utils/validations";
import {
  useLazyDeleteCarsQuery,
  useLazyGetCarsQuery,
  useUpdateVehicleMutation,
} from "../../services/manageCars";
import { showError } from "../../constants/toast";
import { Product } from "../../types/General";
import Pagination from "../../components/Pagination";
import Loader from "../../constants/Loader";
import WarnModal from "../../components/WarnModal";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import useTranslation from "../../hooks/translation";

const ManageProducts = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const translation = useTranslation() as any;

  //states
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const [selectedId, setSelectedId] = useState<string>("");
  const [data, setData] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  //API-Hooks
  const [getCars, { isLoading }] = useLazyGetCarsQuery();
  const [deleteById] = useLazyDeleteCarsQuery();
  const [updateVehicle] = useUpdateVehicleMutation();

  //Api-calling

  const fetchCars = async () => {
    setLoading(true);
    try {
      const res = await getCars({
        page: page,
        size: 10,
        search: debouncedSearchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        const value = res?.data?.vehicle || [];
        setData(res?.data?.vehicle || []);
        setTotalCount(res?.data?.count);

        if (value.length === 0 && page > 1) {
          setPage(page - 1);
        }
        setLoading(false);
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false);
    }
  };

  //Methods
  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Cars"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  // hooks
  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);
  useEffect(() => {
    fetchCars();
  }, [page, searchTerm, debouncedSearchTerm]);
  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.header.manageCars}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            {/* <SearchBar /> */}
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              {hidePermission?.isEdit || userData?.role === 1 ? (
                <Button
                  className="btn btn_primary"
                  onClick={() =>
                    navigate("/manage-cars/add", {
                      state: { type: "add" },
                    })
                  }
                >
                 {translation.buttons.addCar}
                </Button>
              ) : null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {translation.tableHeads.sno}
                  </TableCell>
                  <TableCell>{translation.tableHeads.carImage}</TableCell>
                  <TableCell>{translation.tableHeads.name}</TableCell>
                  <TableCell>{translation.tableHeads.category}</TableCell>
                  <TableCell>{translation.tableHeads.tag}</TableCell>

                  <TableCell>{translation.tableHeads.model}</TableCell>
                  <TableCell>{translation.tableHeads.price}</TableCell>
                  <TableCell>{translation.tableHeads.status}</TableCell>
                  <TableCell>{translation.tableHeads.actions}</TableCell>
                </TableRow>
              </TableHead>

              {data?.length ? (
                data?.map((item, index) => (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell>
                        <figure className="bnr_img">
                          <img
                            src={item?.image[0]}
                            alt="/static/images/post.png"
                          />
                        </figure>
                      </TableCell>
                      <TableCell>{item?.name || ""}</TableCell>
                      <TableCell>{item?.typeName || ""}</TableCell>
                      <TableCell>{item?.categoryName || ""}</TableCell>
                      <TableCell>{item?.model || ""}</TableCell>
                      <TableCell>${item?.price || ""}</TableCell>
                      <TableCell>
                        <Switch
                          {...label}
                          checked={!item?.isBlocked}
                          size="small"
                          onChange={() => {
                            updateStatus(
                              item?._id,
                              !item?.isBlocked,
                              updateVehicle,
                              fetchCars
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <Tooltip title="view">
                            <IconButton
                              onClick={() =>
                                navigate("/manage-cars/details/" + item?._id)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          {hidePermission?.isEdit || userData?.role === 1 ? (
                            <Tooltip title="edit">
                              <IconButton
                                onClick={() =>
                                  navigate("/manage-cars/edit/" + item?._id, {
                                    state: { type: "edit" },
                                  })
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          {hidePermission?.isDelete || userData?.role === 1 ? (
                            <Tooltip title="delete">
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(item?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <DirectionsCarIcon /> No Car Found
                    </div>
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Pagination
        module={data}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        name="car"
        open={open}
        setOpen={setOpen}
        handleDelete={() => {
          handleDelete(deleteById, selectedId, fetchCars);
        }}
      />
    </MainContainer>
  );
};

export default ManageProducts;
