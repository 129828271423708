import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, paymentAPiData, GetPaymentApi, ChatRooms, chatById } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type QueryParams = {
  page: number;
  size: number;
  search?: string;
};
 type ChatListResponse={
    chatRooms:ChatRooms[];
    count:number;
 }

export const ChatsAPi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

    getChatRoom:builder.query<
    CommonResponseType & {data:ChatListResponse},
    {page:number}
    >({
        query:({page})=>({
            url:`${END_POINTS.chatRoom}?page=${page}`,
            method:"GET"
        })
    }),
    getChatById:builder.query<
    CommonResponseType & {data:chatById},
    {page:number,id:string}
    >({
        query:({page,id})=>({
            url:`${END_POINTS.chatRoom}/${id}?page=${page}`,
            method:"GET"
        })
    }),

  }),
});

export const {useLazyGetChatRoomQuery,useLazyGetChatByIdQuery} = ChatsAPi;
