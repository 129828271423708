import React from 'react'
import MainContainer from '../../layout/MainContainer'

const AddBankDetails=()=> {
  return (
    <>
    <MainContainer>
        <div>
            
        </div>
    </MainContainer>
      
    </>
  )
}

export default AddBankDetails
