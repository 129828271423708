import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControl from "@mui/material/FormControl";
import { useAppDispatch } from "../../../hooks/store";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { useForgotPasswordMutation, useOtpMutation } from "../../../services/auth";
import { showError, showToast } from "../../../constants/toast";
import { temporaryToken } from "../../../reducers/authSlice";
import { setToStorage } from "../../../constants/storage";
import { STORAGE_KEYS } from "../../../constants/storageKeys";

const otpStyle = {
  width: "50px",
  height: "50px",
  "box-sizing": "border-box",
  margin: "0 auto",
};

const VerifyOtp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;
  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState<number>(59);
  const [error, setError] = useState<boolean>(false);
  const [optVerificationMutation,{isLoading}]=useOtpMutation()
  const [ForgotPasswordMutation] =
  useForgotPasswordMutation();
  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);

      const data = {
        key: state?.email,
        code: otp,
        isResetPassword:state?.isResetPassword
      };
      console.log(data,"data");
      

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await optVerificationMutation({
          body:encryptedBody
        }).unwrap();
        if (response?.statusCode === 200) {
          console.log(response?.data?.token,"response");
          
          showToast("OTP verified successfully")
          dispatch(temporaryToken({ tempToken:response?.data?.token || null}));
          setToStorage(STORAGE_KEYS.tempToken,response?.data?.token)
          navigate("/resetpassword", { state: state?.email, replace: true });
        }
      } catch (error: any) {
        if (error?.data?.message) {
          showError(error?.data?.message || "");
        }
      }
    } else {
      setError(true);
    }
  };

  const handleResendOtp = async () => {
    let data = {
      key: state?.email,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await ForgotPasswordMutation({
        body:encryptedBody
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message);
        setCountDown(59);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");

      console.log(error);
    }
  };
  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => navigate("/forgotpassword")}
              sx={{ p: 0, position: "absolute", left: 0, color: "#1d1d1d" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" sx={{ color: "#1d1d1d" }}>
              Verify Otp
            </Typography>
          </Box>
          <form>
            <FormControl className="opt_fields" sx={{ width: "100%" }}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                inputStyle={otpStyle}
                inputType="tel"
              />
                 {error && otp.length !== 4 ? (
                <h6 className="err_msg" style={{ textAlign: "center",paddingTop:"10px" }}>
              This field is required
                </h6>
              ) : (
                ""
              )}
            </FormControl>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                // type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Verify Otp
              </Button>
            </Box>
            {countDown === 0 ? (
              <Box
                sx={{
                  textAlign: "center",
                  pt: 2,
                }}
              >
                <Typography
                  className="anchor_link"
                  sx={{ cursor: "pointer" }}
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography
                  // className="anchor_link"
                  sx={{
                    color: "#1d2b28",
                    pt: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  The verification code will expire in{" "}
                  {countDown<10?(
                      <p style={{ margin: 0, color: "#1d2b28", marginLeft: 6 }}>
                      00 : 0{countDown}
                    </p>
                  ):(

                    <p style={{ margin: 0, color: "#1d2b28", marginLeft: 6 }}>
                      00 : {countDown}
                    </p>
                  )}
                </Typography>
              </Box>
            )}
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default VerifyOtp;
