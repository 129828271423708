import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const generateResponsiveStyle = () => {
  //   const theme = useTheme();
  //   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Adjust the breakpoint as needed

  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffff",
    // border: "1px solid #fff",
    boxShadow: 24,
    p: 6,
    borderRadius: 3,
    outline: "none",
  };
};

export const generateResponsiveStyleModal = () => {
  //   const theme = useTheme();
  //   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Adjust the breakpoint as needed

  return {
    position: "absolute",
    maxHeight: "80vh",
    overflowY:"auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800, // Increased width to 600px
    // height: 400, // Added height property with a value of 700px
    bgcolor: "#ffff",
    // border: "1px solid #fff",
    boxShadow: 24,
    p: 6,
    borderRadius: 3,
    outline: "none",
  };
};
