import React, { useEffect, useRef, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormik } from "formik";
import * as Yup from "yup";

import FormLabel from "@mui/material/FormLabel";
import { isNumber, isString } from "../../utils/validations";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, FormResponse } from "../../types/General";
import {
  useAddFormsMutation,
  useLazyDeleteFormsQuery,
  useLazyGetFormsQuery,
  useUpdateFormsMutation,
} from "../../services/manageForms";
import { showError, showToast } from "../../constants/toast";
import Tooltip from "@mui/material/Tooltip";
import WarnModal from "../../components/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import Pagination from "../../components/Pagination";
import useTranslation from "../../hooks/translation";

interface Field {
  fieldName: string;
  type: string;
  numOfFields: number;
  required?: string;
}

const AddForm = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const translation=useTranslation() as any;

  //States
  const [id, setId] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [step, setStep] = useState<string>("");
  const [Imagetype, setImageType] = useState<string>("");
  const selectRef = useRef<HTMLDivElement>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [quantity, setQuantity] = useState<string>("");
  const [required, setRequired] = useState<string>("false");
  const [open, setOpen] = useState<boolean>(false);
  const [fields, setFields] = useState<Field[]>([
    { fieldName: "", type: "", numOfFields: 0, required: "false" },
  ]);

  const [data, setData] = useState<FormResponse[]>([]);
  const [show, setShow] = useState<boolean>(false);

  //API Hooks
  const [addForm] = useAddFormsMutation();
  const [getForms] = useLazyGetFormsQuery();
  const [upateForm] = useUpdateFormsMutation();
  const [deleteById] = useLazyDeleteFormsQuery();

  //form-handling

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(16, "Maximum 16 characters are required"),
      // numOfFields: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      if (type === "IMAGE" && !Imagetype) {
        showError("Select Image type");
        return;
      }
      if (!type) {
        showError("Select field type");
        return;
      }
      if (!step) {
        showError("Select field step");
        return;
      }
      if (!required) {
        showError("Please select mandatory checkbox field");
        return;
      }
      const body = {
        name: formik.values.name,
        required: required === "true" ? true : false,
        type: type,
        step: Number(step),

        ...(Imagetype ? { totalFields: Number(Imagetype) } : null),
      };
      console.log(body, "body");

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      if (id) {
        try {
          const res = await upateForm({
            body: encryptedBody,
            id: id,
          }).unwrap();
          if (res?.statusCode === 200) {
            showToast("Field updated");
            // formik.setFieldValue("name", "");
            // formik.setFieldValue("numOfFields", "");
            formik.resetForm();
            setType("");
            setRequired("false");
            setId("");
            setImageType("");
            setStep("");
            await fetchForms();
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      } else {
        try {
          const response = await addForm({
            body: encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Field added successfully");
            setType("");
            setImageType("");
            setStep("");
            setRequired("false");
            setShow(false);
            formik.resetForm();
            fetchForms();
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    },
  });

  //API calls
  const fetchForms = async () => {
    try {
      const res = await getForms({
        page: page,
        size: 10,
      }).unwrap();

      if (res?.statusCode === 200) {
        const len = res?.data?.field;
        setData(res?.data?.field || []);
        setTotalCount(res?.data?.count);
        if (len?.length === 0 && page > 1) {
          setPage(page - 1);
        }
      } else {
        setData([]);
      }
    } catch (error: any) {}
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    if (value === "IMAGE") {
      setShow(true);
    } else {
      setShow(false);
    }
    setType(event.target.value as string);
  };
  const handleStepChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;

    setStep(event.target.value as string);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequired(event.target.value);
  };

  const handleImageType = (event: SelectChangeEvent) => {
    setImageType(event.target.value as string);
  };

  console.log(required, "req");
  const totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchForms();
  }, [page]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Add Field</h1>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid
                container
                spacing={2}
                // key={index}
                sx={{ marginBottom: 2 }}
              >
                <Grid item lg={2.5} md={2.5} sm={3} xs={12}>
                  <Typography className="custom_label">{translation.tableHeads.fieldName}</Typography>
                  <TextField
                    ref={selectRef}
                    className="text_field"
                    hiddenLabel
                    type="text"
                    name="name"
                    variant="outlined"
                    fullWidth
                    value={formik.values.name}
                    placeholder={translation.tableHeads.fieldName}
                    onChange={(val) => {
                      if (
                        val.target.value === "." ||
                        val.target.value === " "
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    inputProps={{
                      maxLength: 20,
                    }}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item lg={2.5} md={2.5} sm={3} xs={12}>
                  <Typography className="custom_label">Select step</Typography>
                  <FormControl fullWidth>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={step}
                      // value={field.type}
                      onChange={handleStepChange}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="1">Step 1</MenuItem>
                      <MenuItem value="2">Step 2</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={2.5} md={2.5} sm={3} xs={12}>
                  <Typography className="custom_label">Select Type</Typography>
                  <FormControl fullWidth>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={type}
                      // value={field.type}
                      onChange={handleTypeChange}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="TEXT">Text</MenuItem>
                      <MenuItem value="NUMBER">Number</MenuItem>
                      <MenuItem value="IMAGE">Image</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {show ? (
                  <Grid item lg={2.5} md={2.5} sm={3} xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    <FormControl fullWidth>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={Imagetype}
                        // value={field.type}
                        onChange={handleImageType}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="1">Front</MenuItem>
                        <MenuItem value="2">Back</MenuItem>
                        <MenuItem value="3">Both</MenuItem>
                        <MenuItem value="4">Multiple</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                <Grid item lg={2.5} md={2.5} sm={3} xs={12}>
                  <Typography className="custom_label">Mandatory?</Typography>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    row
                    value={required}
                    onChange={(event) => handleChange(event)}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </Grid>
                <Grid
                  item
                  lg={1.5}
                  md={1.5}
                  sm={3}
                  xs={12}
                  style={{ paddingTop: "8px" }}
                >
                  {/* <div className="form_btn">
                  
                        <Button
                          size="large"
                          type="button"
                          className="btn btn_primary"
                          onClick={handleField}
                        >
                          Add
                        </Button>
                      
                    </div> */}
                </Grid>
              </Grid>

              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>

          {data?.length ? (
            <>
              <Grid item xs={12}>
                <Box className="heading">
                  <Typography component="h5">Added Form Fields</Typography>
                </Box>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={2}>
                  <Typography className="custom_label_form">
                  {translation.tableHeads.fieldName}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="custom_label_form">
                  {translation.tableHeads.fieldType}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="custom_label_form">
                  {translation.tableHeads.stepType}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="custom_label_form">
                  {translation.tableHeads.imageType}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="custom_label_form">  {translation.tableHeads.status}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="custom_label_form">  {translation.tableHeads.actions}</Typography>
                </Grid>

                {data?.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={2}>
                      <Typography className="custom_label">
                        {item?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="custom_label">
                        {item?.type === "TEXT"
                          ? "Text"
                          : item?.type === "NUMBER"
                            ? "Number"
                            : "Image"}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="custom_label">
                        {item?.step || ""}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="custom_label">
                        {item?.totalFields === 1
                          ? "Front"
                          : item?.totalFields === 2
                            ? "Back"
                            : item?.totalFields === 3
                              ? "Both"
                              : item?.totalFields === 4
                                ? "Multiple"
                                : "----"}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className="custom_label">
                        {item?.required ? "Required" : "Optional"}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Box className="table_actions">
                        <Tooltip title="edit">
                          <IconButton
                            onClick={() => {
                              formik.setFieldValue("name", item?.name);
                              setShow(item?.totalFields ? true : false);
                              setImageType(
                                String(
                                  item?.totalFields ? item?.totalFields : ""
                                )
                              );
                              setStep(String(item?.step));
                              setType(item?.type);
                              setRequired(item?.required ? "true" : "false");
                              setId(item?._id);
                              selectRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "center",
                                inline: "center",
                              });
                            }}
                          >
                            <ModeEditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="delete">
                          <IconButton
                            onClick={() => {
                              setOpen(true);
                              setSelectedId(item?._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </>
          ) : (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="heading">
                <Typography component="h5">No fields added yet!</Typography>
              </Box>
            </Grid>
          )}
        </Card>
      </div>
      <WarnModal
        name="field"
        open={open}
        setOpen={setOpen}
        handleDelete={() => {
          handleDelete(deleteById, selectedId, fetchForms);
        }}
      />
      <Pagination
        module={data}
        page={page}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </MainContainer>
  );
};

export default AddForm;
