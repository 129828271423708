import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  Box,
  Button,
  Checkbox,
  Container,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";

// import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
// import { usePostLoginMutation } from "../../services/auth";

import "./Login.scss";
import { useLoginMutation } from "../../services/auth";
import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { setCredentials } from "../../reducers/authSlice";
import { showError, showToast } from "../../constants/toast";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/translation";

// import {
//   Loader,
//   STORAGE_KEYS,
//   getFromStorage,
//   removeFromStorage,
//   setToStorage,
//   showError,
// } from "../../constants";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const translation=useTranslation() as any;

  const [showPassword, setShowPassword] = useState(false);

  // const [loginMutation, { isLoading }] = usePostLoginMutation();
  const [login, { isLoading }] = useLoginMutation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl: HTMLInputElement | null = document.getElementById(
      "password"
    ) as HTMLInputElement | null;
    if (inputEl) {
      setTimeout(() => {
        inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const items = [
    {
      label: "Dashboard",
      // icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      label: "Manage Customers",
      // icon: <PeopleIcon />,
      key: "2",
      selected: "/manage-customers",
    },
    {
      label: "Manage Categories",
      // icon: <CategoryIcon />,
      key: "3",
      selected: "/manage-categories",
    },
    {
      label: "Manage Tags",
      // icon: <StyleIcon />,
      key: "4",
      selected: "/manage-tags",
    },
    {
      label: "Manage Cars",
      // icon: <DirectionsCarIcon />,
      key: "5",
      selected: "/manage-cars",
    },
    {
      label: "Manage Loans",
      // icon: <AccountBalanceIcon />,
      key: "6",
      selected: "/manage-loans",
    },
    {
      label: "Manage Interest",
      // icon: <PaymentsIcon />,
      key: "7",
      selected: "/manage-interest",
    },
    {
      label: "Chats",
      // icon: <ChatIcon />,
      key: "8",
      selected: "/chats",
    },
    {
      label: "Manage Forms",
      // icon: <DynamicFormIcon />,
      key: "9",
      selected: "/manage-forms/add",
    },
    {
      label: "Manage Loan Term",
      // icon: <TodayIcon />,
      key: "10",
      selected: "/manage-loan-term",
    },
    {
      label: "Manage Service Station",
      // icon: <CleaningServicesIcon />,
      key: "11",
      selected: "/manage-service-stations",
    },
    {
      label: "Customer Support",
      // icon: <HelpCenterIcon />,
      key: "12",
      selected: "/customer-support",
    },
    {
      label: "Manage Testimonials",
      // icon: <ReviewsIcon />,
      key: "13",
      selected: "/manage-testimonials",
    },
    {
      label: "Manage Banner",
      // icon: <ViewCarouselIcon />,
      key: "14",
      selected: "/manage-banner",
    },
    {
      label: "Manage Notifications",
      // icon: <NotificationsActiveIcon />,
      key: "15",
      selected: "/manage-notifications",
    },
    {
      label: "Reports and Analytics",
      // icon: <BarChartIcon />,
      key: "16",
      selected: "/analytics",
    },
    {
      label: "Manage Sub-Admin",
      // icon: <PersonAddIcon />,
      key: "17",
      selected: "/manage-subAdmin",
    },
    {
      label: "Manage CMS",
      // icon: <SummarizeTwoToneIcon />,
      key: "18",
      selected: "/cms",
    },
    {
      label: "Manage FAQS",
      // icon: <QuizIcon />,
      key: "19",
      selected: "/faq",
    },
    {
      label: "Settings",
      // icon: <SettingsIcon />,
      key: "20",
      selected: "/settings",
    },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required(translation.login.emailRequired)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translation.login.emailInvalid
        ),
      password: Yup.string()
        .required(translation.login.passwordRequired)
        .min(6, translation.login.passwordMinLength),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      console.log("hello");
      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
      console.log(fcmtoken, "fcmToken");
      let body = {
        key: formik.values.email,
        password: formik.values.password,
        deviceToken: fcmtoken || "1234",
        deviceType: "WEB",
      };

      console.log(body);

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        console.log("res");
        const response = await login({
          body: encryptedBody,
        }).unwrap();

        if (response?.statusCode === 200) {
          showToast(translation.login.success);
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );

          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }

          if (response?.data?.role === 1) {
            console.log("role1");
            navigate("/dashboard", { replace: true });
          } else {
            const nav = items?.filter((item: any) =>
              response?.data?.permission?.some(
                (list: any) => list?.label === item?.label
              )
            );
            console.log(nav, "nav");

            navigate(`${nav[0]?.selected}`);
          }
        }
      } catch (error: any) {
        showError(error?.data?.message || "Error");
        console.log(error);
      }
    },
  });

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.key);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", color: "#1d1d1d" }}>
            <Typography variant="h5">{translation.login.title}</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">{translation.login.emailLabel}</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder={translation.login.emailPlaceholder}
                fullWidth
                name="email"
                type="email"
                id="email"
                variant="outlined"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">{translation.login.passwordLabel}</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder={translation.login.passwordPlaceholder}
                fullWidth
                name="password"
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box className="remember_box">
                <FormControlLabel
                  control={<Checkbox />}
                  label={translation.login.rememberMe}
                  checked={formik.values.remember}
                  name="remember"
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                className="anchor_link"
                onClick={() => navigate("/forgotpassword")}
              >
                <Typography>{translation.login.forgotPassword}</Typography>
              </Box>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                // onClick={() => navigate("/dashboard")}
              >
                Sign In
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default Login;
