import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import {
  CommonBody,
  GetCustomerApi,
  GetUserApiResponse,
  LoanTenure,
  LoanTenureResponse,
  Tags,
  TagsResponse,
  UserData,
  addCustomerResponse,
} from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type QueryParams = {
  page: number;
  size: number;
  search?: string;
  type?: string;
};

export const ManageCustomerApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addCustomer: builder.mutation<
      CommonResponseType & { data: addCustomerResponse },
      { body: CommonBody }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.user}`,
        method: "POST",
        body,
      }),
    }),
    updateCustomer: builder.mutation<
      CommonResponseType & { data: addCustomerResponse },
      { body: CommonBody; id: string }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.user}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    getCustomer: builder.query<
      CommonResponseType & { data: GetCustomerApi },
      QueryParams
    >({
      query: ({ search, page, size }) => ({
        url: `${END_POINTS.user}?search=${search}&page=${page}&size=${size}`,
        method: "GET",
      }),
    }),
    getNewCustomer: builder.query<
      CommonResponseType & { data: GetCustomerApi },
      QueryParams
    >({
      query: ({ search, page, size, type }) => ({
        url: `${END_POINTS.user}?search=${search}&page=${page}&size=${size}&users=${type}`,
        method: "GET",
      }),
    }),
    getCustomerAll: builder.query<
      CommonResponseType & { data: GetUserApiResponse },
      {}
    >({
      query: ({}) => ({
        url: `${END_POINTS.user}`,
        method: "GET",
      }),
    }),

    getCustomerNotification: builder.query<
      CommonResponseType & { data: GetCustomerApi },
      QueryParams
    >({
      query: ({ search, page, size }) => ({
        url: `${END_POINTS.user}?isBlocked=false&search=${search}&page=${page}&size=${size}`,
        method: "GET",
      }),
    }),

    deleteCustomer: builder.query<
      CommonResponseType & { data: GetUserApiResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.user}/${id}`,
        method: "DELETE",
      }),
    }),
    getCustomerById: builder.query<
      CommonResponseType & { data: addCustomerResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.user}/${id}`,
        method: "GET",
      }),
    }),

    exportCSV: builder.query<CommonResponseType & { data: string }, {search:string}>({
      query: ({search}) => ({
        url: `${END_POINTS.userExports}?search=${search}`,
        method: "GET",
      }),
    }),

    exportCSVNew: builder.query<CommonResponseType & { data: string }, {search:string}>({
      query: ({search}) => ({
        url: `${END_POINTS.userExports}?users=new&search=${search}`,
        method: "GET",
      }),
      
    }),
    exportCSVExisting: builder.query<CommonResponseType & { data: string }, {search:string}>({
        query: ({search}) => ({
          url: `${END_POINTS.userExports}?users=existing&search=${search}`,
          method: "GET",
        }),
        
      }),
  }),
});

export const {
  useAddCustomerMutation,
  useLazyGetCustomerQuery,
  useLazyGetCustomerAllQuery,
  useLazyGetCustomerByIdQuery,
  useUpdateCustomerMutation,
  useLazyDeleteCustomerQuery,
  useLazyExportCSVQuery,
  useLazyGetCustomerNotificationQuery,
  useLazyExportCSVExistingQuery
} = ManageCustomerApi;
