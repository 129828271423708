import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
  TextField,
  FormControlLabel,
  Typography,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { useDebouncedCallback } from "use-debounce";
import * as Yup from "yup";
import { isString } from "../../utils/validations";
import {
  ManageCustomerApi,
  useLazyGetCustomerAllQuery,
  useLazyGetCustomerQuery,
} from "../../services/manageCustomers";
import { showError, showToast } from "../../constants/toast";
import {
  CommonBody,
  GetCustomer,
  addCustomerResponse,
} from "../../types/General";
import { title } from "process";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useAddNotificationsMutation } from "../../services/notifications";
import InfiniteScroll from "react-infinite-scroller";
import CircularProgress from "@mui/material/CircularProgress";
import useTranslation from "../../hooks/translation";

const AddNotification = () => {
  const navigate = useNavigate();
  const translation=useTranslation() as any;

  // States
  const [checked, setChecked] = useState<boolean>(false);
  const [receiver, setReceiver] = useState<GetCustomer[]>([]);
  const [selectId, setSelectId] = useState<string>("");
  const selectRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  //API hooks
  const [getUSer] = ManageCustomerApi.useLazyGetCustomerNotificationQuery();
  const [addNotifications] = useAddNotificationsMutation();

  //form handling
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      title_sp: "",
      desc: "",
      desc_sp: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(16, "Maximum 16 characters are allowed"),
      title_sp: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(16, "Maximum 16 characters are allowed"),
      desc: Yup.string()
        .required("This field is required")
        .min(5, "Minimum 5 characters are required")
        .max(250, "Maximum 250 characters are allowed"),
      desc_sp: Yup.string()
        .required("This field is required")
        .min(5, "Minimum 5 characters are required")
        .max(250, "Maximum 250 characters are allowed"),
    }),
    onSubmit: async (values) => {
      if(selectedUsers.length===0 && !checked){
        showError("Select atleast one user")
        return;
      }
      const body = {
        title: values.title,
        title_sp: values.title_sp,
        message: values.desc,
        message_sp: values.desc_sp,
        type: checked ? 1 : 2,
        ...(checked ? null : { userId: selectedUsers }),
      };

      console.log(body, "bodyyy");

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      try {
        const res = await addNotifications({
          body: encryptedBody,
        }).unwrap();
        if (res?.statusCode === 200) {
          navigate("/manage-notifications");
          showToast("Notification added successfully");
        }
      } catch (error: any) {
        showError(error?.data?.message);
      }
    },
  });

  const handleReceiverChange = (event: SelectChangeEvent) => {
    setSelectId(event.target.value as string);
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const Options = receiver?.map((item) => {
    return {
      label: item?.firstName || item?.fullName,
      id: item?._id,
    };
  });

  const handleScroll = useDebouncedCallback(() => {
    const element = selectRef.current;
    if (element) {
      const bottom =
        Math.ceil(element.scrollTop + element.clientHeight) >=
        element.scrollHeight;
      if (bottom) {
        setLoading(true);
        setPage(page + 1);
      }
    }
  }, 500);

  const handleSearch = useDebouncedCallback((value: any) => {
    setLoading(true);
    setSearchTerm(value);
    setPage(1);
    setReceiver([]);
  }, 500);

  // API Call

  const fetchUser = async () => {
    try {
      const res = await getUSer({
        size: 10,
        page: page,
        search: searchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        if (searchTerm) {
          setReceiver(res?.data?.user);
          setLoading(false);
        } else {
          const data = res?.data?.user;
          setReceiver((prev) => [...prev, ...data]);
          setLoading(false)
        }
      } else {
        setReceiver([]);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const label = { inputProps: { "aria-label": "controlled" } };
  useEffect(() => {
    fetchUser();
  }, [page, searchTerm]);
  console.log(selectedUsers, "users");
  console.log(receiver, "receiver");
  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Notification</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-notifications");
              }}
            >
              {translation.buttons.back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                        {translation.textFields.notificationTitle}
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="title"
                      
                      variant="outlined"
                      value={formik.values.title}
                      fullWidth
                      placeholder=   {translation.textFields.notificationTitle}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.title && formik.errors.title}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                    {translation.textFields.notificationTitleSpanish}
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="title_sp"
                      value={formik.values.title_sp}
                      variant="outlined"
                      fullWidth
                      placeholder={translation.textFields.notificationTitleSpanish}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.title_sp && formik.errors.title_sp
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                    {translation.textFields.notificationMessage}
                    </Typography>
                    <TextField
                      className="text_field"
                      multiline
                      minRows={1}
                      maxRows={4}
                      hiddenLabel
                      type={"text"}
                      name="desc"
                      variant="outlined"
                      value={formik.values.desc}
                      fullWidth
                      placeholder={translation.textFields.notificationMessage}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{
                        maxLength: 260,
                      }}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.desc && formik.errors.desc}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                    {translation.textFields.notificationMessageSpanish}
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      multiline
                      minRows={1}
                      maxRows={4}
                      type={"text"}
                      name="desc_sp"
                      variant="outlined"
                      value={formik.values.desc_sp}
                      fullWidth
                      placeholder= {translation.textFields.notificationMessageSpanish}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{
                        maxLength: 260,
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.desc_sp && formik.errors.desc_sp
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                    {translation.textFields.selectReceiver}
                    </Typography>

                    <FormControl sx={{ width: "100%" }}>
                      <Autocomplete
                        className="users_autocomplete"
                        disabled={checked}
                        multiple
                        disableCloseOnSelect
                        autoComplete
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        limitTags={4}
                        filterOptions={(x) => x}
                        onInputChange={(event, newInputValue) => {
                          handleSearch(newInputValue);
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        options={Options}
                        loading={loading}
                        loadingText={
                          <CircularProgress
                            color="inherit"
                            size={20}
                            style={{
                              marginRight: 8,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          />
                        }
                        noOptionsText={!loading?"No user found":null}
                        ListboxProps={{
                          ref: selectRef,
                          onScroll: handleScroll,
                          sx: {
                            maxHeight: 200,
                            overflowY: "auto",
                          },
                        }}
                        onChange={(event, value) => {
                          console.log(value, "values");
                          const userId = value?.map((item, index) => item?.id);

                          setSelectedUsers(userId);
                        }}
                        
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                        
                    
                      />
                    </FormControl>
                  </Grid>
                  

                  <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...label}
                          checked={checked}
                          onChange={handleChange}
                        />
                      }
                      label={translation.textFields.sendToAll}
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translation.buttons.save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddNotification;
