import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLazyDeleteSubAdminQuery, useLazyGetSubadminQuery ,useUpdateSubAdminMutation} from "../../services/subAdmin";
import { showError } from "../../constants/toast";
import { subAdmin } from "../../types/General";
import { isValidInput } from "../../utils/validations";
import Tooltip from "@mui/material/Tooltip";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/translation";

const ManageSubAdmin = () => {
  const navigate = useNavigate();
  const translation=useTranslation() as any;
  //
  const [data, setData] = useState<subAdmin[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [loading,setLoading]=useState(false)

  //API Hooks
  const [getSubAdmin, { isLoading }] = useLazyGetSubadminQuery();
  const [updateSubAdmin]=useUpdateSubAdminMutation()
  const [deleteById]=useLazyDeleteSubAdminQuery()

  //API Call
  const fetchSubAdmin = async () => {
    setLoading(true)
    try {
      const res = await getSubAdmin({
        size: 10,
        page: page,
        search: debouncedSearchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        const subAdmin = res?.data?.subAdmin || [];
        setData(res?.data?.subAdmin || []);
        setTotalCount(res?.data?.count);
        if (subAdmin?.length === 0 && page > 1) {
          setPage(page - 1);
        }
        setLoading(false)
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false)
    }
  };
  function createData(Name: string, Email: string, Phone: string) {
    return { Name, Email, Phone };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Sub Admin", "subadmin@gmail.com", "+971056734321")];

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const totalpages = Math.ceil(totalCount / 10);

  useEffect(() => {
    fetchSubAdmin();
  }, [searchTerm, debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={loading}/>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng"> {translation.header.manageSubAdmin}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            {/* <SearchBar /> */}
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-subAdmin/add")}
              >
             {translation.buttons.addSubAdmin}
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>{translation.tableHeads.name}</TableCell>
                  <TableCell>{translation.tableHeads.role}</TableCell>
                  <TableCell>{translation.tableHeads.email}</TableCell>
                  <TableCell>{translation.tableHeads.phoneNumber}</TableCell>
                  <TableCell>{translation.tableHeads.status}</TableCell>
                  <TableCell>{translation.tableHeads.actions}</TableCell>
                </TableRow>
              </TableHead>
              {data?.length ? (
                data?.map((item, index) => (
                  <TableBody>
                    <TableRow>
                      <TableCell>{(page - 1) * 10 + index + 1}</TableCell>
                      <TableCell>{item?.firstName|| ""} {item?.lastName}</TableCell>
                      <TableCell>
                        {item?.role === 2 ? "Sub-Admin" : "" }
                      </TableCell>
                      <TableCell>{item?.email || ""}</TableCell>
                      <TableCell>{item?.phone || ""}</TableCell>
                      <TableCell>
                        <Switch {...label} checked={!item?.isBlocked} size="small" onChange={()=>{
                          updateStatus(
                            item?._id,
                            !item?.isBlocked,
                            updateSubAdmin,
                            fetchSubAdmin
                          )
                        }}/>
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <Tooltip title="edit">
                            <IconButton
                              onClick={() =>
                                navigate("/manage-subAdmin/edit/" + item?._id)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="delete">
                            <IconButton onClick={()=>{
                              setSelectedId(item?._id)
                              setOpen(true)
                            }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                      <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >

                  <PersonAddIcon/>  No Sub-admin Found
                  </div>
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
      name="sub-admin"
      open={open}
      setOpen={setOpen}
      handleDelete={()=>{
        handleDelete(deleteById,selectedId,fetchSubAdmin)
      }}
      />
      <Pagination
      module={data}
      page={page}
      totalPages={totalpages}
      onPageChange={onPageChange}
      
      />
    </MainContainer>
  );
};

export default ManageSubAdmin;
