import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Input,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";

import { ChangeEvent, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import Multiselect from "multiselect-react-dropdown";

interface Option {
  id: number;
  value: string;
}
const AddChallenge = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");

  const [image, setImage] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [fileName, setFileName] = useState<string>("");

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      console.log(event.target.files, "event.target.files");

      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const [file, setFile] = useState<string | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files;
    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = () => {
        const fileDataURL = reader.result as string;
        setFile(fileDataURL);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const handleCancel = () => {
    setFile(null);
  };

  const isImage = file && file.startsWith("data:image");
  const isVideo = file && file.startsWith("data:video/mp4");

  function createData(Module: string) {
    return { Module };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData("Manage Customers"),
    createData("Manage Points Criteria"),
    createData("Manage Round UP Configuration"),
    createData("Manage Subscription"),
    createData("Manage Email Template"),
    createData("Manage Notification"),
    createData("Reports and Analytics"),
    createData("Manage CMS"),
    createData("Manage Settings"),
  ];
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };
  const handleSubCategoryChange = (event: SelectChangeEvent) => {
    setSubCategory(event.target.value as string);
  };
  const options: Option[] = [
    { id: 1, value: "Option 1" },
    { id: 2, value: "Option 2" },
    { id: 3, value: "Option 3" },
  ];
  const onSelect = (selectedList: Option[], selectedItem: Option) => {
    setSelectedOptions(selectedList);
  };
  const onRemove = (selectedList: Option[], removedItem: Option) => {
    setSelectedOptions(selectedList);
  };

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng"> Add Challenge</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-challenges");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography className="custom_label">
                      Upload Image or Video
                    </Typography>
                    {file ? (
                      <div className="upload_image_preview2">
                        {isImage ? (
                          <CardMedia component="img" image={file} alt="photo" />
                        ) : isVideo ? (
                          <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            className="video_dv"
                            preload="yes"
                          >
                            <source src={file} type="video/mp4" />
                          </video>
                        ) : null}
                        <CancelIcon onClick={handleCancel} />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/*,video/*",
                            }}
                            onChange={handleFileChange}
                          />
                          <Button component="span" className="upload_image_btn">
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} />
                  {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview2">
                        <CardMedia component="img" image={image} alt="photo" />

                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              const files = (event.target as HTMLInputElement)
                                .files;
                              if (files && files[0].type.includes("image")) {
                                setImage(URL.createObjectURL(files[0]));
                              }
                            }}
                            //   onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            {/* <img
                                src={
                                  image
                                    ? image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              /> */}
                  {/* <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid> */}

                  {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography className="custom_label">
                      Upload Your Videos
                    </Typography>
                    {video ? (
                      <div className="upload_image_preview2">
                        {/* <CardMedia component="img" image={video} alt="photo" /> */}
                  {/* <video
                          autoPlay
                          muted
                          loop
                          playsInline
                          className="video_dv"
                          preload="yes"
                        >
                          <source src={video} type="video/mp4" />
                        </video>
                        <CancelIcon
                          onClick={() => {
                            setVideo("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file-video">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file-video"
                            type="file"
                            inputProps={{
                              accept: "video/*",
                            }}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              const files = (event.target as HTMLInputElement)
                                .files;
                              if (files && files[0].type.includes("video/")) {
                                setVideo(URL.createObjectURL(files[0]));
                              }
                            }} */}
                  {/* //   onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn"> */}
                  {/* <img
                                src={
                                  image
                                    ? image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              /> */}
                  {/* <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid> */}

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Title</Typography>
                    <TextField
                      hiddenLabel
                      type={"email"}
                      name="message"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Description
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="message"
                      variant="outlined"
                      fullWidth
                      placeholder="Description"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Location</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="message"
                      variant="outlined"
                      fullWidth
                      placeholder="Location"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Challenge Completion Points
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Points"
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      No of Allowed Entries
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="message"
                      variant="outlined"
                      fullWidth
                      placeholder="No of Allowed Entries"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Select Category
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>

                        <MenuItem value="travel">Travel</MenuItem>
                        <MenuItem value="food">Food</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Select Sub-Category
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={subCategory}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleSubCategoryChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>

                        <MenuItem value="travel">Adventure</MenuItem>
                        <MenuItem value="food">Food</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Start Date</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      placeholder="Start Date"
                      fullWidth
                      name="dateOfTransfer"
                      variant="outlined"
                      type={"date"}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">End Date</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      placeholder="End Date"
                      fullWidth
                      name="dateOfTransfer"
                      variant="outlined"
                      type={"date"}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Assign Users
                    </Typography>

                    {/* <FormControl sx={{ width: "100%" }}> */}
                    <Multiselect
                      className="multi_select"
                      options={options}
                      displayValue="value"
                      selectedValues={selectedOptions}
                      onSelect={onSelect}
                      onRemove={onRemove}
                    />
                    {/* </FormControl> */}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} />
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...label}
                          // checked={checked}
                          // onChange={handleChange}
                        />
                      }
                      label="Assign to All Users"
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Submit
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddChallenge;
