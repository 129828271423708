import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, NotificationResponse,NotificationGetResponse } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type QueryParams = {
    page: number;
    size: number;
    search?: string;
  };

export const NotificationsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

   
    addNotifications:builder.mutation<
    CommonResponseType & {data:NotificationResponse},
    {body:CommonBody}>({
        query:({body})=>({
            url:`${END_POINTS.notification}`,
            method:"POST",
            body

        })
    }),
    deleteNotification:builder.query<
    CommonResponseType & {data:NotificationResponse},
    {id:string}>({
        query:({id})=>({
            url:`${END_POINTS.notification}/${id}`,
            method:"DELETE",
            

        })
    }),

    getNotifications:builder.query<
    CommonResponseType & {data:NotificationGetResponse},
    QueryParams>({
        query:({page,size,search})=>({
                url:`${END_POINTS.notification}?search=${search}&page=${page}&size=${size}`,
                method:"GET"
        })
    }),

    getNotificationsAdmin:builder.query<
    CommonResponseType & {data:NotificationGetResponse},
    QueryParams>({
        query:({page,size,search})=>({
                url:`${END_POINTS.adminNotification}?search=${search}&page=${page}&size=${size}`,
                method:"GET"
        })
    }),

    deleteAdminNotification:builder.query<
    CommonResponseType & {data:NotificationResponse},
    {}>({
        query:({})=>({
            url:`${END_POINTS.adminNotification}`,
            method:"DELETE",
            

        })
    }),


  
    
   

  })
});

export const {
useAddNotificationsMutation,
useLazyGetNotificationsQuery,
useLazyDeleteNotificationQuery,
useLazyGetNotificationsAdminQuery,
useLazyDeleteAdminNotificationQuery
} =NotificationsApi;
