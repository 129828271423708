import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, GetCustomerApi, LoanApplication, loanReportResponse, VehicleResponse } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

export const LoanReportApi=emptySplitApi.injectEndpoints({
    endpoints:(builder)=>({
        loanReport:builder.query<
        CommonResponseType & {data:loanReportResponse},{page:number,search:string,body:CommonBody,vehicleId:string,dateFrom?:string,dateTo?:string }>({
            query: ({ page, search, body, vehicleId, dateFrom, dateTo }) => {
                // Construct base URL with mandatory query params
                let url = `${END_POINTS.loanReports}?page=${page}&search=${search}&size=10`;
              
                // Append optional query params only if they exist
                if (vehicleId) {
                  url += `&vehicleId=${vehicleId}`;
                }
                if (dateFrom) {
                  url += `&dateFrom=${dateFrom}`;
                }
                if (dateTo) {
                  url += `&dateTo=${dateTo}`;
                }
              
                return {
                  url,
                  method: "POST",
                  body
                };
              }
        }),
        exportCSVLoanReport: builder.query<CommonResponseType & { data: string }, {search:string,body:CommonBody,vehicleId:string,dateFrom?:string,dateTo?:string}>({
            query: ({ search, body, vehicleId, dateFrom, dateTo }) => {
                // Start with the base URL including mandatory parameters
                let url = `${END_POINTS.loanReportsExport}?search=${search}`;
              
                // Append optional parameters only if they exist
                if (vehicleId) {
                  url += `&vehicleId=${vehicleId}`;
                }
                if (dateFrom) {
                  url += `&dateFrom=${dateFrom}`;
                }
                if (dateTo) {
                  url += `&dateTo=${dateTo}`;
                }
              
                return {
                  url,
                  method: "POST",
                  body
                };
              }
          }),
          getCustomerLoan: builder.query<
          CommonResponseType & { data: GetCustomerApi },
          void
        >({
          query: () => ({
            url: `${END_POINTS.user}?wpagination=true`,
            method: "GET",
          }),
        }),
        getCarsLoan: builder.query<
        CommonResponseType & { data: VehicleResponse },
        void
      >({
        query: () => ({
          url: `${END_POINTS.vehicle}?wpagination=true`,
          method: "GET",
        }),
      }),
    })
})

export const {useLazyLoanReportQuery,useLazyExportCSVLoanReportQuery,useLazyGetCustomerLoanQuery,useLazyGetCarsLoanQuery}=LoanReportApi