import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Card,
  IconButton,
  Rating,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";

const ManageListedProperties = () => {
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Properties</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            {/* <SearchBar
            // searchTerm={searchTerm}
            // setDebouncedSearchTerm={setDebouncedSearchTerm}
            // value={searchTerm}
            // onCross={() => setSearchTerm("")}
            // onChange={(val: any) => {
            //   if (isValidInput(val.target.value)) {
            //     setSearchTerm(val.target.value);
            //   }
            // }}
            /> */}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Property Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Service Provider Details</TableCell>
                  <TableCell>Availability</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell align="center"> {1}</TableCell>
                  <TableCell> Bhumibandara Hotel</TableCell>
                  <TableCell> Villa</TableCell>
                  <TableCell> Bali</TableCell>
                  <TableCell>
                    <p style={{ margin: 0, padding: "2px 0" }}>
                      abdulhussian67@gmail.com
                    </p>
                  </TableCell>
                  <TableCell>Available</TableCell>

                  <TableCell>
                    <Switch {...label} size="small" defaultChecked />
                  </TableCell>
                  <TableCell>
                    <Box className="table_actions">
                      <IconButton
                        onClick={() =>
                          navigate(`/manage-listed-properties/details`)
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>

                      {/* <IconButton
                        onClick={() =>
                          navigate(`/manage-listed-properties/edit`)
                        }
                      >
                        <ModeEditIcon />
                      </IconButton> */}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageListedProperties;
