import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, UserData,testimonialResponse,getTestimonialsResponse } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
    statusCode: number;
    message: string;
};


type QueryParams = {
    page: number;
    size: number;
    search?: string;
  };



export const testimonialApi= emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({

       
        addTestimonials:builder.mutation<
        CommonResponseType & {data:testimonialResponse},
        {body:CommonBody}>({
            query:({body})=>({
                url:`${END_POINTS.testimonial}`,
                method:"POST",
                body
            })
        }),
        updateTestimonials:builder.mutation<
        CommonResponseType & {data:testimonialResponse},
        {body:CommonBody,id:string}>({
            query:({body,id})=>({
                url:`${END_POINTS.testimonial}/${id}`,
                method:"PUT",
                body
            })
        }),
        deleteTestimonial:builder.query<
        CommonResponseType & {data:testimonialResponse},
        {id:string}>({
            query:({id})=>({
                url:`${END_POINTS.testimonial}/${id}`,
                method:"DELETE",
                
            })
        }),

        getTestimonials:builder.query<
        CommonResponseType & {data:getTestimonialsResponse},
        QueryParams>({
            query:({page,size,search})=>({
                url:`${END_POINTS.testimonial}?page=${page}&size=${size}&search=${search}`,
                method:"GET"
            })
        }),

        getTestimonialsById:builder.query<
        CommonResponseType & {data:testimonialResponse},
        {id:string}>({
            query:({id})=>({
                url:`${END_POINTS.testimonial}/${id}`,
                method:"GET"
            })
        }),

        
       


        
      

       

       

      


        



        

    })
})

export const {
useAddTestimonialsMutation,
useLazyGetTestimonialsQuery,
useUpdateTestimonialsMutation,
useLazyDeleteTestimonialQuery,
useLazyGetTestimonialsByIdQuery

    
}=testimonialApi

