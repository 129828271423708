import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { isValidInput } from "../../utils/validations";
import SearchBar from "../../components/SearchBar";
import {
  useLazyExportCSVLoanReportQuery,
  useLazyGetCarsLoanQuery,
  useLazyGetCustomerLoanQuery,
  useLazyLoanReportQuery,
} from "../../services/loanReport";
import { CommonBody, LoanApplication, Product } from "../../types/General";
import moment from "moment";
import Loader from "../../constants/Loader";
import Pagination from "../../components/Pagination";
import { useNavigate } from "react-router-dom";
import { showError } from "../../constants/toast";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import useTranslation from "../../hooks/translation";

const LoanReport = () => {
  const navigate = useNavigate();
  const translation=useTranslation() as any;
  const [data, setData] = useState<LoanApplication[]>([]);
  const [userData, setUserData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [userName, setUserName] = useState<string[]>([]);
  const [cars, setCars] = useState<Product[]>([]);
  const [selectedCar, setSelectedCar] = useState<string>("");
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // API Hooks
  const [getLoanReport] = useLazyLoanReportQuery();
  const [exportCSV] = useLazyExportCSVLoanReportQuery();
  const [getCars] = useLazyGetCarsLoanQuery();
  const [getUser, { isLoading }] = useLazyGetCustomerLoanQuery();

  const handleUserNameChange = (event: SelectChangeEvent<typeof userName>) => {
    const {
      target: { value },
    } = event;
    setUserName(typeof value === "string" ? value.split(",") : value);
  };

  const fetchUser = async () => {
    setLoading(true);
    try {
      const res = await getUser().unwrap();
      if (res?.statusCode === 200) {
        const newUserData = res?.data?.user?.map((item) => {
          return { title: item?.fullName, id: item?._id };
        });
        setUserData(newUserData);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false);
    }
  };

  const handleGraphChange = (event: SelectChangeEvent) => {
    setSelectedCar(event.target.value as string);
  };
  const fetchCars = async () => {
    setLoading(true);
    try {
      const res = await getCars().unwrap();
      if (res?.statusCode === 200) {
        setCars(res?.data?.vehicle);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false);
    }
  };

  const getReport = async () => {
    setLoading(true);
    try {
      let body = {
        userArray: selectedId,
      };
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await getLoanReport({
        page,
        search: debouncedSearchTerm.trim(),
        body: encryptedBody,
        vehicleId: selectedCar,
        ...(startDate
          ? {
              dateFrom: moment(startDate)
                .utc()
                .format("YYYY-MM-DDTHH:mm:ss[Z]"),
            }
          : {}),
        ...(endDate
          ? { dateTo: moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") }
          : {}),
      }).unwrap();
      if (res?.statusCode === 200) {
        setData(res?.data?.data);
        setTotalCount(res?.data?.count);
        if (res?.data?.data?.length === 0 && page > 1) {
          setPage(page - 1);
        }
      }
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };

  const downloadCsv = async () => {
    try {
      let body = {
        userArray: selectedId,
      };
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await exportCSV({
        search: debouncedSearchTerm.trim(),
        body: encryptedBody,
        vehicleId: selectedCar,
        ...(startDate
          ? {
              dateFrom: moment(startDate)
                .utc()
                .format("YYYY-MM-DDTHH:mm:ss[Z]"),
            }
          : {}),
        ...(endDate
          ? { dateTo: moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]") }
          : {}),
      }).unwrap();
      console.log(res, "resss");
      if (res?.statusCode === 200) {
        window.open(res?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const totalpages = Math.ceil(totalCount / 10);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    getReport();
  }, [
    page,
    searchTerm,
    debouncedSearchTerm,
    selectedCar,
    selectedId,
    startDate,
    endDate,
  ]);
  useEffect(() => {
    fetchUser();
    fetchCars();
  }, []);
  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.header.loanReport}</h1>
        </div>
        <Box className="custom_tabs">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Button
              className="btn btn_primary"
              onClick={() => {
                setSelectedCar("");
                setSelectedId([]);
                setStartDate(null);
                setEndDate(null);
                setUserName([]);
              }}
            >
              Clear filter
            </Button>
            <Box className="cards_header_right">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue: any) => setStartDate(newValue)}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue: any) => setEndDate(newValue)}
                    minDate={startDate}
                  />
                </Box>
              </LocalizationProvider>
              <Select
                className="select_div"
                value={selectedCar}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleGraphChange}
                displayEmpty
                sx={{
                  width: "200px",
                  border: "1px solid black",
                }}
              >
                <MenuItem value="" disabled>
                  Select car name
                </MenuItem>
                {cars?.map((item, index) => (
                  <MenuItem key={item?._id} value={item?._id}>
                    {item?.name || ""}
                  </MenuItem>
                ))}
              </Select>
              <Autocomplete
                multiple
                limitTags={4}
                id="checkboxes-tags-demo"
                options={userData}
                disableCloseOnSelect
                onChange={(event, value) => {
                  // Extract the ids of the selected users
                  const selectedUserIds = value.map((user) => user?.id);

                  setSelectedId(selectedUserIds);
                }}
                getOptionLabel={(option) => option?.title}
                renderOption={(props, option, { selected }) => {
                  const { ...optionProps } = props;
                  return (
                    <li {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.title}
                    </li>
                  );
                }}
                style={{ width: 250 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select User"
                    placeholder="Select User"
                  />
                )}
              />
              <Button className="btn btn_primary" onClick={downloadCsv}>
                Export CSV
              </Button>
            </Box>
          </Box>
        </Box>
        <TableContainer className="table_container">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{translation.tableHeads.sno}</TableCell>

                <TableCell align="center">{translation.tableHeads.applicationNumber}</TableCell>
                <TableCell align="center">RIF Number</TableCell>
                <TableCell align="center">{translation.tableHeads.vehicleName}</TableCell>
                <TableCell align="center">{translation.tableHeads.userEmail}</TableCell>
                <TableCell align="center">{translation.tableHeads.loanStatus}</TableCell>
                <TableCell align="center">{translation.tableHeads.loanDate}</TableCell>
                <TableCell align="center">{translation.tableHeads.loanType}</TableCell>
                <TableCell align="center">{translation.tableHeads.loanAmount}</TableCell>
                <TableCell align="center">{translation.tableHeads.downPayment}</TableCell>
                <TableCell align="center">{translation.tableHeads.amountPaid}</TableCell>
                <TableCell align="center">{translation.tableHeads.pendingAmount}</TableCell>
                <TableCell align="center">{translation.tableHeads.interestRate}</TableCell>
                <TableCell align="center">{translation.tableHeads.emiAmount}</TableCell>
                <TableCell align="center">{translation.tableHeads.totalInstallment}</TableCell>
                <TableCell align="center">{translation.tableHeads.installmentPaid}</TableCell>
                <TableCell align="center">{translation.tableHeads.remainingInstallment}</TableCell>
                <TableCell align="center">
                {translation.tableHeads.stepOneDate}
                </TableCell>
                <TableCell align="center">
                {translation.tableHeads.stepOneApproveDate}
                </TableCell>
                <TableCell align="center">
                {translation.tableHeads.stepTwoDate}
                </TableCell>
                <TableCell align="center">
                {translation.tableHeads.stepTwoApproveDate}
                </TableCell>
                <TableCell align="center"> {translation.tableHeads.loanComplete}</TableCell>
              </TableRow>
            </TableHead>
            {data?.length
              ? data?.map((item, index) => (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell
                        style={{
                          cursor: "pointer",
                        }}
                        align="center"
                        onClick={() =>
                          navigate("/manage-loans/details/" + item?._id, {
                            state: { userStatus: "ACCEPTED" },
                          })
                        }
                      >
                        {item?.applicationNo || ""}
                      </TableCell>
                      <TableCell align="center">
                        {item?.userDetail?.RIF || ""}
                      </TableCell>
                      <TableCell align="center">
                        {item?.vehicleDetail?.name || ""}
                      </TableCell>
                      <TableCell align="center">
                        {item?.userDetail?.email || ""}
                      </TableCell>
                      <TableCell align="center">
                        {item?.loanStatus === 2
                          ? "Approved"
                          : item?.loanStatus === 1
                            ? "Pending"
                            : "-" || ""}
                      </TableCell>
                      <TableCell align="center">
                        {item?.createdAt
                          ? moment(item?.createdAt).format("DD/MM/YYYY")
                          : "-" || ""}
                      </TableCell>
                      <TableCell align="center">
                        {item?.typeDetail?.name || ""}
                      </TableCell>
                      <TableCell align="center">
                        $ {item?.totalLoanAmount?.toFixed(2) || ""}
                      </TableCell>
                      <TableCell align="center">
                        $ {item?.downPayment?.toFixed(2) || ""}
                      </TableCell>
                      <TableCell align="center">
                        $ {item?.paidTotal?.toFixed(2) || ""}
                      </TableCell>
                      <TableCell align="center">
                        $ {item?.currentPendingAmount?.toFixed(2) || ""}
                      </TableCell>
                      <TableCell align="center">
                        {item?.interestRate || ""}
                      </TableCell>
                      <TableCell align="center">
                        $ {item?.emi?.toFixed(2) || ""}
                      </TableCell>
                      <TableCell align="center">{item?.tenure || ""}</TableCell>
                      <TableCell align="center">
                        {item?.installmentsPaid || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {item?.remainingInstallments || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {item?.doc1UploadedAt
                          ? moment(item?.doc1UploadedAt).format("DD/MM/YYYY")
                          : "-" || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {item?.doc1ApprovedAt
                          ? moment(item?.doc1ApprovedAt).format("DD/MM/YYYY")
                          : "-" || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {item?.doc2UploadedAt
                          ? moment(item?.doc2UploadedAt).format("DD/MM/YYYY")
                          : "- " || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {item?.doc2ApprovedAt
                          ? moment(item?.doc2ApprovedAt).format("DD/MM/YYYY")
                          : "-" || "-"}
                      </TableCell>
                      <TableCell align="center">
                        {item?.loanStatus === 4 ? "Loan Completed" : "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              : null}
          </Table>
        </TableContainer>
        <Pagination
          module={data}
          page={page}
          totalPages={totalpages}
          onPageChange={onPageChange}
        />
      </div>
      {/* <DatePicker value={dateValue} setValue={setDateValue} open={openModal} onClose={handleCloseModal} setOpen={setOpenModal} /> */}
    </MainContainer>
  );
};

export default LoanReport;
