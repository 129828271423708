import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import {
  CommonBody,
  InterestRate,
  InterestRateResponse,
  UserData,
} from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type QueryParams = {
  page: number;
  size: number;
  search: string;
};

export const InterestApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getInterest: builder.query<
      CommonResponseType & { data: InterestRateResponse },
      QueryParams
    >({
      query: ({ page, size, search }) => ({
        url: `${END_POINTS.interestRate}?page${page}&size=${size}&search=${search}`,
        method: "GET",
      }),
    }),
    getALlInterest: builder.query<
      CommonResponseType & { data: InterestRateResponse },
      {}
    >({
      query: ({}) => ({
        url: `${END_POINTS.interestRate}`,
        method: "GET",
      }),
    }),
    getALLInterestProduct: builder.query<
      CommonResponseType & { data: InterestRateResponse },
      {}
    >({
      query: ({}) => ({
        url: `${END_POINTS.interestRate}?isBlocked=false`,
        method: "GET",
      }),
    }),
    getInterestById: builder.query<
      CommonResponseType & { data: InterestRate },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.interestRate}/${id}`,
        method: "GET",
      }),
    }),

    addInterest: builder.mutation<
      CommonResponseType & { data: InterestRate },
      { body: CommonBody }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.interestRate}`,
        method: "POST",
        body,
      }),
    }),
    updateInterest: builder.mutation<
      CommonResponseType & { data: InterestRate },
      { body: CommonBody; id: string }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.interestRate}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteInterest: builder.query<
      CommonResponseType & { data: InterestRate },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.interestRate}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetInterestQuery,
  useAddInterestMutation,
  useUpdateInterestMutation,
  useLazyDeleteInterestQuery,
  useLazyGetInterestByIdQuery,
  useLazyGetALlInterestQuery,
  useLazyGetALLInterestProductQuery
} = InterestApi;
