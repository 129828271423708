export const isString = (val: any) => {
    if (val.includes(".")) {
      return true;
    }
    if (val.length === 1 && val === " ") {
      return false;
    }
    if(/^-\d+(\.\d+)?$/.test(val)){
      return false
    }
    if (
      val[val.length - 1] === " " &&
      val[val.length - 1] !== val[val.length - 2]
    ) {
      return true;
    }
    
    if (
      val[val.length - 1]?.trim()?.toLowerCase() !==
        val[val.length - 1]?.trim()?.toUpperCase() ||
      val === ""
    ) {
      return true;
    }
    return false;
  };
  export const isFloat = (val: any) => {
    if (val[val.length - 1] === " " || val === "." || val === "0") {
      return false;
    }
    if (val.includes(".")) {
      val = val.replace(".", "");
      // eslint-disable-next-line no-restricted-globals
      if ((!val.includes(".") && !isNaN(val?.trim())) || val === "") {
        return true;
      }
      return false;
    }
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(val?.trim()) || val === "") {
      return true;
    }
    return false;
  };
  export const isNumber = (val: any) => {
    if (val[val.length - 1] === " ") {
      return false;
    }
    if (val.includes(".")) {
      return false;
    }
    if(/^-\d+(\.\d+)?$/.test(val)){
      return false
    }
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(val?.trim()) || val === "") {
      return true;
    }
    return false;
  };
  
  export const isValidInput = (value: string) => {
    if (value === "") {
      return true;
    }
    if (value.trim() === "") {
      return false; // Reject if the value is empty or only consists of whitespace
    }

    if(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(value)){
      return false;
    }
  
    if (value.includes("  ") || value.includes("..")) {
      return false; // Reject if there are consecutive spaces or decimals
    }
  
    return true; // Accept the input if it meets all the conditions
  };

  export const isNumberPrice = (value:any) => {

    const regex = /^(?!.*\..*\.)[0-9]*(\.[0-9]{0,2})?$/;
    if(/^-\d+(\.\d+)?$/.test(value)){
      return false
    }
    
    return regex.test(value);
  };

  export const check=(val:any)=>{
    if(/^-\d+(\.\d+)?$/.test(val)){
      return false
    }
  }

  export const isNumberScore = (val: any) => {
    if (val[val.length - 1] === " ") {
      return false;
    }
    if (val.includes(".")) {
      return false;
    }
    
   
    if (!isNaN(val?.trim()) || val === "") {
      return true;
    }
    return true;
  };
  
  