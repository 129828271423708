import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import Prequalified from "../../features/manageCustomers/Prequalified";
import Qualified from "../../features/manageCustomers/Qualified";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import Existing from "../../features/manageCustomers/Existing";
import useTranslation from "../../hooks/translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageUsers = () => {
  const userData=useAuth()
  const navigate = useNavigate();
  const location=useLocation();
  const translation=useTranslation() as any;
  const {state}=location
  const [value, setValue] = useState<number>(0);
  const [hidePermission, setHidePermission] = useState<
  Permissions | null | undefined
>(null);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // setSearchTerm("");
    // setDebouncedSearchTerm("");
  };
  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "+971056734321",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "10",
      "600 SAR"
    ),
  ];

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Customers"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };
  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(()=>{
    if(state?.value){
      setValue(state.value)
    }
  },[])
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.header.manageCustomer}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label={translation.tabs.all} {...a11yProps(0)} />
                <Tab label={translation.tabs.new} {...a11yProps(1)} />
                <Tab label={translation.tabs.existing} {...a11yProps(2)} />
              </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
              <Prequalified hidePermission={hidePermission}/>
            </CustomTabPanel>

            <CustomTabPanel  value={value} index={1}>
             <Qualified  hidePermission={hidePermission}/> 
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
             <Existing  hidePermission={hidePermission}/> 
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageUsers;
