import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, paymentAPiData ,GetPaymentApi} from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type QueryParams = {
  page: number;
  size: number;
  search?: string;
};

export const paymentWays = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addWays: builder.mutation<
      CommonResponseType & { data: paymentAPiData },
      { body: CommonBody }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.payment}`,
        method: "POST",
        body
      }),
    }),
    updateWays: builder.mutation<
      CommonResponseType & { data: paymentAPiData },
      { body: CommonBody,id:string }
    >({
      query: ({ body ,id}) => ({
        url: `${END_POINTS.payment}/${id}`,
        method: "PUT",
        body
      }),
    }),
    deleteWays: builder.query<
      CommonResponseType & { data: paymentAPiData },
      {id:string }
    >({
      query: ({id}) => ({
        url: `${END_POINTS.payment}/${id}`,
        method: "DELETE",
      }),
    }),

    getWays:builder.query<
    CommonResponseType & {data:GetPaymentApi},
    QueryParams
    >({
        query:({size,search,page})=>({
            url:`${END_POINTS.payment}?search=${search}&size=${size}&page=${page}`,
            method:"GET"
        })
    }),
    getWaysById:builder.query<
    CommonResponseType & {data:GetPaymentApi},
    QueryParams & {id:string}
    >({
        query:({size,search,page,id})=>({
            url:`${END_POINTS.payment}/${id}?search=${search}&size=${size}&page=${page}`,
            method:"GET"
        })
    }),
  }),
});

export const { useAddWaysMutation,useLazyGetWaysQuery,useUpdateWaysMutation,useLazyDeleteWaysQuery,useLazyGetWaysByIdQuery } = paymentWays;
