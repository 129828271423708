import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import {
  CommonBody,
  InterestRate,
  InterestRateResponse,
  Product,
  UserData,
  VehicleResponse,
} from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type QueryParams = {
  page: number;
  size: number;
  search: string;
};

export const ManageCars = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addCars: builder.mutation<
      CommonResponseType & { data: Product },
      { body: CommonBody }
    >({
      query: ({ body }) => ({
        url: `${END_POINTS.vehicle}`,
        method: "POST",
        body,
      }),
    }),
    updateVehicle: builder.mutation<
      CommonResponseType & { data: Product },
      { body: CommonBody; id: string }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.vehicle}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    getCars: builder.query<
      CommonResponseType & { data: VehicleResponse },
      QueryParams
    >({
      query: ({ page, size, search }) => ({
        url: `${END_POINTS.vehicle}?page=${page}&size=${size}&search=${search}`,
        method: "GET",
      }),
    }),

    deleteCars: builder.query<
      CommonResponseType & { data: Product },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.vehicle}/${id}`,
        method: "DELETE",
      }),
    }),

    getCarsById: builder.query<
      CommonResponseType & { data: Product },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.vehicle}/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddCarsMutation,
  useLazyGetCarsQuery,
  useLazyDeleteCarsQuery,
  useLazyGetCarsByIdQuery,
  useUpdateVehicleMutation,
} = ManageCars;
