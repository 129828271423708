import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { isString, useFormik } from "formik";
import * as Yup from "yup";
import { ChangeEvent, useEffect, useState } from "react";
import {
  useAddTestimonialsMutation,
  useLazyGetTestimonialsByIdQuery,
  useUpdateTestimonialsMutation,
} from "../../services/testimonial";
import { showError, showToast } from "../../constants/toast";
import { UploadMedia } from "../../utils/mediaUpload";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, testimonialResponse } from "../../types/General";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/translation";

const AddTestimonials = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { state } = location;
  const translation=useTranslation() as any;
  const [category, setCategory] = useState<string>("");
  const [loading,setLoading]=useState(false)

  //States

  const [image, setImage] = useState<string>("");

  const [data, setData] = useState<testimonialResponse>();

  //API Hooks
  const [addTestimonials] = useAddTestimonialsMutation();
  const [getTestimonial, { isLoading }] = useLazyGetTestimonialsByIdQuery();
  const [updateaTestimonial] = useUpdateTestimonialsMutation();

  //API calls

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if (
      file &&
      file.type &&
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    ) {
      showError("Invalid Image Type ");
      return;
    }

    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      const uploadedImages = res?.data;
      console.log(uploadedImages);
      setImage(uploadedImages);
    } else {
      showError(res?.message);
    }
    // API Calling
  };

  const fetchTestimonial = async (id: string) => {
    setLoading(true)
    try {
      const res = await getTestimonial({
        id: id,
      }).unwrap();
      if (res?.statusCode === 200) {
        setData(res?.data || {});

        setImage(res?.data?.image || "");
        setLoading(false)
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false)
    }
  };

  //Form-handling

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.user || "",
      title: data?.title || "",
      description: data?.description || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("This field is Required")
        .min(2, "Minimum 2 characters are required")
        .max(16, "Maximum 16 characters are allowed"),
      title: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(16, "Maximum 16 characters are allowed"),
      description: Yup.string().required("This field is required"),
    }),

    onSubmit: async (values) => {
      if (!image) {
        showError("Image is required");
        return;
      }
      const body = {
        title: formik.values.title,
        user: formik.values.name,
        description: formik.values.description,
        image: image,
      };

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      if (id) {
        try {
          const res = await updateaTestimonial({
            id: id,
            body: encryptedBody,
          }).unwrap();
          if (res?.statusCode === 200) {
            showToast("Testimonial updated");
            navigate("/manage-testimonials");
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      } else {
        try {
          console.log("hello")
          const res = await addTestimonials({
            body: encryptedBody,
          }).unwrap();
          if (res?.statusCode === 200) {
            showToast("Testimonial added");
            navigate("/manage-testimonials");
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }
    },
  });

  useEffect(() => {
    if (id) {
      fetchTestimonial(id);
    }
  }, []);
  return (
    <>
      <MainContainer>
        {id ? <Loader isLoad={loading} /> : null}
        <div className="main_loyout">
          <div className="dashboard">
            <h1>
              {state && state.type === "edit" ? "Edit" : "Add"} Testimonial
            </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-testimonials");
              }}
            >
              {translation.buttons.back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">{translation.tableHeads.image}</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">{translation.textFields.name}</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      fullWidth
                      value={formik.values.name}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.name && formik.errors.name}
                      inputProps={{
                        maxLength: 20,
                      }}
                      placeholder={translation.textFields.name}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">{translation.textFields.title}</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      value={formik.values.title}
                      fullWidth
                      placeholder={translation.textFields.title}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={formik.touched.title && formik.errors.title}
                      inputProps={{
                        maxLength: 20,
                      }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.textFields.description}
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      multiline
                      minRows={4}
                      maxRows={4}
                      value={formik.values.description}
                      name="description"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.textFields.description}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                      inputProps={{
                        maxLength: 250,
                      }}
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translation.buttons.save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddTestimonials;
