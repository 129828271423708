import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, Tags, TagsResponse, UserData } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type QueryParams = {
    page: number;
    size: number;
    search: string;
  };

export const TagsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

    getTags:builder.query<
    CommonResponseType & {data:TagsResponse},
    QueryParams
    >({
        query:({page,size,search})=>({
            url:`${END_POINTS.category}?page=${page}&size=${size}&search=${search}`,
            method:"GET"
        })
    }),
    getAllTags:builder.query<
    CommonResponseType & {data:TagsResponse},
    {}
    >({
        query:({})=>({
            url:`${END_POINTS.category}`,
            method:"GET"
        })
    }),
    getTagsById:builder.query<
    CommonResponseType & {data:Tags},
    {id:string}
    >({
        query:({id})=>({
            url:`${END_POINTS.category}/${id}`,
            method:"GET"
        })
    }),
    addTags:builder.mutation<
    CommonResponseType & {data:Tags},
    {body:CommonBody}
    >({
        query:({body})=>({
            url:`${END_POINTS.category}`,
            method:"POST",
            body
        })
    }),
    updateTags:builder.mutation<
    CommonResponseType & {data:Tags},
    {body:CommonBody,id:string}
    >({
        query:({body,id})=>({
            url:`${END_POINTS.category}/${id}`,
            method:"PUT",
            body
        })
    }),
    deleteTags:builder.query<
    CommonResponseType & {data:TagsResponse},
    {id:string}
    >({
        query:({id})=>({
            url:`${END_POINTS.category}/${id}`,
            method:"DELETE"
        })
    }),

    getAllTagsProduct:builder.query<
    CommonResponseType & {data:TagsResponse},
    {}
    >({
        query:({})=>({
            url:`${END_POINTS.category}?isBlocked=false`,
            method:"GET"
        })
    }),

  }),
});

export const {
    useLazyGetTagsQuery,
    useLazyDeleteTagsQuery,
    useLazyGetTagsByIdQuery,
    useAddTagsMutation,
    useUpdateTagsMutation,
    useLazyGetAllTagsQuery,
    useLazyGetAllTagsProductQuery
} = TagsApi;
