import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/bootstrap.css";
import { showError, showToast } from "../../constants/toast";
import { useAddCmsMutation, useLazyGetCmsQuery } from "../../services/cms";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const translation=useTranslation() as any;
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [aboutUs, setAboutUs] = useState<string>("");
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [cmsId, setCmsId] = useState("");
  const [value, setValue] = React.useState(0);
  const [loading,setLoading]=useState(false)
  const [getCms, { isLoading }] = useLazyGetCmsQuery();
  const [updateCms] = useAddCmsMutation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
    }),

    onSubmit: async (values) => {
      if (aboutUs?.length <= 8) {
        return showError("About us should not be empty");
      }
      if (termsAndConditions?.length <= 8) {
        return showError("Terms and conditions should not be empty");
      }

      if (privacyPolicy?.length <= 8) {
        return showError("Privacy policy should not be empty");
      }

      console.log(termsAndConditions,"terms")
      const body = {
        email: formik.values.email,
        phone: formik.values.phone,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        termsAndCondition: termsAndConditions,

        privacyPolicy: privacyPolicy,

        about_us: aboutUs,
      };
      console.log(body,"body")

      let encryptedBody=generateEncryptedKeyBody(body)as CommonBody

      try{
        const res=await updateCms({
          body:encryptedBody,
          id:cmsId
        }).unwrap()

        if(res?.statusCode===200){
          showToast("CMS updated")
          await fetchCms()
        }

      }catch(error:any){
        showError(error?.data?.message)
      }
    },
  });

  const fetchCms = async () => {
    setLoading(true)
    try {
      const res = await getCms({}).unwrap();
      if (res?.statusCode === 200) {
        formik.setFieldValue("email", res?.data?.email);
        formik.setFieldValue("phone", res?.data?.phone);
        setAboutUs(res?.data?.about_us || " ");
        setPhoneCode(res?.data?.countryCode || "");
        setPrivacyPolicy(res?.data?.privacyPolicy || " ");
        setTermsAndConditions(res?.data?.termsAndCondition || " ");
        setCmsId(res?.data?._id || "");
        setLoading(false)
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false)
    }
  };
  useEffect(() => {
    fetchCms();
  }, []);
  return (
    <MainContainer>
      <Loader isLoad={loading}/>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.header.manageCms}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label={translation.tabs.contactus} {...a11yProps(0)} />
              <Tab label={translation.tabs.aboutUs} {...a11yProps(1)} />

              <Tab label={translation.tabs.privacy} {...a11yProps(2)} />
              <Tab label={translation.tabs.termsAndCondition} {...a11yProps(3)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">{translation.textFields.email}</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder={translation.textFields.email}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 40 }}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                    {translation.textFields.phone}
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      value={phoneCode + formik.values.phone}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                       {translation.buttons.save}
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form onSubmit={formik.handleSubmit}>
                <EditText content={aboutUs} setContent={setAboutUs} />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                       {translation.buttons.save}
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                       {translation.buttons.save}
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={termsAndConditions}
                  setContent={setTermsAndConditions}
                />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translation.buttons.save}
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;
