import { Backdrop, Box, Button, Fade, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Dispatch, SetStateAction } from "react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  images: string[] | undefined;
};

const DocumentSlider = ({ open, setOpen, images }: props) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleBackdropClick = (event: any) => {
    console.log(event,"event");
    
    if (event.target.classList.contains("modalBox_body")) {
      setOpen(false);
    }
  };
  return (  

    <Modal
      className="modal gallery_modal"
      open={open}
      onClose={handleClose}
      hideBackdrop={false} // Add this line
      onClick={handleBackdropClick} 
      
  
    >
      {/* <Fade in={open}> */}
        <Box className="modalBox_body">
          <Box>
            {/* <Button className="close_btn" onClick={() => setOpen(false)}>
              <CloseIcon />
            </Button> */}
            <Swiper
              className="location_swiper gallery_swiper"
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={10}
              slidesPerView={1}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              pagination={{
                el: ".swiper-pagination",
                dynamicBullets: true,
                clickable: true,
              }}
              loop
              observer
            >
                 {/* <Button className="close_btn" 
                    onClick={handleClose}
                 >
              <CloseIcon />
            </Button> */}
             
              <div className="swiper_action">
                <div className="swiper-button-prev">
                  <ArrowBackIcon />
                </div>
                <div className="swiper-pagination"></div>
                <div className="swiper-button-next">
                  <ArrowForwardIcon />
                </div>
              </div>
              {images?.length
                ? images?.map((item, i) => (
                    <SwiperSlide key={i}>
                      <figure>
                        <img
                          src={item || "/images/property-detail-01.jpg"}
                          alt=""
                        />
                      </figure>
                    </SwiperSlide>
                  ))
                : null}
            </Swiper>
          </Box>
        </Box>
      {/* </Fade> */}
    </Modal>
  );
};

export default DocumentSlider;