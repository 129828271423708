import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { AddBanerRes, BannerResponse, CommonBody } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
    statusCode: number;
    message: string;
};
type QueryParams = {
    page: number;
    size: number;
    search: string;
  };


export const BannerApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({

       addBaner:builder.mutation<
       CommonResponseType & {data:AddBanerRes},
       {body:CommonBody}>({
        query:({body})=>({
            url:`${END_POINTS.banner}`,
            method:"POST",
            body
        })
       }),
       updateBanner:builder.mutation<
       CommonResponseType & {data:AddBanerRes},
       {body:CommonBody,id:string}>({
        query:({body,id})=>({
            url:`${END_POINTS.banner}/${id}`,
            method:"PUT",
            body
        })
       }),

       getBanner:builder.query<
       CommonResponseType & {data:BannerResponse},
       QueryParams>({
        query:({search,size,page})=>({
            url:`${END_POINTS.banner}?page=${page}&size=${size}&search=${search}`,
            method:"GET"
        })
       }),
       getBannerById:builder.query<
       CommonResponseType & {data:AddBanerRes},
       {id:string}>({
        query:({id})=>({
            url:`${END_POINTS.banner}/${id}`,
            method:"GET"
        })
       }),

       deleteBanner:builder.query<
       CommonResponseType & {data:AddBanerRes},
       {id:CommonBody}>({
        query:({id})=>({
            url:`${END_POINTS.banner}/${id}`,
            method:"DELETE",
            
        })
       }),
      

       

       

      


        



        

    })
})

export const {
   useAddBanerMutation,
   useLazyGetBannerQuery,
   useLazyDeleteBannerQuery,
   useUpdateBannerMutation,
   useLazyGetBannerByIdQuery
    
}=BannerApi

