import React, { ChangeEvent, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Input,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import AddIcon from "@mui/icons-material/Add";

const AddServiceProvider = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [frontDocImage, setFrontDocImage] = useState<string>("");
  const [backDocImage, setBackDocImage] = useState<string>("");
  const [image, setImage] = useState<string>("");

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          {state?.type === "edit" ? (
            <h1 className="mn_hdng">Edit Advertiser</h1>
          ) : (
            <h1 className="mn_hdng">Add Advertiser</h1>
          )}
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-advertisers");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="custom_label">Image</Typography>
                  {image ? (
                    <div className="upload_image_preview">
                      <CardMedia component="img" image={image} alt="photo" />
                      <CancelIcon
                        onClick={() => {
                          setImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const files = (event.target as HTMLInputElement)
                              .files;
                            if (files && files[0].type.includes("image")) {
                              setImage(URL.createObjectURL(files[0]));
                            }
                          }}
                        />
                        <Button component="span" className="upload_image_btn">
                          <img
                            src="/static/images/user_placeholder.png"
                            alt=""
                          />
                          <CameraAltIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                {/* <Grid item xs={3}>
              <Typography className="custom_label">
                Account Status : Pending
              </Typography>
            </Grid> */}
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">First Name</Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="First Name"
                    // id="firstName"
                    // onChange={(val) => {
                    //   if (
                    //     val.target.value === " " ||
                    //     val.target.value === "."
                    //   ) {
                    //   } else if (isString(val.target.value)) {
                    //     formik.handleChange(val);
                    //   }
                    // }}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.firstName}
                    // helperText={
                    //   formik.touched.firstName && formik.errors.firstName
                    // }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Last Name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="lastName"
                    variant="outlined"
                    fullWidth
                    placeholder="Last Name"
                    // id="lastName"
                    // className="text_field"
                    // onChange={(val) => {
                    //   if (
                    //     val.target.value === " " ||
                    //     val.target.value === "."
                    //   ) {
                    //   } else if (isString(val.target.value)) {
                    //     formik.handleChange(val);
                    //   }
                    // }}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.lastName}
                    // helperText={
                    //   formik.touched.lastName && formik.errors.lastName
                    // }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Email</Typography>
                  <TextField
                    hiddenLabel
                    type={"email"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Email"
                    // id="email"
                    // className="text_field"
                    // onChange={(val) => {
                    //   if (
                    //     val.target.value === " " ||
                    //     val.target.value === "."
                    //   ) {
                    //   } else {
                    //     formik.handleChange(val);
                    //   }
                    // }}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.email}
                    // helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Phone Number</Typography>
                  <PhoneInput
                    enableSearch
                    // value={formik.values.countryCode + formik.values.phone}
                    country={"us"}
                    inputClass="phoneInput"
                    inputStyle={{ width: "100%" }}
                    buttonClass="phoneBtn"
                    placeholder="Phone Number"
                    // onChange={(phone, country) =>
                    //   handleChangePhone(phone, country)
                    // }
                    // onBlur={formik.handleBlur}
                  />
                  {/* {formik.touched.phone && formik.errors.phone ? (
                    <h6 className="err_msg">
                      {formik.touched.phone && formik.errors.phone}
                    </h6>
                  ) : (
                    ""
                  )} */}
                </Grid>
                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Date of Birth
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"date"}
                    name="dob"
                    variant="outlined"
                    fullWidth
                    placeholder="DOB"
                    id="dob"
                    className="text_field"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dob}
                    helperText={formik.touched.dob && formik.errors.dob}
                  />
                </Grid> */}
                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Bio</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="bio"
                    variant="outlined"
                    fullWidth
                    placeholder="Bio"
                    className="text_field"
                    id="bio"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.bio}
                    helperText={formik.touched.bio && formik.errors.bio}
                  />
                </Grid> */}
                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Headline</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="headline"
                    variant="outlined"
                    fullWidth
                    placeholder="Headline"
                    className="text_field"
                    id="headline"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.headline}
                    helperText={
                      formik.touched.headline && formik.errors.headline
                    }
                  />
                </Grid> */}
                <Grid item xs={12} className="heading">
                  <Typography className="custom_label" variant="h5">
                    Address
                  </Typography>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Address line 1
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="address"
                    variant="outlined"
                    fullWidth
                    placeholder="Address line 1"
                    // className="text_field"
                    // id="address"
                    // onChange={(val) => {
                    //   if (
                    //     val.target.value === " " ||
                    //     val.target.value === "."
                    //   ) {
                    //   } else {
                    //     formik.handleChange(val);
                    //   }
                    // }}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.address}
                    // helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Address line 2
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="address"
                    variant="outlined"
                    fullWidth
                    placeholder="Address line 2"
                    // className="text_field"
                    // id="address"
                    // onChange={(val) => {
                    //   if (
                    //     val.target.value === " " ||
                    //     val.target.value === "."
                    //   ) {
                    //   } else {
                    //     formik.handleChange(val);
                    //   }
                    // }}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.address}
                    // helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    City/State/Location
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="address"
                    variant="outlined"
                    fullWidth
                    placeholder="City/State/Location"
                    // className="text_field"
                    // id="address"
                    // onChange={(val) => {
                    //   if (
                    //     val.target.value === " " ||
                    //     val.target.value === "."
                    //   ) {
                    //   } else {
                    //     formik.handleChange(val);
                    //   }
                    // }}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.address}
                    // helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    PIN Code/Zipcode
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="address"
                    variant="outlined"
                    fullWidth
                    placeholder="PIN Code/Zipcode"
                    // className="text_field"
                    // id="address"
                    // onChange={(val) => {
                    //   if (
                    //     val.target.value === " " ||
                    //     val.target.value === "."
                    //   ) {
                    //   } else {
                    //     formik.handleChange(val);
                    //   }
                    // }}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.address}
                    // helperText={formik.touched.address && formik.errors.address}
                  />
                </Grid>
                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Select Profession
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formik.values.profession}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleProfessionChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value={1}>Chef</MenuItem>
                      <MenuItem value={2}>Photographer</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.profession && formik.errors.profession ? (
                    <h6 className="err_msg">
                      {formik.touched.profession && formik.errors.profession}
                    </h6>
                  ) : (
                    ""
                  )}
                </Grid> */}
                {/* <Grid item xs={12} className="heading">
                  <Typography className="custom_label" variant="h5">
                    Documents
                  </Typography>
                </Grid> */}

                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    A/C Holder Name
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="holderName"
                    variant="outlined"
                    fullWidth
                    placeholder="Name"
                    className="text_field"
                    id="holderName"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.holderName}
                    helperText={
                      formik.touched.holderName && formik.errors.holderName
                    }
                  />
                </Grid> */}
                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Swift Code</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="swiftCode"
                    variant="outlined"
                    fullWidth
                    placeholder="Swift Code"
                    className="text_field"
                    id="swiftCode"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.swiftCode}
                    helperText={
                      formik.touched.swiftCode && formik.errors.swiftCode
                    }
                  />
                </Grid> */}
                {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Bank Name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="bankName"
                    variant="outlined"
                    fullWidth
                    placeholder="Bank Name"
                    className="text_field"
                    id="bankName"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.bankName}
                    helperText={
                      formik.touched.bankName && formik.errors.bankName
                    }
                  />
                </Grid> */}
                <Grid item xs={12} className="heading">
                  <Typography className="custom_label" variant="h5">
                    Upload Documents
                  </Typography>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Name of the Document
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="accountNumber"
                    variant="outlined"
                    fullWidth
                    placeholder=" Name of the Document"
                    className="text_field"
                    // id="accountNumber"
                    // onChange={(val) => {
                    //   if (
                    //     val.target.value === " " ||
                    //     val.target.value === "."
                    //   ) {
                    //   } else if (isNumber(val.target.value)) {
                    //     formik.handleChange(val);
                    //   }
                    // }}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.accountNumber}
                    // helperText={
                    //   formik.touched.accountNumber &&
                    //   formik.errors.accountNumber
                    // }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">Expiry Date</Typography>
                  <TextField
                    hiddenLabel
                    type={"date"}
                    name="accountNumber"
                    variant="outlined"
                    fullWidth
                    placeholder="Expiry Date"
                    className="text_field"
                    // id="accountNumber"
                    // onChange={(val) => {
                    //   if (
                    //     val.target.value === " " ||
                    //     val.target.value === "."
                    //   ) {
                    //   } else if (isNumber(val.target.value)) {
                    //     formik.handleChange(val);
                    //   }
                    // }}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.accountNumber}
                    // helperText={
                    //   formik.touched.accountNumber &&
                    //   formik.errors.accountNumber
                    // }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12} />
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">
                    {" "}
                    Document Front Image
                  </Typography>
                  {frontDocImage ? (
                    <div className="upload_image_preview2">
                      <CardMedia
                        component="img"
                        image={frontDocImage}
                        alt="photo"
                      />
                      <CancelIcon
                        onClick={() => {
                          setFrontDocImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="front-doc">
                        <Input
                          sx={{ display: "none" }}
                          id="front-doc"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const files = (event.target as HTMLInputElement)
                              .files;
                            if (files && files[0].type.includes("image")) {
                              setFrontDocImage(URL.createObjectURL(files[0]));
                            }
                          }}
                        />
                        <Button component="span" className="upload_image_btn">
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">
                    Document Back Image
                  </Typography>
                  {backDocImage ? (
                    <div className="upload_image_preview2">
                      <CardMedia
                        component="img"
                        image={backDocImage}
                        alt="photo"
                      />
                      <CancelIcon
                        onClick={() => {
                          setBackDocImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="back-doc">
                        <Input
                          sx={{ display: "none" }}
                          id="back-doc"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const files = (event.target as HTMLInputElement)
                              .files;
                            if (files && files[0].type.includes("image")) {
                              setBackDocImage(URL.createObjectURL(files[0]));
                            }
                          }}
                          //   onChange={handleImageUpload}
                        />
                        <Button component="span" className="upload_image_btn">
                          {/* <img
                                src={
                                  image
                                    ? image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              /> */}
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddServiceProvider;
