import { Box, Modal } from "@mui/material";
import { FadeLoader } from "react-spinners";

const Loader = (props: any) => {
  const style = {
    outline: "none",
  };
  return (
    <Box>
      {props.isLoad ? (
        <Modal open>
          <Box className="loader_loading" sx={style}>
            {/* <figure>
              <img src="/static/images/loader.gif" alt="loading" />
            </figure> */}
            <FadeLoader
              color="#84dbca"
              height={10}
              margin={-2}
              radius={16}
              speedMultiplier={1}
              width={4}
            />
          </Box>
        </Modal>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Loader;
