import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetCarsByIdQuery } from "../../services/manageCars";
import { Product } from "../../types/General";
import { showError } from "../../constants/toast";
import { DocumentViewer } from "../../components";
import DocumentSlider from "../../components/DocumentSlider";
import Loader from "../../constants/Loader";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
type LoanTenureItem = {
  loanTenure: string;
  loanRate: string;
};
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProductDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  //States
  const [data, setData] = useState<Product>();
  const [myImages, setMyImages] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loading,setLoading]=useState<boolean>(false)
  const [loanTenure, setLoanTenure] = useState<LoanTenureItem[]>([
    
  ]);

  //API hooks

  const [getCarsById] = useLazyGetCarsByIdQuery();

  //Methods

  //API calling

  const fetchCars = async (id: string) => {
    setLoading(true)
    try {
      const res = await getCarsById({
        id: id,
      }).unwrap();
      console.log(res, "response");
      if (res?.statusCode === 200) {
        setData(res?.data || {});
        // setTag(res?.data?.categoryId)
        // setCategoryID(res?.data?.typeId)
        // setTag(res?.data?.)

        setMyImages(res?.data?.image);

        const newData = res?.data?.loanDetails.map((item) => {
          return {
            loanTenure: String(item?.loanTenure),
            loanRate: String(item?.loanRate),
          };
        });
        if (newData) {
          setLoanTenure(newData);
        }
        setLoading(false)
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false)
    }
  };

  useEffect(() => {
    if (id) {
      fetchCars(id);
    }
  }, []);

  return (
    <MainContainer>
     <Loader isLoad={loading}/>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Car Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-cars");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                      Car Details
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box>
                          <Typography component="h5">Car Images</Typography>
                          <Box className="docs_div" sx={{alignItems:"center"}}>
                            <figure>
                              {/* <VisibilityIcon
                                onClick={() => {
                                  setOpen(true);
                                }}
                              /> */}

                              <img
                                src={myImages[0]}
                                alt="/static/images/post.png"
                              />
                            </figure>
                            {myImages.length>1?(

                            <Button
                              className="btn btn_primary"
                              onClick={() => {
                                setOpen(true)
                              }}
                            >
                              View all images
                            </Button>
                            ):null}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Name</Typography>
                          <Typography component="p">
                            {data?.name || ""}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Category</Typography>
                          <Typography component="p">
                            {" "}
                            {data?.categoryName || ""}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Model</Typography>
                          <Typography component="p">
                            {" "}
                            {data?.model || ""}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Price</Typography>
                          <Typography component="p">
                            ${data?.price || ""}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            Guarantee Period
                          </Typography>
                          <Typography component="p">
                            {data?.guaranteePeriod || ""}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            Minimum Downpayment 
                          </Typography>
                          <Typography component="p">
                            {data?.minDownPayment || ""}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            Maximum Downpayment 
                          </Typography>
                          <Typography component="p">
                            {data?.maxDownPayment || ""}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <h3 className="mn_hdng">Loan Details</h3>
            </Box>

            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Loan Tenure (Months)</TableCell>
                    <TableCell>Interest Rate</TableCell>
                  </TableRow>
                </TableHead>

                {loanTenure?.map((item, index) => (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{item?.loanTenure}</TableCell>
                      <TableCell align="center">
                        {item?.loanRate}% (P.A)
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      <DocumentSlider open={open} setOpen={setOpen} images={myImages} />
    </MainContainer>
  );
};

export default ProductDetails;
