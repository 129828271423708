import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import { NotificationResponse } from "../../types/General";
import { useLazyDeleteAdminNotificationQuery, useLazyDeleteNotificationQuery, useLazyGetNotificationsAdminQuery } from "../../services/notifications";
import { showError } from "../../constants/toast";
import moment from "moment";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import { isValidInput } from "../../utils/validations";
import Loader from "../../constants/Loader";

const RecievedNotifications = () => {
  const [data, setData] = useState<NotificationResponse[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [getNotifications] = useLazyGetNotificationsAdminQuery();
  const [deleteById]=useLazyDeleteNotificationQuery()
  const [deleteAll]=useLazyDeleteAdminNotificationQuery()
  const [loading,setLoading]=useState(false)
  function createData(
    CustomerName: string,
    CustomerUsername: string,
    CustomerEmail: string,
    title: string,
    message: string
  ) {
    return { CustomerName, CustomerUsername, CustomerEmail, title, message };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "abdulhussian67@gmail.com",
      "Title",
      "Message"
    ),
  ];
  const fetchNotifications = async () => {
    setLoading(true)
    try {
      const res = await getNotifications({
        size: 10,
        page: page,
        search: debouncedSearchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
          console.log(res?.data ,"ff")
        const value=res?.data?.data ||[]
        // setData(res?.data?.data);
        setData(res?.data?.data)
        setTotalCount(res?.data?.count);
        if (value.length === 0 && page > 1) {
          setPage(page-1)
        }
        setLoading(false)
      } else {
        setData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(true)
    }
  };

  const handleDeleteAll=async()=>{
    try{
      const res=await deleteAll({}).unwrap()
      if(res?.statusCode===200){

        fetchNotifications()

      }
    }catch(error:any){
      showError(error?.data?.message)
    }
  }
  const totalPages=Math.ceil(totalCount/10)
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchNotifications();
  }, [searchTerm, debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={loading}/>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Recieved Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            {/* <SearchBar /> */}
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={handleDeleteAll}
                disabled={!data?.length}
                sx={{
                  '&.Mui-disabled': {
                    opacity: 0.5, 
                    cursor: 'not-allowed' ,
                    
                  }
                }}
              >
                Clear All
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Date</TableCell>
                  {/* <TableCell>Customer Email</TableCell> */}
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {data?.length ? (
                  data?.map((item,index)=>(
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                        <TableCell align="center">{moment(item?.createdAt).format("DD/MM/YYYY") || "---"}</TableCell>
                        <TableCell align="center">{item?.title || "---"}</TableCell>
                        <TableCell align="center">{item?.message.length > 10
                              ? `${item.message.substring(0, 15)}...`
                              : item?.message || "----"}</TableCell>
                        <TableCell align="center">
                      <Box className="table_actions">
                        <IconButton 
                        onClick={()=>{
                          setOpen(true)
                          setSelectedId(item?._id)
                        }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>

                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    No notification Found
                  </TableCell>
                </TableBody>
                )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Pagination
      module={data}
      page={page}
      onPageChange={onPageChange}
      totalPages={totalPages}/>
      <WarnModal
      name="notification"
      open={open}
      setOpen={setOpen}
      handleDelete={()=>{
        handleDelete(deleteById,selectedId,fetchNotifications)
      }}/>
    </MainContainer>
  );
};

export default RecievedNotifications;
