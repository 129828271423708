import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import MainContainer from "../../layout/MainContainer";
import { isString } from "../../utils/validations";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { useAddBanerMutation, useLazyGetBannerByIdQuery, useUpdateBannerMutation } from "../../services/banner";
import { UploadMedia } from "../../utils/mediaUpload";
import useTranslation from "../../hooks/translation";

export default function AddBanner() {
  const navigate = useNavigate();
  const {id}=useParams();
  const translation=useTranslation() as any;


  //State
  const [image, setImage] = useState<string>("");


  //API Hooks
  const[addBaner]=useAddBanerMutation()
  const [getById]=useLazyGetBannerByIdQuery()
  const[updateBanner]=useUpdateBannerMutation()


  //Form handling
  const formik=useFormik({
    enableReinitialize:true,
    initialValues:{
        title:""
    },
    validationSchema:Yup.object({
        title:Yup.string().required("This field is required").min(2,"Minimum 2 characters are required").max(16,"Maximum 16 characters are allowed")
    }),
    onSubmit:async(values)=>{
        formik.setSubmitting(true)
        if(!image){
            showError("Image is required")
            return;
        }
        const body={
            title:formik.values.title,
            bannerImage:image,
        
        }
        let encryptedBody=generateEncryptedKeyBody(body) as CommonBody;

        if(id){
            try{
                const res=await updateBanner({
                    id:id,
                    body:encryptedBody
                }).unwrap()
                if(res?.statusCode===200){
                    showToast("Banner Updated")
                    navigate("/manage-banner")
                }
                console.log(res,"res")
    
            }catch(error:any){
                showError(error?.data?.message)
            }
        }else{
            try{
                const res=await addBaner({
                    body:encryptedBody
                }).unwrap()
                if(res?.statusCode===200){
                    showToast("Banner added")
                    navigate("/manage-banner")
                }
                console.log(res,"res")
    
            }catch(error:any){
                showError(error?.data?.message)
            }
        }

       
    }
  })

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if (
      file &&
      file.type &&
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    ) {
      showError("Invalid Image Type ");
      return;
    }

    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      const uploadedImages = res?.data;
      console.log(uploadedImages);
      setImage(uploadedImages);
    } else {
      showError(res?.message);
    }
      // API Calling

 
  };
  const fetchBanner=async(id:string)=>{
    try{
      const res=await getById({
      id:id
      }).unwrap()
      if(res?.statusCode===200){
        formik.setFieldValue("title",res?.data?.title)
        setImage(res?.data?.bannerImage)
       
      
      }
    }catch(error:any){
      showError(error?.data?.message)
    }
  }
  useEffect(()=>{
    if(id){
        fetchBanner(id)
    }
  },[])
  
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>{id?"Edit Banner":"Add Banner"}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-banner");
            }}
          >
            {translation.buttons.back}
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">{translation.textFields.banner}</Typography>
                  {image ? (
                    <div className="upload_image_preview2">
                      <CardMedia component="img" image={image} alt="photo" />
                      <CancelIcon
                        onClick={() => {
                          setImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}

                            onChange={handleImageUpload}
                        />
                        <Button component="span" className="upload_image_btn">
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} />

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">{translation.textFields.bannerTitle}</Typography>
                  <TextField
                  className="text_field"
                    hiddenLabel
                    type={"text"}
                    name="title"
                    variant="outlined"
                    value={formik.values.title}
                    fullWidth
                    placeholder="Title"
                    onChange={(val)=>{
                        if (val.target.value === " " || val.target.value === ".") {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.title && formik.errors.title}
                    inputProps={{
                        maxLength:20
                    }}
                  />
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  {translation.buttons.save}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
}
