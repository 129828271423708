import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { Category, CategoryResponse, CommonBody, UserData, addCategoryResponse } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type QueryParams = {
  page: number;
  size: number;
  search: string;
};

export const categoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategory: builder.query<
      CommonResponseType & { data: CategoryResponse },
      QueryParams
    >({
      query: ({ page, size, search }) => ({
        url: `${END_POINTS.type}?search=${search}&size=${size}&page=${page}`,
        method: "GET",
      }),
    }),
    getAllCategory: builder.query<
      CommonResponseType & { data: CategoryResponse },
      {}
    >({
      query: ({  }) => ({
        url: `${END_POINTS.type}`,
        method: "GET",
      }),
    }),
    deleteCategory: builder.query<
      CommonResponseType & { data: CategoryResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.type}/${id}`,
        method: "DELETE",
      }),
    }),
    addCategory:builder.mutation<
    CommonResponseType & {data:addCategoryResponse},
    {body:CommonBody}
    >({
      query:({body})=>({
        url:`${END_POINTS.type}`,
        method:"POST",
        body
      })
    }),
    getCategoryById: builder.query<
      CommonResponseType & { data: Category },
      {id:string}
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.type}/${id}`,
        method: "GET",
      })
    }),

    getAllCategoryProduct: builder.query<
    CommonResponseType & { data: CategoryResponse },
    {}
  >({
    query: ({  }) => ({
      url: `${END_POINTS.type}?isBlocked=false`,
      method: "GET",
    }),
  }),
    
    updateCategory: builder.mutation<
      CommonResponseType & { data: Category },
      {body:CommonBody,id:string}
    >({
      query: ({ id,body }) => ({
        url: `${END_POINTS.type}/${id}`,
        method: "PUT",
        body
      })
    }),

    
  }),
});

export const { 
  useLazyGetCategoryQuery,
  useLazyDeleteCategoryQuery,
  useAddCategoryMutation,
  useLazyGetCategoryByIdQuery,
  useUpdateCategoryMutation,
  useLazyGetAllCategoryQuery,
  useLazyGetAllCategoryProductQuery

 } = categoryApi;
