import React, { useState, ChangeEvent } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Box,
  Input,
  CardMedia,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
function EditLoan() {
  const navigate = useNavigate();
  const [pan, setPan] = useState<string>("");
  const [aadhar, setAadhar] = useState<string>("");

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const uploadedImage = files[0];
      setPan(URL.createObjectURL(uploadedImage));
    }
  };
  const handleAadhar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const uploadedImage = files[0];
      setAadhar(URL.createObjectURL(uploadedImage));
    }
  };
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Edit Loans</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-loans");
            }}
          >
            Back
          </Button>
        </div>
      </div>

      <Card className="cards">
        <form>
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography className="custom_label">
                  Pan Card Number
                </Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="firstName"
                  variant="outlined"
                  fullWidth
                  placeholder="Pan Number"
                ></TextField>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography className="custom_label">
                  Aadhar Card Number
                </Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="firstName"
                  variant="outlined"
                  fullWidth
                  placeholder="Aadhar Number"
                ></TextField>
              </Grid>

              <Grid item xs={12} className="heading">
                <Typography className="custom_label" variant="h5">
                  Documents
                </Typography>
              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography className="custom_label">
                  Pan Card Image{" "}
                </Typography>
                {pan ? (
                  <div className="upload_image_preview2">
                    <CardMedia component="img" image={pan} alt="photo" />
                    <CancelIcon
                      onClick={() => {
                        setPan("");
                      }}
                    />
                  </div>
                ) : (
                  <Box className="upload_image_bnr">
                    <label htmlFor="back-doc">
                      <Input
                        sx={{ display: "none" }}
                        id="back-doc"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg",
                        }}
                        // onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        //   const files = (event.target as HTMLInputElement)
                        //     .files;
                        //   if (files && files[0].type.includes("image")) {
                        //     setBackDocImage(URL.createObjectURL(files[0]));
                        //   }
                        // }}
                        onChange={(e) =>
                          handleImageUpload(e as ChangeEvent<HTMLInputElement>)
                        }
                      />
                      <Button component="span" className="upload_image_btn">
                        {/* <img
                                src={
                                  image
                                    ? image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              /> */}
                        <AddIcon />
                      </Button>
                    </label>
                  </Box>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography className="custom_label">
                  Aadhar Card Image{" "}
                </Typography>
                {aadhar ? (
                  <div className="upload_image_preview2">
                    <CardMedia component="img" image={aadhar} alt="photo" />
                    <CancelIcon
                      onClick={() => {
                        setAadhar("");
                      }}
                    />
                  </div>
                ) : (
                  <Box className="upload_image_bnr">
                    <label htmlFor="aadhar">
                      <Input
                        sx={{ display: "none" }}
                        id="aadhar"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg",
                        }}
                        // onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        //   const files = (event.target as HTMLInputElement)
                        //     .files;
                        //   if (files && files[0].type.includes("image")) {
                        //     setBackDocImage(URL.createObjectURL(files[0]));
                        //   }
                        // }}
                        onChange={(e) =>
                          handleAadhar(e as ChangeEvent<HTMLInputElement>)
                        }
                      />
                      <Button component="span" className="upload_image_btn">
                        {/* <img
                                src={
                                  image
                                    ? image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              /> */}
                        <AddIcon />
                      </Button>
                    </label>
                  </Box>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </form>
      </Card>
    </MainContainer>
  );
}

export default EditLoan;
