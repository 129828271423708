import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Tooltip from "@mui/material/Tooltip";
import PeopleIcon from "@mui/icons-material/People";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  FormControl,
  DialogContent,
  Dialog,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  ManageCustomerApi,
  useLazyGetCustomerQuery,
} from "../../services/manageCustomers";
import { showError } from "../../constants/toast";
import { CommonBody, GetCustomer } from "../../types/General";
import { isString, isValidInput } from "../../utils/validations";
import Pagination from "../../components/Pagination";
import Loader from "../../constants/Loader";
import {
  handleDelete,
  updateApprovalStatus,
  updateStatus,
} from "../../utils/commonFunctions";
import WarnModal from "../../components/WarnModal";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import useTranslation from "../../hooks/translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type props = {
  hidePermission: Permissions | null | undefined;
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Prequalified = ({ hidePermission }: props) => {
  const navigate = useNavigate();
  const userData = useAuth();
const translation=useTranslation() as any;
  const [data, setData] = useState<GetCustomer[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [open1, setOpen1] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [getUser, { isLoading }] = useLazyGetCustomerQuery();
  const [updateCustomer] = ManageCustomerApi.useUpdateCustomerMutation();
  const [deleteById] = ManageCustomerApi.useLazyDeleteCustomerQuery();
  const [exportCSV] = ManageCustomerApi.useLazyExportCSVQuery();
  const [value, setValue] = useState<string>("");
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [id, setId] = useState<string>("");
  const fetchUser = async () => {
    setLoading(true);
    try {
      const res = await getUser({
        size: 10,
        page: page,
        search: debouncedSearchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        const len = res?.data?.user || [];
        setData(res?.data?.user || []);
        setTotalCount(res?.data?.count);
        if (len.length === 0 && page > 1) {
          setPage(page - 1);
        }
        setLoading(false);
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false);
    }
  };

  const downloadCsv = async () => {
    try {
      const res = await exportCSV({
        search: debouncedSearchTerm.trim(),
      }).unwrap();
      console.log(res, "resss");
      if (res?.statusCode === 200) {
        window.open(res?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleApproval = async (e: SelectChangeEvent<string>, id: string) => {
    const { value } = e.target;
    if (value === "3") {
      setOpen1(true);
      setId(id);
    } else {
      let body = {
        approvalStatus: Number(value),
      };

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      try {
        const res = await updateCustomer({
          body: encryptedBody,
          id: id,
        }).unwrap();
        if (res?.statusCode === 200) {
          fetchUser();
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: "",
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("Reason is required"),
    }),
    onSubmit: async (values) => {
      let body = {
        reason: values.reason,
        approvalStatus: 3,
      };

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      try {
        const res = await updateCustomer({
          body: encryptedBody,
          id: id,
        }).unwrap();
        if (res?.statusCode === 200) {
          fetchUser();
          setId("");
          setOpen1(false);
        }
      } catch (error: any) {
        console.log(error);
      }
    },
  });

  const handleClose = () => {
    setOpen1(false);
    setId("");
    formik.resetForm();
  };

  useEffect(() => {
    fetchUser();
  }, [searchTerm, debouncedSearchTerm, page]);
  return (
    <div>
      <Card className="cards">
        <Loader isLoad={loading} />
        <Box className="cards_header">
          {/* <SearchBar /> */}
          <SearchBar
            searchTerm={searchTerm}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            value={searchTerm}
            onCross={() => setSearchTerm("")}
            onChange={(val: any) => {
              if (isValidInput(val.target.value)) {
                setSearchTerm(val.target.value);
              }
            }}
          />
          <Box className="cards_header_right">
            {hidePermission?.isEdit || userData?.role === 1 ? (
              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-customers/add");
                }}
              >
                {translation.buttons.addCustomer}
              </Button>
            ) : null}
            <Button
              className="btn btn_primary"
              onClick={() => {
                downloadCsv();
              }}
            >
              <FileDownloadIcon /> {translation.buttons.exportCsv}
            </Button>
          </Box>
        </Box>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{translation.tableHeads.sno}</TableCell>
                <TableCell>{translation.tableHeads.fName}</TableCell>
                <TableCell>{translation.tableHeads.phone}</TableCell>
                <TableCell>{translation.tableHeads.email}</TableCell>
                <TableCell>RIF</TableCell>
                <TableCell>{translation.tableHeads.approvalStatus}</TableCell>

                <TableCell>{translation.tableHeads.accountStatus}</TableCell>

                <TableCell>{translation.tableHeads.actions}</TableCell>
              </TableRow>
            </TableHead>

            {data?.length ? (
              data?.map((item, index) => (
                <TableBody>
                  <TableRow>
                    <TableCell>{(page - 1) * 10 + index + 1}</TableCell>
                    <TableCell>{item?.fullName || "--"}</TableCell>
                    <TableCell>
                      {(item?.countryCode || "") + (item?.phone || "")}
                    </TableCell>
                    <TableCell>{item?.email || ""}</TableCell>
                    <TableCell>{item?.RIF || ""}</TableCell>

                    <TableCell>
                      {item?.approvalStatus === 1 ? (
                        <Select
                          className="select_div "
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={value}
                          fullWidth
                          key={item?._id}
                          onChange={(e) => handleApproval(e, item?._id)}
                          MenuProps={{
                            sx: { maxHeight: "300px" },
                          }}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          <MenuItem value="2">Accept</MenuItem>
                          <MenuItem value="3">Reject</MenuItem>
                        </Select>
                      ) : item?.approvalStatus === 2 ? (
                        "Accepted"
                      ) : (
                        "Rejected"
                      )}
                    </TableCell>

                    <TableCell>
                      <Switch
                        {...label}
                        checked={!item?.isBlocked}
                        size="small"
                        onChange={() => {
                          updateStatus(
                            item?._id,
                            !item?.isBlocked,
                            updateCustomer,
                            fetchUser
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <Tooltip title="Details">
                          <IconButton
                            onClick={() =>
                              navigate("/manage-customers/details/" + item?._id)
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>

                        {hidePermission?.isEdit || userData?.role === 1 ? (
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() =>
                                navigate("/manage-customers/edit/" + item?._id)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}

                        {hidePermission?.isDelete || userData?.role === 1 ? (
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(item?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))
            ) : (
              <TableBody>
                <TableCell
                  align="center"
                  colSpan={10}
                  sx={{ color: "#051140" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <PeopleIcon sx={{ marginTop: "5px" }} /> No Customer Found
                  </div>
                </TableCell>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Card>
      <Pagination
        module={data}
        page={page}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
      <WarnModal
        name="customer"
        open={open}
        setOpen={setOpen}
        handleDelete={() => {
          handleDelete(deleteById, selectedId, fetchUser);
        }}
      />
      <Dialog open={open1} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="custom_label">Reason For Rejection</Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                className="text_field"
                hiddenLabel
                multiline
                minRows={1}
                maxRows={4}
                autoFocus
                id="rate"
                type="text"
                name="reason"
                value={formik.values.reason}
                placeholder="Reason"
                inputProps={{ maxLength: 100 }}
                fullWidth
                onBlur={formik.handleBlur}
                helperText={formik.touched.reason && formik.errors.reason}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_primary" type="submit">
                Save
              </Button>
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Prequalified;
