import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Box from "@mui/material/Box";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReviewsIcon from "@mui/icons-material/Reviews";
import SearchBar from "../../components/SearchBar";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import {
  useLazyDeleteTestimonialQuery,
  useLazyGetTestimonialsQuery,
  useUpdateTestimonialsMutation,
} from "../../services/testimonial";
import { showError } from "../../constants/toast";
import { testimonialResponse } from "../../types/General";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import Loader from "../../constants/Loader";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";

import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import useTranslation from "../../hooks/translation";

const ManageTestimonials = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const translation = useTranslation() as any;
  //States
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<testimonialResponse[]>([]);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  // API hooks
  const [getTestimonial, { isLoading }] = useLazyGetTestimonialsQuery();
  const [updateaTestimonial] = useUpdateTestimonialsMutation();
  const [deleteById] = useLazyDeleteTestimonialQuery();

  //API calls

  const fetchTestimonial = async () => {
    setLoading(true);
    try {
      const res = await getTestimonial({
        size: 10,
        page: page,
        search: debouncedSearchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        const testimonial = res?.data?.data || [];
        setData(res?.data?.data || []);
        // setImage(res?.data?.data)
        setTotalCount(res?.data?.count);
        if (testimonial?.length === 0 && page > 1) {
          setPage(page - 1);
        }
        setLoading(false);
      } else {
        setData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false);
    }
  };

  function createData(Name: string) {
    return { Name };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Hatchback"), createData("SUV")];
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const totalpages = Math.ceil(totalCount / 10);
  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Testimonials"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    fetchTestimonial();
  }, [searchTerm, debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.header.manageTestimonials}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || userData?.role === 1 ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-testimonials/add")}
                  >
                    {translation.buttons.addTestimonial}
                  </Button>
                ) : null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>{translation.tableHeads.image}</TableCell>
                    <TableCell>{translation.tableHeads.name}</TableCell>
                    <TableCell> {translation.tableHeads.title}</TableCell>

                    <TableCell>{translation.tableHeads.status}</TableCell>
                    <TableCell>{translation.tableHeads.actions}</TableCell>
                  </TableRow>
                </TableHead>

                {data?.length ? (
                  data?.map((item, index) => (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          {(page - 1) * 10 + index + 1}
                        </TableCell>
                        <TableCell align="center">
                          <figure className="bnr_img">
                            <img
                              src={item?.image}
                              alt="/static/images/user_placeholder.png"
                            />
                          </figure>
                        </TableCell>
                        <TableCell>{item?.user || ""}</TableCell>
                        <TableCell>{item?.title}</TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            checked={!item?.isBlocked}
                            size="small"
                            onChange={() => {
                              updateStatus(
                                item?._id,
                                !item?.isBlocked,
                                updateaTestimonial,
                                fetchTestimonial
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <Tooltip title="view">
                              <IconButton>
                                <VisibilityIcon
                                  onClick={() =>
                                    navigate(
                                      "/manage-testimonials/details/" +
                                        item?._id
                                    )
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                            {hidePermission?.isEdit || userData?.role === 1 ? (
                              <Tooltip title="edit">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      "/manage-testimonials/edit/" + item?._id,
                                      {
                                        state: { type: "edit" },
                                      }
                                    )
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            {hidePermission?.isDelete ||
                            userData?.role === 1 ? (
                              <Tooltip title="delete">
                                <IconButton
                                  onClick={() => {
                                    setSelectedId(item?._id);
                                    setOpen(true);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <ReviewsIcon /> No Testimonial Found
                      </div>
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      <WarnModal
        name="testimonial"
        open={open}
        setOpen={setOpen}
        handleDelete={() => {
          handleDelete(deleteById, selectedId, fetchTestimonial);
        }}
      />
      <Pagination
        module={data}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalpages}
      />
    </MainContainer>
  );
};

export default ManageTestimonials;
