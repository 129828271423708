import React from "react";
import MainContainer from "../../layout/MainContainer";
import Loader from "../../constants/Loader";
import { Box, Card, Button, TableContainer, Table, TableRow,TableHead,TableCell } from "@mui/material";

const ManageBanks=()=> {
  return (
    <MainContainer>
      <Loader />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Bank Accounts</h1>
        </div>
      </div>
      <Card className="cards">
        <Box className="custom_tabs">
          <Box className="cards_header">
            {/* <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              /> */}
            <Box className="cards_header_right">
              <Button className="btn btn_primary">Add Bank Account</Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Bank Name</TableCell>
                    {/* <TableCell>Interest Rate</TableCell> */}
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                </Table>
                </TableContainer>
        </Box>
      </Card>
    </MainContainer>
  );
}

export default ManageBanks;
