import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Tabs,
  Tab,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Switch,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PhoneInput from "react-phone-input-2";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showError, showToast } from "../../constants/toast";
import { isString } from "../../utils/validations";
import {
  useAddSubAdminMutation,
  useLazyGetSUbAdminByIdQuery,
  useUpdateSubAdminMutation,
} from "../../services/subAdmin";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, subAdmin } from "../../types/General";
import { UploadMedia } from "../../utils/mediaUpload";
import Loader from "../../constants/Loader";
import useTranslation from "../../hooks/translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface DataType {
  _id?: string;
  label: string;
  isEdit: boolean;
  isView: boolean;
  isDelete?: boolean;
  disabled?: boolean;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface Role {
  name: string;
  enabled: boolean;
}
const AddSubAdmin = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [role, setRole] = useState("");
  const [rolesData, setRolesData] = useState<Role[]>([]);
  const [newRole, setNewRole] = useState("");
  const [newData, setNewData] = useState<DataType[]>([] as any);
  const [subAdminData, setSubAdminData] = useState<subAdmin | null>(null);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [value, setValue] = useState<number>(0);
  const [loading,setLoading]=useState(false)
  const translation=useTranslation() as any;
  const [permissions, setPermissions] = useState<DataType[]>([
    {
      _id: "1",
      label: "Manage Customers" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },

    {
      _id: "2",
      label: "Manage Categories" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "3",
      label: "Manage Tags" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "4",
      label: "Manage Cars" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "5",
      label: "Manage Loans" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "6",
      label: "Manage Interest" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "7",
      label: "Chats" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "8",
      label: "Manage Forms" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "9",
      label: "Manage Loan Term" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "10",
      label: "Manage Service Station" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "11",
      label: "Customer Support" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "12",
      label: "Manage Testimonials" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "13",
      label: "Manage Banner" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "14",
      label: "Manage Notifications" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "15",
      label: "Report and Analytics" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "16",
      label: "Manage CMS" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "17",
      label: "Manage FAQS" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "18",
      label: "Settings" || "",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    // {
    //   _id: "19",
    //   label: "Credit Score" || "",
    //   isEdit: false,
    //   isView: false,
    //   isDelete: false,
    //   disabled: true,
    // },
  ]);

  const [addSubAdmin] = useAddSubAdminMutation();
  const [updateSubAdmin] = useUpdateSubAdminMutation();
  const [getAdminById] = useLazyGetSUbAdminByIdQuery();

  const handleRoleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if (
      file &&
      file.type &&
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    ) {
      showError("Invalid Image Type ");
      return;
    }

    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      const uploadedImages = res?.data;
      console.log(uploadedImages);
      setImage(uploadedImages);
    } else {
      showError(res?.message);
    }
    // API Calling
  };
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };
  const onSelectChange = (key: any, type: string) => {
    let idx = permissions.findIndex((obj) => obj.label === key.label);
    if (type === "addEdit") {
      permissions[idx].isEdit = !key.isEdit;
      if (key.isEdit) {
        permissions[idx].isView = true;
      } else {
        if (key.isDelete) {
          permissions[idx].isView = true;
        } else {
          permissions[idx].isView = true;
        }
        // permissions[idx].isView = false;
      }
    } else if (type === "isview") {
      permissions[idx].isView = !key.isView;
      if (key.isEdit) {
        permissions[idx].isEdit = !key.isEdit;
      }
      if (key.isDelete) {
        permissions[idx].isDelete = !key.isDelete;
      }
    } else {
      permissions[idx].isDelete = !key.isDelete;
      if (key.isDelete) {
        permissions[idx].isView = true;
      } else {
        if (key.isEdit) {
          permissions[idx].isView = true;
        } else {
          permissions[idx].isView = true;
        }
      }
    }
    setPermissions([...permissions]);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: subAdminData?.firstName || "",
      lastName: subAdminData?.lastName || "",
      fullName: subAdminData?.fullName || "",

      email: subAdminData?.email || "",
      phone: subAdminData?.phone || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      firstName: Yup.string()
        .required("First name is required")
        .min(3, "Minimum 3 characters are required")
        .max(30, "Maximum 30 character are allowed"),
      lastName: Yup.string()
        .required("Last name is required")
        .min(3, "Minimum 3 characters are required")
        .max(30, "Maximum 30 character are allowed"),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters")
        .matches(/^(?!000000000)\d{10}$/, "Enter a valid phone number"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      console.log("hello");
      // if(!image){
      //   showError("Image is required")
      //   return;
      // }
      permissions?.length > 0 &&
        permissions.map((element) => {
          const obj = {
            sideBarId: element?._id,
            label: element?.label,
            isEdit: element.isEdit,
            isView: element.isView,
            isDelete: element.isDelete,
          };
          if (obj) {
            newData?.push(obj);
            setNewData([...newData]);
          }
        });

      const selectedModules = newData.filter((element) => {
        return (
          element.isView === true ||
          element.isEdit === true ||
          element.isDelete === true
        );
      });
      if (selectedModules?.length === 0) {
        showError("Please allow permission for atleast one module ");
        return;
      }

      let body = {
        ...(values.email !== subAdminData?.email
          ? { email: values.email }
          : {}),
        ...(values.phone !== subAdminData?.phone ||
        phoneCode !== subAdminData?.countryCode
          ? { phone: values.phone }
          : {}),
        ...(values.phone !== subAdminData?.phone ||
        phoneCode !== subAdminData?.countryCode
          ? { countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode }
          : {}),

        // email: formik.values.email,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,

        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: formik.values.phone,
        image: image,
        permission: newData.filter((element) => {
          return (
            element.isView === true ||
            element.isEdit === true ||
            element.isDelete === true
          );
        }),
      };
      console.log(body, "body");
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      if (id) {
        try {
          const response = await updateSubAdmin({
            id: id,
            body: encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Subadmin Updated Sucessfully");
            navigate("/manage-subAdmin");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addSubAdmin({
            body: encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Sub-admin Created Sucessfully");
            navigate("/manage-subAdmin");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const fetchSubAdmin = async (id: string) => {
    setLoading(true)
    try {
      const res = await getAdminById({
        id: id,
      }).unwrap();
      if (res?.statusCode === 200) {
        setSubAdminData(res?.data);
        setPhoneCode(res?.data?.countryCode || "+91");
        setImage(res?.data?.image || "");
        const modifiedArr2 = permissions.map((item) => {
          const foundItem = res?.data?.permission?.find(
            (i: { label: string }) => i?.label === item.label
          );
          if (foundItem) {
            return {
              ...item,
              isView: foundItem.isView,
              isEdit: foundItem.isEdit,
              isDelete: foundItem.isDelete,
            };
          }
          return item;
        });
        setPermissions(modifiedArr2);

        // setData(res?.data?.subAdmin || []);
        setLoading(false)
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false)
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    if (id) {
      fetchSubAdmin(id);
    }
  }, []);

  return (
    <>
      <MainContainer>
        <Loader isLoad={loading}/>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">
              {id ? "Edit Sub-Admin" : "Add Sub-Admin"}
            </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subAdmin");
              }}
            >
              {translation.buttons.back}
            </Button>
          </div>
          <Card className="cards">
            <Box className="custom_tabs">
              <Box className="flx_sc">
                {/* <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  className="custom_tabs_links"
                >
                  {/* <Tab label="Add Role" {...a11yProps(0)} /> */}
                {/* <Tab label="Add Sub-admin" {...a11yProps(0)} /> */}
                {/* </Tabs>  */}
              </Box>

              <CustomTabPanel value={value} index={0}>
                <form onSubmit={formik.handleSubmit}>
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography className="custom_label">   {translation.tableHeads.image}</Typography>
                        {image ? (
                          <div className="upload_image_preview">
                            <CardMedia
                              component="img"
                              image={image}
                              alt="photo"
                            />
                            <CancelIcon
                              onClick={() => {
                                setImage("");
                                setFileName("");
                              }}
                            />
                          </div>
                        ) : (
                          <Box className="upload_image">
                            <label htmlFor="icon-button-file">
                              <Input
                                sx={{ display: "none" }}
                                id="icon-button-file"
                                type="file"
                                inputProps={{
                                  accept: "image/png,image/jpeg",
                                }}
                                onChange={handleImageUpload}
                              />
                              <Button
                                component="span"
                                className="upload_image_btn"
                              >
                                <img
                                  src="/static/images/user_placeholder.png"
                                  alt=""
                                />
                                <CameraAltIcon />
                              </Button>
                            </label>
                          </Box>
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                        {translation.textFields.firstName}
                        </Typography>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type={"text"}
                          name="firstName"
                          variant="outlined"
                          value={formik.values.firstName}
                          fullWidth
                          placeholder= {translation.textFields.firstName}
                          onBlur={formik.handleBlur}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isString(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          helperText={
                            formik.touched.firstName && formik.errors.firstName
                          }
                          // helperText={
                          //   formik.touched.fullName && formik.errors.fullName
                          // }
                          inputProps={{
                            maxLength: 30,
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                        {translation.textFields.lName}
                        </Typography>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type={"text"}
                          name="lastName"
                          variant="outlined"
                          value={formik.values.lastName}
                          fullWidth
                          placeholder={translation.textFields.lName}
                          onBlur={formik.handleBlur}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isString(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          helperText={
                            formik.touched.lastName && formik.errors.lastName
                          }
                          // helperText={
                          //   formik.touched.fullName && formik.errors.fullName
                          // }
                          inputProps={{
                            maxLength: 30,
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">  {translation.textFields.email}</Typography>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type={"email"}
                          name="email"
                          value={formik.values.email}
                          variant="outlined"
                          onChange={formik.handleChange}
                          fullWidth
                          placeholder={translation.textFields.email}
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                        {translation.textFields.phone}
                        </Typography>
                        <PhoneInput
                          country={"us"}
                          placeholder="0 (000) 000-000"
                          enableSearch={true}
                          value={phoneCode + formik.values.phone}
                          onChange={(phone, country) =>
                            handleChangePhone(phone, country)
                          }
                          inputStyle={{ width: "100%" }}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <h6 className="err_msg">
                            {/* {formik.touched.phone && formik.errors.phone} */}
                            {formik.touched.phone && formik.errors.phone}
                          </h6>
                        ) : (
                          ""
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <TableContainer className="table_container">
                          <Box className="heading"></Box>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell className="mn_hdng">
                                  Module
                                </TableCell>
                                <TableCell className="mn_hdng">
                                  Add/Edit
                                </TableCell>
                                <TableCell className="mn_hdng">View</TableCell>
                                <TableCell className="mn_hdng">
                                  Delete
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {permissions.map((row, i) => (
                                <TableRow>
                                  <TableCell className="mn_hdng">
                                    {row.label}
                                  </TableCell>
                                  <TableCell align="center">
                                    <Checkbox
                                      {...label}
                                      disabled={
                                        row?.label === "Chats" ||
                                        row?.label === "Report and Analytics"
                                      }
                                      onChange={() =>
                                        onSelectChange(row, "addEdit")
                                      }
                                      checked={
                                        row?.label === "Chats" ||
                                        row?.label === "Report and Analytics"
                                          ? true
                                          : row?.isEdit || false
                                      }
                                      sx={{
                                        "&.Mui-disabled": {
                                          opacity: "0.5",
                                        },
                                        "&.Mui-disabled .MuiSvgIcon-root": {
                                          color: "gray",
                                        },
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Checkbox
                                      {...label}
                                      onChange={() =>
                                        onSelectChange(row, "isview")
                                      }
                                      checked={row?.isView || false}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <Checkbox
                                      {...label}
                                      disabled={
                                        row?.label === "Chats" ||
                                        row?.label === "Report and Analytics"
                                      }
                                      onChange={() =>
                                        onSelectChange(row, "isdel")
                                      }
                                      // checked={row?.isDelete || false}
                                      checked={
                                        row?.label === "Chats" ||
                                        row?.label === "Report and Analytics"
                                          ? true
                                          : row?.isDelete || false
                                      }
                                      sx={{
                                        "&.Mui-disabled": {
                                          opacity: "0.5",
                                        },
                                        "&.Mui-disabled .MuiSvgIcon-root": {
                                          color: "gray",
                                        },
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                      >
                        {translation.buttons.save}
                      </Button>
                    </div>
                  </CardContent>
                </form>
              </CustomTabPanel>
            </Box>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddSubAdmin;
