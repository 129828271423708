import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tabs,
  Tab,
  Switch,
  Select,
  Menu,
  MenuItem,
  SelectChangeEvent
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ChatIcon from "@mui/icons-material/Chat";
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ManageAdvertisements from "../../pages/manageLoans";
import { showError } from "../../constants/toast";
import { LoanAPi } from "../../services/loans";
import { Loan } from "../../types/General";
import Pending from "../manageAdvertisements/Pending";
import Accepted from "../manageAdvertisements/Accepted";
import Rejected from "../manageAdvertisements/Rejected";
import Completed from "../manageAdvertisements/Completed";
import { Permissions } from "../../types/User";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LoanHistory = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const {id}=useParams()
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const [data, setData] = useState<Loan[]>([]);

  //API Hooks
  const [getLoans, { isLoading }] = LoanAPi.useLazyGetLoanByUserQuery();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  //API calls

  const fetchLoan = async () => {
    try {
      const res = await getLoans({
        page: page,
        size: 10,
        search: debouncedSearchTerm.trim(),
        type:
          value === 0
            ? 1
            : value === 1
              ? 2
              : value === 2
                ? 3
                : value === 3
                  ? 4
                  : 1,
                  userId:id
      }).unwrap();
      if (res?.statusCode === 200) {
        const len=res?.data?.data || []
        setData(res?.data?.data || []);
        setTotalCount(res?.data?.count);
        if(len?.length ===0 && page>1){
          setPage(page-1)
        }
      } else {
        setData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  useEffect(() => {
    fetchLoan();
  }, [page, value, searchTerm, debouncedSearchTerm]);

  
  
  
  return (

    
    <Card sx={{ mt: 4 }} className="cards">
      <Box className="custom_tabs">
        {/* <h2 className="mn_hdng">Loan History</h2> */}

        <Box className="flx_sc">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            className="custom_tabs_links"
          >
            <Tab label="Pending" {...a11yProps(0)} />
            <Tab label="Approved" {...a11yProps(1)} />
            <Tab label="Un-approved" {...a11yProps(2)} />
            <Tab label="Completed" {...a11yProps(3)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
              <Pending
                hidePermission={hidePermission}
                value={value}
                setValue={setValue}
                setData={setData}
                data={data}
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
                fetchLoan={fetchLoan}
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                setSearchTerm={setSearchTerm}
                debouncedSearchTerm={debouncedSearchTerm}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Accepted
                hidePermission={hidePermission}
                value={value}
                setValue={setValue}
                setData={setData}
                data={data}
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
                fetchLoan={fetchLoan}
                searchTerm={searchTerm}
                debouncedSearchTerm={debouncedSearchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                setSearchTerm={setSearchTerm}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Rejected
                hidePermission={hidePermission}
                value={value}
                setValue={setValue}
                setData={setData}
                data={data}
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
                fetchLoan={fetchLoan}
                searchTerm={searchTerm}
                debouncedSearchTerm={debouncedSearchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                setSearchTerm={setSearchTerm}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Completed
                hidePermission={hidePermission}
                value={value}
                setValue={setValue}
                setData={setData}
                data={data}
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
                fetchLoan={fetchLoan}
                searchTerm={searchTerm}
                debouncedSearchTerm={debouncedSearchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                setSearchTerm={setSearchTerm}
              />
            </CustomTabPanel>
      </Box>
    </Card>
  );
};

export default LoanHistory;
