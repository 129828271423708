import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetTestimonialsByIdQuery } from "../../services/testimonial";
import { testimonialResponse } from "../../types/General";
import { showError } from "../../constants/toast";
import Loader from "../../constants/Loader";

const TestimonialDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  //States
  const [data, setData] = useState<testimonialResponse>();
  const [image, setImage] = useState<string>("");

  //API Hooks
  const [getTestimonial, { isLoading }] = useLazyGetTestimonialsByIdQuery();

  //API calls
  const fetchTestimonial = async (id: string) => {
    try {
      const res = await getTestimonial({
        id: id,
      }).unwrap();
      if (res?.statusCode === 200) {
        setData(res?.data || {});

        setImage(res?.data?.image || "");
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  useEffect(() => {
    if (id) {
      fetchTestimonial(id);
    }
  }, []);
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Testimonial Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-testimonials");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item lg={2} md={2} sm={6} xs={12}>
                <figure className="profile_img">
                  <img src={image} alt="/static/images/user_placeholder.png" />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5"> Name</Typography>
                      <Typography component="p">{data?.user}</Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Title</Typography>
                      <Typography component="p">{data?.title}</Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={8} md={8} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Description</Typography>
                      <Typography component="p">{data?.description}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default TestimonialDetails;
