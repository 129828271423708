import React, { useEffect, useRef, useState } from "react";

import PhoneInput from "react-phone-input-2";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import { Document, Page, pdfjs } from "react-pdf";
import { Message, SenderId } from "../types/General";
import { socket } from "../utils/socket";
import AddIcon from "@mui/icons-material/Add";
import { UploadMedia, Uploadpdf } from "../utils/mediaUpload";
import { showError } from "../constants/toast";
type chatData = {
  data: Message[];
  loading: boolean;
  setData: any;
  text: string;
  setText: any;
  sendMessage: () => void;
  headerName: string;
  headerImage: string;
  fileType: string;
  setFileType: any;
  setDoc: any;
  file: string;
  setFile: any;
  doc: string;
};
type chatDataDetails = {
  data: Message;
};
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const ChatDetail = ({
  data,
  loading,
  setData,
  text,
  setText,
  sendMessage,
  headerName,
  headerImage,
  fileType,
  setFileType,
  setDoc,
  doc,
}: chatData) => {
  const options = ["Share Profile", "Report"];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [file, setFile] = useState<string>("");
  const [load, setLoad] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const chatContainerRef = useRef<any>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;
  console.log(data, "data chat");

  const messagesByDate: { [date: string]: Message[] } = {};
  data?.forEach((message) => {
    const date = new Date(message.createdAt).toLocaleDateString();
    if (!messagesByDate[date]) {
      messagesByDate[date] = [];
    }
    messagesByDate[date].push(message);
  });

  console.log(Object.entries(messagesByDate), "message bY Data");

  const RenderSkeleton = () => {
    return (
      <div className="chats_lst">
        <div className="time">
          <Skeleton animation="wave" width={100} />
        </div>

        {[...Array(10)].map((_, index) => (
          <div key={index}>
            <div
              key={index}
              className={index % 2 === 0 ? "out-going" : "incoming"}
            >
              <Skeleton animation="wave" height={"100%"} width={"100%"} />
            </div>
          </div>
        ))}
      </div>
    );
  };
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const handleFileSelect = async (event: any) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    if (
      file &&
      file.type &&
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    ) {
      showError("Invalid Image Type ");
      event.target.files = null;
      return;
    }
    setDoc(URL.createObjectURL(file));
    setLoad((prev) => !prev);

    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      const uploadedImages = res?.data;
      console.log(uploadedImages);
      setFile(uploadedImages);
      setDoc(uploadedImages);
      setLoad((prev) => !prev);
      // sendMessage();
    } else {
      // showError(res?.message);
      setLoad((prev) => !prev);
    }

    handleClose();
  };

  const handlePdfUpload = async (event: any) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    if(file.type !== "application/pdf"){
      showError("Invalid document type")
      return;
    }
    const res = await Uploadpdf(file);
    if (res?.statusCode === 200) {
      const uploadedImages = res?.data;
      console.log(uploadedImages);

      setDoc(uploadedImages);

      sendMessage();
    } else {
      // showError(res?.message);
    }

    handleClose();
  };
  console.log(doc, "docffffff");
  const Options = () => {
    return (
      <div>
        <IconButton onClick={(e) => handleClick(e)}>
          <AddIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
          anchorOrigin={{
            vertical: "bottom", // Set anchor origin to bottom
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleClose}
        >
          <label htmlFor="image-upload" style={{ cursor: "pointer" }}>
            <MenuItem style={{ fontSize: "16px" }}>
              Image
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => {
                  handleFileSelect(e);
                  setFileType("IMAGE");
                }}
              />
            </MenuItem>
          </label>
          <label htmlFor="pdf-upload" style={{ cursor: "pointer" }}>
            <MenuItem style={{ fontSize: "16px" }}>
              <input
                id="pdf-upload"
                type="file"
                accept="*"
                style={{ display: "none" }}
                onChange={(e) => {
                  handlePdfUpload(e);
                  setFileType("DOC");
                }}
              />
              Documents
            </MenuItem>
          </label>
        </Menu>
      </div>
    );
  };

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  return (
    <div className="main">
      <div className="heading">
        <div className="userDetail">
          <figure>
            <img
              src={headerImage}
              alt=""
              style={{
                borderRadius: "50px",
              }}
            />
          </figure>
          <div className="username">
            <h3>{headerName}</h3>
          </div>
        </div>
      </div>

      <div className="chat-div">
        {loading ? (
          <RenderSkeleton />
        ) : (
          <div className="chats_lst" ref={chatContainerRef}>
            {Object?.entries(messagesByDate)?.map(([date, messages]) => (
              <>
                <div className="time">{date}</div>
                {messages?.length &&
                  messages?.map((item, index) => {
                    const isSameSender =
                      index > 0 &&
                      messages[index - 1]?.senderId?._id ===
                        item?.senderId?._id;

                    return (
                      <>
                        {item?.type === "TEXT" ? (
                          <div
                            className={
                              item?.senderType === "admin"
                                ? "out-going"
                                : "incoming"
                            }
                          >
                            <p>{item?.text || ""}</p>
                          </div>
                        ) : item?.type === "IMAGE" || fileType === "IMAGE" ? (
                          <LoadImage data={item} />
                        ) : item?.type === "DOC" || fileType === "DOC" ? (
                          <>
                            <LoadPdf data={item} />

                            {item?.text ? (
                              <div
                                className={
                                  item?.senderType === "admin"
                                    ? "out-going"
                                    : "incoming"
                                }
                              >
                                <p>{item?.text || ""}</p>
                              </div>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    );
                  })}
              </>
            ))}
          </div>
        )}

        <div className="chat_foot">
          <div className="preview-wrapper">
            {doc && (
              <div className="file-preview">
                <figure className={`doc_img_chat ${load ? "loading" : ""}`}>
                  {doc?.includes(".pdf") ? (
                    <img
                      src="/static/images/pdf.png"
                      onClick={() => LoadsPdf(doc)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <img src={doc} alt="Preview" />
                  )}

                  <IconButton onClick={() => setDoc("")}>
                    <CancelIcon />
                  </IconButton>
                </figure>
              </div>
            )}
          </div>
          <TextField
            hiddenLabel
            placeholder="Type a message"
            fullWidth
            className="text_field"
            name="text"
            type="text"
            variant="outlined"
            multiline
            minRows={1}
            maxRows={4}
            inputProps={{
              maxLength:500
            }}
            value={text}
            onChange={(val) => {
              setText(val.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                sendMessage();
              }
            }}
          />
          {doc ? null : (
            <div>
              <IconButton onClick={(e) => handleClick(e)}>
                <AddIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "10vh",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom", // Set anchor origin to bottom
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                onClose={handleClose}
              >
                <label htmlFor="image-upload" style={{ cursor: "pointer" }}>
                  <MenuItem
                       sx={{
                        fontSize: "16px",
                        justifyContent: "center",
                        backgroundColor: "initial",
                        ":hover": {
                          backgroundColor: "#a0dfdf",
                        },
                      }}
                  >
                    <ImageIcon />
                    <input
                      id="image-upload"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileSelect(e);
                        setFileType("IMAGE");
                      }}
                    />
                  </MenuItem>
                </label>
                <label htmlFor="pdf-upload" style={{ cursor: "pointer" }}>
                  <MenuItem
                    sx={{
                      fontSize: "16px",
                      justifyContent: "center",
                      backgroundColor: "initial",
                      ":hover": {
                        backgroundColor: "#a0dfdf",
                      },
                    }}
                  >
                    <input
                      id="pdf-upload"
                      type="file"
                      accept="*"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handlePdfUpload(e);
                        setFileType("DOC");
                      }}
                    />
                    <PictureAsPdfIcon />
                  </MenuItem>
                </label>
              </Menu>
            </div>
          )}
          <IconButton onClick={() => sendMessage()}>
            <SendIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
const LoadImage = ({ data }: chatDataDetails) => {
  return (
    <div
      key={data._id}
      className={
        data?.senderType === "admin" ? "out-goingDocs" : "incomingDocs"
      }
    >
      <figure style={{ width: "100%" }}>
        <img src={data?.media} width={"100%"} height={"170px"} alt="" />
        {data?.text ? <p>{data?.text || ""}</p> : null}
      </figure>
    </div>
  );
};

const LoadPdf = ({ data }: chatDataDetails) => {
  return (
    <div
      key={data?._id}
      className={
        data?.senderType === "admin" ? "out-goingDocs" : "incomingDocs"
      }
    >
      <figure className="doc_pdf" style={{ width: "200px", height: "170px" }}>
        {data?.media.includes(".pdf") ? (
          <div style={{ width: "20px", height: "17px" }}>
            <a href={data?.media} target="_blank" rel="noopener noreferrer">
              <Document
                // style={{ width: '20px', height: '17px' }}
                file={{
                  url: data?.media,
                }}
              >
                <Page pageNumber={1} />
              </Document>
            </a>
          </div>
        ) : (
          <figure>
            <img src={data?.media} width={"1%"} height={"17px"} alt="" />
          </figure>
        )}
      </figure>
    </div>
  );
};
const LoadsPdf = (pdf: any) => {
  console.log("hellooo pdf");
  console.log(pdf, "pdffffffffffffff");
  return (
    <a href={pdf} target="_blank" rel="noopener noreferrer">
      {/* <Document file={{
                url: pdf,
              }}>
        <Page pageNumber={1} />
      </Document> */}
    </a>
  );
};

export default ChatDetail;
