import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, FetchFromResponse, FormResponse } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
    statusCode: number;
    message: string;
};



export const manageForms = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
    
        addForms:builder.mutation<
        CommonResponseType & {data:FormResponse},
        {body:CommonBody}>({
            query:({body})=>({
                url:`${END_POINTS.form}`,
                method:"POST",
                body
            })
        }),
        updateForms:builder.mutation<
        CommonResponseType & {data:FormResponse},
        {body:CommonBody,id:string}>({
            query:({body,id})=>({
                url:`${END_POINTS.form}/${id}`,
                method:"PUT",
                body
            })
        }),
        getForms:builder.query<
        CommonResponseType & {data:FetchFromResponse},
        {page:number,size:number}>({
            query:({page,size})=>({
                url:`${END_POINTS.form}?page=${page}&size=${size}`,
                method:"GET",
                
            })
        }),
        deleteForms:builder.query<
        CommonResponseType & {data:FetchFromResponse},
        {id:string}>({
            query:({id})=>({
                url:`${END_POINTS.form}/${id}`,
                method:"DELETE",
                
            })
        }),
        
      

       

       

      


        



        

    })
})

export const {
    useLazyDeleteFormsQuery,
    useUpdateFormsMutation,
    useAddFormsMutation,
    useLazyGetFormsQuery
}=manageForms

