import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { Rating } from "react-simple-star-rating";
import { useNavigate } from "react-router-dom";

const ManageReviews = () => {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Reviews</h1>
        </div>
        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Booking Id</TableCell>
                    <TableCell align="center">Service Provider Name</TableCell>
                    <TableCell align="center">Rating By</TableCell>
                    <TableCell>Ratings</TableCell>
                    <TableCell>Reviews</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{1}</TableCell>
                    <TableCell align="center">1178</TableCell>
                    <TableCell align="center">John</TableCell>
                    <TableCell>Abdul Hussain</TableCell>
                    <TableCell>
                      {" "}
                      <Rating
                        initialValue={4}
                        // onClick={(newRating: number) =>
                        //   setratingCount(newRating)
                        // }
                        iconsCount={5}
                        size={23}
                        allowHover={false}
                        readonly
                        // transition={true}
                      />
                    </TableCell>
                    <Tooltip
                      title="Lorem ipsum dolor sit amet consectetur, adipisicing elit
                        dolor sit amet consectetur. Lorem ipsum dolor sit amet
                        consectetur, adipisicing elit dolor sit amet"
                    >
                      <TableCell className="td">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit
                        dolor sit amet consectetur. Lorem ipsum dolor sit amet
                        consectetur, adipisicing elit dolor sit amet
                        consectetur.
                      </TableCell>
                    </Tooltip>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => navigate("/manage-reviews/details")}
                        >
                          <VisibilityIcon />
                        </IconButton>

                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageReviews;
