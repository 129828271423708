import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { addCustomerResponse, ScoreEntry } from "../../types/General";
import { ManageCustomerApi } from "../../services/manageCustomers";
import { showError } from "../../constants/toast";
import { useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const Challenges = () => {
  const { id } = useParams();

  const [data, setData] = useState<ScoreEntry[]>([]);
  const [getById, { isLoading }] =
    ManageCustomerApi.useLazyGetCustomerByIdQuery();
  const fetchUser = async (id: string) => {
    try {
      const res = await getById({
        id: id,
      }).unwrap();
      if (res?.statusCode === 200) {
        setData(res?.data?.criteriaArray || []);
      }
    } catch (error: any) {
      showError(error?.data?.manage);
    }
  };

  useEffect(() => {
    if (id) {
      fetchUser(id);
    }
  }, []);
  return (
    <Card sx={{ mt: 4 }} className="cards">
      <div>
        {data?.length
          ? data?.map((item, index) => (
              <Accordion defaultExpanded={index === 0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  
                  <Typography component="p" sx={{
                    fontWeight:"600"
                  }}>Q . {item?.criteriaName || ""}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {item?.range || ""}
                  </AccordionDetails>
              </Accordion>
            ))
          : null}
      </div>
    </Card>
  );
};

export default Challenges;
