import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, UserData } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

type UserResponse ={
    data: {
        data: User;
        statusCode: number;
        message:string
    };
}



export const AdminApi= emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({

        getProfile:builder.query<
        CommonResponseType & {data:User}, //getting error in response while giving data the type User
        {}
        >({
            query:({})=>({
                url:`${END_POINTS.getProfile}`
            })
        }),

        updateProfile:builder.mutation<
         CommonResponseType & { data: User} ,
        {body:CommonBody}
        >({
            query:({body})=>({
                url:`${END_POINTS.updateProfile}`,
                method:"PUT",
                body
            })
        })


        
      

       

       

      


        



        

    })
})

export const {
useLazyGetProfileQuery,
useUpdateProfileMutation

    
}=AdminApi

