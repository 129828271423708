




// import {
//   createApi,
//   fetchBaseQuery,
//   BaseQueryFn,
//   FetchArgs,
//   FetchBaseQueryError,
// } from "@reduxjs/toolkit/query/react";
// import type { RootState } from "../app/store";
// import { API_URL } from "../constants/url";
// import { resetAuth } from "../reducers/authSlice";
// import { generateEncryptedKey, generateEncryptedKeyBody } from "./crypto";

// const baseQuery = fetchBaseQuery({
//   baseUrl: API_URL,

//   // prepareHeaders: (headers, { getState }) => {
//   //   const { token } = (getState() as RootState).auth;

//   //   if (token) {
//   //     headers.set("authorization", `Bearer ${token}`);
//   //   }
//   //   return headers;
//   // },
//   prepareHeaders: (headers, { getState }) => {
//     const { token } = (getState() as RootState).auth;
//     const encryptedData = generateEncryptedKey();
//     const { tempToken } = (getState() as RootState).auth;

//     if (token) {
//       headers.set("authorization", `Bearer ${token}`);
//     } else if (tempToken) {
//       headers.set("authorization", `Bearer ${tempToken}`);
//     }
//     headers.set("devicetype", `web`);
//     // headers.set("Sek", ` ${encryptedData?.sek}`);
//     return headers;
//   },
// });

// const baseQueryWithAuth: BaseQueryFn<
//   string | FetchArgs | any,
//   unknown,
//   FetchBaseQueryError
// > = async (args, api, extraOptions) => {
//   if (args.body) {
//     console.log(args.body, "args.body");

//     const encryptedData = generateEncryptedKeyBody(args.body);
//     console.log(encryptedData, "encryptedData");

//     if (encryptedData) {
//       args.body = encryptedData;
//     } else {
//       return {
//         error: {
//           status: 400,
//           data: { detail: "Failed to encrypt request body" },
//         },
//       };
//     }
//   }
//   const result = await baseQuery(args, api, extraOptions);
//   if (result.error?.status === 400) {
//     const errors = Object.values(result?.error?.data || {});
//     console.log({ errors });
//     if (errors?.length > 1 && errors[1] === 400) {
//       return result;
//     }

//     if (errors?.length) {
//       const error = errors[0] as any;
//       if (error?.length) {
//         console.log(error[0]);
//       }
//     }
//   }
//   if (result.error?.status === 401) {
//     api.dispatch(resetAuth());
//     if (window) {
//       window.location.replace("/");
//     }
//   }
//   if (
//     result.error &&
//     result.error.status === 401 &&
//     (api.getState() as RootState).auth.user
//   ) {
//     api.dispatch(resetAuth());
//   }

//   return result;
// };

// const emptySplitApi = createApi({
//   baseQuery: baseQueryWithAuth,
//   tagTypes: ["UNAUTHORIZED", "UNKNOWN_ERROR"],
//   endpoints: () => ({}),
// });

// export default emptySplitApi;

import {
    createApi,
    fetchBaseQuery,
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
  } from "@reduxjs/toolkit/query/react";
  import type { RootState } from "../app/store";
  
  import { generateSEKAndHash } from "./crypto";
  
  import { resetAuth } from "../reducers/authSlice";
  import { getFromStorage } from "../constants/storage";
  import { STORAGE_KEYS } from "../constants/storageKeys";
  import { API_URL } from "../constants/url";
import { showToast } from "../constants/toast";
  
  const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
      const language = getFromStorage(STORAGE_KEYS.language);
      const { token } = (getState() as RootState).auth;
      const { tempToken } = (getState() as RootState).auth;
      
      
      const encryptData = generateSEKAndHash(token || tempToken||null);

      
  
      if (encryptData) {
        headers.set("hash", encryptData?.hash);
        headers.set("sek", encryptData?.sek);
      }
      headers.set("deviceType", "web");
      headers.set("lang", language === "ar" ? "ar" : "en");
  
      // if (token) {
      //   headers.set("authorization", `Bearer ${token}`);
      // } else if (tempToken) {
      //   headers.set("authorization", `Bearer ${tempToken}`);
      // }
  
      return headers;
    },
  });
  
  const baseQueryWithAuth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === 400) {
      const errors = Object.values(result?.error?.data || {});
      console.log({ errors });
      if (errors?.length > 1 && errors[1] === 400) {
        return result;
      }
  
      if (errors?.length) {
        const error = errors[0] as any;
        if (error?.length) {
          console.log(error[0]);
        }
      }
    }
  
    if (result.error?.status === 401) {
      api.dispatch(resetAuth());
      if (window) {

        showToast("Session expired , please login again")
        window.location.replace("/");

      }
    }
    // if (result.error?.status === "FETCH_ERROR") {
    //   if (window) {
    //     window.location.replace("/server-maintenance");
    //   }
    // }
  
    // if (result?.error?.status === 429) {
    //   toast.dismiss();
    //   toast.error(result?.error?.status || "");
    // }
  
    // if (result.error?.status === 401) {
    // 	ShowToast(result?.error?.data?.detail || 'Something went wrong!');
    // 	// navigate('SignInAndSignUp', undefined);
    // }
    // if (result.error?.error && result?.error?.originalStatus === 502) {
    // 	ShowToast('Server error!');
    // } else if (result.error?.error) {
    // 	ShowToast(result?.error?.error?.replace('TypeError: ', '') || 'Something went wrong!');
    // }
    // if (
    //   result.error &&
    //   result.error.status === 401 &&
    //   (api.getState() as RootState).auth.user
    // ) {
    //   api.dispatch(resetAuth());
    // }
  
    return result;
  };
  
  const emptySplitApi = createApi({
    baseQuery: baseQueryWithAuth,
    tagTypes: ["UNAUTHORIZED", "UNKNOWN_ERROR"],
    endpoints: () => ({}),
  });
  
  export default emptySplitApi;