import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import {
  CommonBody,
  DashBoardData,
  Graphs,
  InterestRate,
  InterestRateResponse,
  UserData,
  userGraph,
} from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type QueryParams = {
  page: number;
  size: number;
  search: string;
};

export const DashboardApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashBoard:builder.query<
    CommonResponseType & {data:DashBoardData},
    {}>({
        query:({})=>({
            url:`${END_POINTS.dashboard}`,
            method:"GET"
        })
    }),

    getUserGraphs:builder.query<
    CommonResponseType & {data:userGraph},
    {type:string}>({
        query:({type})=>({
            url:`${END_POINTS.userGraph}?type=${type}`,
            method:"GET"
        })
    }),

    getloanGraphs:builder.query<
    CommonResponseType & {data:any},
    {type:string}>({
        query:({type})=>({
            url:`${END_POINTS.loanRequestGraph}?type=${type}`,
            method:"GET"
        })
    }),
    getAllGraphs:builder.query<
    CommonResponseType & {data:Graphs},
    {}>({
        query:()=>({
            url:`${END_POINTS.allExports}`,
            method:"GET"
        })
    }),

  }),
});

export const {
useLazyGetDashBoardQuery,
useLazyGetUserGraphsQuery,
useLazyGetloanGraphsQuery,
useLazyGetAllGraphsQuery
} = DashboardApi;
