import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { AddBanerRes, BannerResponse, CommonBody, FAQ, FAQResponse } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
    statusCode: number;
    message: string;
};
type QueryParams = {
    page: number;
    size: number;
    search: string;
  };


export const FaqApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({

      addFaq:builder.mutation<
      CommonResponseType & {data:FAQ},
      {body:CommonBody}>({
        query:({body})=>({
            url:`${END_POINTS.faq}`,
            method:"POST",
            body
        })
      }),
      getFaq:builder.query<
      CommonResponseType & {data:FAQResponse},
      {}>({
        query:({})=>({
            url:`${END_POINTS.faq}`,
            method:"GET"
        })
      }),
      deleteFaq:builder.query<
      CommonResponseType & {data:FAQResponse},
      {id:string}>({
        query:({id})=>({
            url:`${END_POINTS.faq}/${id}`,
            method:"DELETE"
        })
      }),

      
    

       

       

      


        



        

    })
})

export const {
useAddFaqMutation,
useLazyGetFaqQuery,
useLazyDeleteFaqQuery

    
}=FaqApi

