import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, LoanTenure, LoanTenureResponse, Tags, TagsResponse, UserData } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type QueryParams = {
    page: number;
    size: number;
    search?: string;
  };

export const loanTermApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({

 getLoanTerm:builder.query<
 CommonResponseType & {data:LoanTenureResponse},
 QueryParams>({
    query:({size,page,search})=>({
        url:search===undefined?`${END_POINTS.loanTenure}?page=${page}&size=${size}`:`${END_POINTS.loanTenure}?search=${search}&page=${page}&size=${size}`,
        method:"GET"
    })
 }),
 getAllLoanTerm:builder.query<
 CommonResponseType & {data:LoanTenureResponse},
 {}>({
    query:({})=>({
        url:`${END_POINTS.loanTenure}`,
        method:"GET"
    })
 }),
 addLoanTerm:builder.mutation<
 CommonResponseType & {data:LoanTenure},
 {body:CommonBody}>({
    query:({body})=>({
        url:`${END_POINTS.loanTenure}`,
        method:"POST",
        body
    })
 }),
 updateLoanTerm:builder.mutation<
 CommonResponseType & {data:LoanTenure},
 {body:CommonBody,id:string}>({
    query:({body,id})=>({
        url:`${END_POINTS.loanTenure}/${id}`,
        method:"PUT",
        body
    })
 }),
 deleteTerm:builder.query<
 CommonResponseType & {data:LoanTenure},
 {id:string}>({
    query:({id})=>({
        url:`${END_POINTS.loanTenure}/${id}`,
        method:"DELETE",
        
    })
 }),

 getAllLoanTermProduct:builder.query<
 CommonResponseType & {data:LoanTenureResponse},
 {}>({
    query:({})=>({
        url:`${END_POINTS.loanTenure}?isBlocked=false`,
        method:"GET"
    })
 }),
   

  
    
   

  }),
});

export const {
   useLazyGetLoanTermQuery,
   useAddLoanTermMutation,
   useUpdateLoanTermMutation,
   useLazyDeleteTermQuery,
   useLazyGetAllLoanTermQuery,
   useLazyGetAllLoanTermProductQuery
} =loanTermApi;
