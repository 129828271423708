import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import moment from "moment";

const EditProperties = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();

  const [propertyType, setPropertyType] = useState<string>("Private");

  const handlePropertyType = (event: SelectChangeEvent) => {
    setPropertyType(event.target.value as string);
  };

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Edit Property</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-listed-properties");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Property Name
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Property Name"
                      className="text_field"
                      id="title"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Location</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="location"
                      variant="outlined"
                      fullWidth
                      placeholder="Location"
                      className="text_field"
                      id="location"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">Category</Typography>
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={propertyType}
                        onChange={handlePropertyType}
                        sx={{ minWidth: "250px" }}
                      >
                        <MenuItem value="Private">Home</MenuItem>
                        <MenuItem value="Residential">Villa</MenuItem>
                        <MenuItem value="Commercial">Resort</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Max time limit for a booking
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      placeholder=" Max time limit"
                      className="text_field"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Per hour Rental price Per Person
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      placeholder="Per hour Rental price Per Person"
                      className="text_field"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Commision Charges
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      placeholder=" Commision Charges"
                      className="text_field"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Operating Hours
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      placeholder="Operating Hours"
                      className="text_field"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      About the property
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="About the property"
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={4}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default EditProperties;
