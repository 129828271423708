import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useSetPasswordMutation } from "../../../services/auth";
import { showError, showToast } from "../../../constants/toast";
import { removeFromStorage } from "../../../constants/storage";
import { getTempToken, temporaryToken } from "../../../reducers/authSlice";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { STORAGE_KEYS } from "../../../constants/storageKeys";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";
import { log } from "console";
const ResetPassword = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [setPassword]=useSetPasswordMutation()
  const dispatch = useAppDispatch();
  const tempToken = useAppSelector(getTempToken)
  console.log(tempToken,"temp")

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleshowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .label("Password")
        .required("Password is required.")
        .matches(
          /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character. "
        )
        // .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
        //   "Enter a strong password")
        .min(6, "Password must be at least 6 characters"),

      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match.")
        .required("Confirm password is required."),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);

      let data = {
        password: formik.values.password,
      };
      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

        const response = await setPassword({
          body:encryptedBody
        }).unwrap();
        console.log(response,"set")
        if (response?.statusCode === 200) {
          showToast("Password reset successfully" || "");
          removeFromStorage(STORAGE_KEYS.tempToken);
          navigate("/");
          dispatch(
            temporaryToken({
              tempToken: null,
            })
          );
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error, "errorrr");
      }
    
      formik.setSubmitting(false);
    }

  });

  const handleSubmit=async()=>{
    let data = {
      password: formik.values.password,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const response = await setPassword({
        body:encryptedBody
      }).unwrap();
      console.log(response,"set")
     
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error, "errorrr");
    }
  }

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => navigate("/forgotpassword")}
              sx={{ p: 0, position: "absolute", left: 0, color: "#1d1d1d" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" sx={{ color: "#1d1d1d" }}>
              Reset Password
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Password</Typography>
              <TextField
              className="text_field"
                sx={{ m: 0 }}
                hiddenLabel
                placeholder="Password"
                fullWidth
                margin="normal"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <Typography className="custom_label">Confirm Password</Typography>
              <TextField
                sx={{ m: 0 }}
              className="text_field"

                hiddenLabel
                placeholder="Confirm Password"
                fullWidth
                margin="normal"
                name="confirmPassword"
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                type={showConfirmPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleshowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                
              >
                Send
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default ResetPassword;
