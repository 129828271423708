import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import {
  CommonBody,
  DocsRes,
  DocumentData,
  Loan,
  LoanCreditDetails,
  LoanResponse,
  stateMentResponse,
} from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type QueryParams = {
  page: number;
  size: number;
  search: string;
  type?: number;
  userId?: string;
};

export const LoanAPi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getLoan: builder.query<
      CommonResponseType & { data: LoanResponse },
      QueryParams
    >({
      query: ({ search, type, page, size }) => ({
        url: `${END_POINTS.loanRequest}?search=${search}&loanStatus=${type}&page=${page}&size=${size}`,
        method: "GET",
      }),
    }),
    getLoanByUser: builder.query<
      CommonResponseType & { data: LoanResponse },
      QueryParams
    >({
      query: ({ search, type, page, size, userId }) => ({
        url: `${END_POINTS.loanRequest}?userId=${userId}&search=${search}&loanStatus=${type}&page=${page}&size=${size}`,
        method: "GET",
      }),
    }),
    getLoanById: builder.query<
      CommonResponseType & { data: LoanResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.loanRequest}/${id}`,
        method: "GET",
      }),
    }),

    updateLoan: builder.mutation<
      CommonResponseType & { data: Loan },
      { id: string; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.loanRequest}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteLoan: builder.query<
      CommonResponseType & { data: Loan },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.loanRequest}/${id}`,
        method: "DELETE",
      }),
    }),

    getLoanDocs: builder.query<
      CommonResponseType & { data: DocsRes },
      { id: string; step: number }
    >({
      query: ({ id, step }) => ({
        url: `${END_POINTS.document}?loanId=${id}&step=${step}`,
      }),
    }),

    getAmountStatement: builder.query<
      CommonResponseType & { data: stateMentResponse },
      { id: string; page: number }
    >({
      query: ({ id, page }) => ({
        url: `${END_POINTS.amountStatement}?loanId=${id}&page=${page}&size=${100}`,
        method: "GET",
      }),
    }),
    updateStatement: builder.mutation<
      CommonResponseType & { data: any },
      { id: string; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.amountStatement}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    getLoanCredit: builder.query<CommonResponseType & { data: LoanCreditDetails }, {body:CommonBody}>({
      query: ({body}) => ({
        url: `${END_POINTS.creditSimulator}?userType=admin`,
        method: "POST",
        body
      }),
    }),
  }),
});

export const {
  useLazyGetLoanQuery,
  useUpdateLoanMutation,
  useLazyDeleteLoanQuery,
  useLazyGetLoanByIdQuery,
  useLazyGetLoanDocsQuery,
  useLazyGetAmountStatementQuery,
  useUpdateStatementMutation,
  useLazyGetLoanCreditQuery
} = LoanAPi;
