import React, { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";
import { toast } from "react-toastify";
import { firebaseCloudMessaging } from "./utils/firebase";
import { Socket_URL, socket } from "./utils/socket";
import { useAppSelector } from "./hooks/store";
import { getToken } from "./reducers/authSlice";
import LanguageContextProvider from "./context/languageContext";

function App() {
  const socketToken = useAppSelector(getToken);

  function onConnect() {
    console.log("connected");
  }

  function onDisconnect() {
    console.log("disconnected");
  }

  function onError(error: any) {
    console.error("Socket connection error:", error);
  }

  useEffect(() => {
    if (socketToken?.length) {
      const modifiedURL = `${Socket_URL}?token=${socketToken || ""}`;
      socket.io.uri = modifiedURL;

      if (!socket?.connected) {
        socket.connect();
      }

      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("error", onError);

      return () => {
        socket.disconnect();
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("error", onError);
      };
    } else {
      socket.disconnect();
    }
  }, [socketToken]);

  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("fcm_Token", fcmToken);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setToken();

    // const playNotificationSound = () => {
    //   const audio = new Audio("/static/sounds/notification_sound.mp3"); // Specify the path to your notification sound
    //   audio.play();
    // };

    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          // playNotificationSound();
          return toast(
            <div style={{ height: "100%" }}>
              <div
                style={{ color: "#820000" }}
                // onClick={() => navigate("/notifications")}
              >
                {event?.data?.data?.title}
              </div>
              <div
                style={{ color: "#1d1d1d", paddingTop: "10px" }}
                // onClick={() => navigate("/notifications")}
              >
                {event?.data?.data?.message}
              </div>
            </div>
          );
          return event;
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then(function (notificationPerm) {
          notificationPerm.onchange = function () {
            if (notificationPerm.state === "granted") {
              setToken();
            }
          };
        });
    }
    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);
  return   <LanguageContextProvider>
    <Routing />;
    </LanguageContextProvider>
}

export default App;
