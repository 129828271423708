export const esp = {
  faq: {
    question: "Pregunta",
    answer: "Respuesta",
    delete: "Eliminar",
    manage: "Manage Faqs",
    added: "FAQ agregada",
  },
  analytics: {
    reportAndanalytics: "Informes y Análisis",
    users: "Usuarios",
    daily: "Diario",
    monthly: "Mensual",
    yearly: "Anual",
    weekly: "Semanal",
    exportCSV: "Exportar CSV",
    Select: "Seleccionar",
    loanRequest: "Solicitudes de Credito",
    csvSelect: "Please select CSV to download",
  },
  changePassword: {
    title: "Cambiar Contraseña",
    oldPassword: "Contraseña Antigua",
    newPassword: "Nueva Contraseña",
    confirmPassword: "Confirmar Contraseña",
    oldPasswordRequired: "Se requiere la contraseña antigua",
    newPasswordRequired: "Se requiere una nueva contraseña",
    newPasswordDifferent: "La nueva contraseña no puede ser igual a la antigua",
    passwordCriteria:
      "La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial",
    passwordsMustMatch: "Las contraseñas deben coincidir",
    confirmPasswordRequired: "Se requiere confirmar la contraseña",
    oldPasswordPlaceholder: "Ingresa la contraseña antigua",
    newPasswordPlaceholder: "Ingresa la nueva contraseña",
    confirmPasswordPlaceholder: "Confirma tu contraseña",
    submit: "Enviar",
    success: "Contraseña cambiada con éxito",
  },
  forgotPassword: {
    title: "Olvidé mi Contraseña",
    emailLabel: "Dirección de Correo Electrónico",
    emailPlaceholder: "Ingrese su dirección de correo electrónico",
    emailRequired: "El correo electrónico es obligatorio",
    emailInvalid: "Ingrese una dirección de correo electrónico válida",
    sendButton: "Enviar",
    successMessage: "Correo de verificación enviado con éxito",
  },
  login: {
    title: "Iniciar sesión en QFI Admin",
    emailLabel: "Dirección de correo electrónico",
    emailPlaceholder: "Dirección de correo electrónico",
    emailRequired: "El correo electrónico es obligatorio",
    emailInvalid: "Introduce una dirección de correo electrónico válida",
    passwordLabel: "Contraseña",
    passwordPlaceholder: "Contraseña",
    passwordRequired: "La contraseña es obligatoria",
    passwordMinLength: "La contraseña debe tener al menos 6 caracteres",
    rememberMe: "Recuérdame",
    forgotPassword: "¿Olvidaste tu contraseña?",
    success: "Inicio de sesión exitoso.",
    error: "Error",
  },
  dashboard: {
    title: "Tablero",
    totalCustomer: "Cliente Total",
    preCustomer: "Total de Solicitudes Precalificadas",
    newCustomer: "Nuevo Cliente",
    totalLoanRequest: "Total de Solicitudes de Préstamos",
    totalUser: "Total de Usuarios",
  },
  tableHeads: {
    sno: "S.No",
    fName: "Nombre completo",
    phone: "Número de teléfono",
    email: "Correo electrónico",
    approvalStatus: "Estado de aprobación",
    accountStatus: "Estado de la cuenta",
    actions: "Comportamiento",
    image: "Imagen",
    categoryName: "Nombre de categoría",
    status: "Estado",
    tagName: "Nombre de etiqueta",
    applicationNumber: "Número de solicitud",
    vehicleName: "Nombre del vehículo",
    userEmail: "Correo electrónico del usuario",
    loanStatus: "Estado del préstamo",
    loanDate: "Fecha del préstamo",
    loanType: "Tipo de préstamo",
    loanAmount: "Monto del préstamo",
    downPayment: "Depósito",
    amountPaid: "Monto pagado",
    pendingAmount: "Monto Pendiente",
    interestRate: "Tasa de interés",
    emiAmount: "Monto del EMI",
    totalInstallment: "Cuota Total",
    installmentPaid: "Pago a plazos",
    remainingInstallment: "Cuota restante",
    stepOneDate: "Paso 1 Fecha de carga del documento",
    stepTwoDate: "Paso 2 Fecha de carga del documento",
    stepOneApproveDate: "Paso 1 Fecha de aprobación del documento",
    stepTwoApproveDate: "Paso 2 Fecha de aprobación del documento",
    loanComplete: "Préstamo completado en",
    carImage: "Imagen del coche",
    name: "Nombre",
    category: "Categoría",
    tag: "Etiqueta",
    model: "Modelo",
    price: "Precio",
    date: "Fecha",
    userDetails: "Detalles del usuario",
    carName: "Nombre del auto",
    currentStatus: "Estado actual",
    chatStatus: "Estado del chat",
    drag: "Arrastrar",
    criteriaName: "Nombre del criterio",
    factors: "Factores",
    interestRatePa: "Tasa de interés (p.a)",
    fieldName: "Nombre del campo",
    fieldType: "Tipo de campo",
    stepType: "Tipo de paso",
    imageType: "Tipo de imagen",
    loanTerm: "Plazo del préstamo",
    phoneNumber: "Número de teléfono",
    address: "DIRECCIÓN",
    userName: "Nombre de usuario",
    queries: "Consultas",
    title: "Título",
    bannerImage: "Imagen de banner",
    bannerTitle: "Título del banner",
    notificationTitle: "Título de la notificación",
    notificationMessage: "Mensaje de notificación",
    role: "Role",
    paymentMode: "Modo de pago",
    viewMode: "Modo de visualización",
    description: "Descripción",

    factorName: "Nombre del Factor",
    score: "Puntuación",
    delete: "Borrar",
  },
  tabs: {
    all: "Todo",
    new: "Nuevo",
    existing: "Existente",
    pending: "Pendiente",
    approved: "Aprobado",
    rejected: "Rechazado",
    completed: "Terminado",
    admin: "Administración",
    user: "Usuario",
    queriesRecieved: "Consultas recibidas",
    complaints: "Quejas/Disputa",
    contactus: "Contacta con nosotros",
    aboutUs: "Sobre nosotros",
    privacy: "política de privacidad",
    termsAndCondition: "Términos y condiciones",
  },

  buttons: {
    addCustomer: "Agregar cliente",
    exportCsv: "Exportar CSV",
    addCategory: "Agregar categoría",
    addTag: "Agregar etiqueta",
    addCar: "Añadir coche",
    clearFilter: "Borrar filtro",
    addCriteriaAdmin: "Agregar criterios para administrador",
    addCriteriaSignUp: "Agregar criterios para registrarse",
    addInterestRate: "Agregar tasa de interés",
    save: "Ahorrar",
    addLoanTerm: "Agregar plazo de préstamo",
    addServiceStation: "Agregar estación de servicio",
    addTestimonial: "Agregar testimonio",
    addBanner: "Agregar banner",
    addNotification: "Agregar notificación",
    addSubAdmin: "Agregar subadministrador",
    addPaymentWay: "Agregar forma de pago",
    addPaymentDetails: "Agregar detalles de pago",
    back: "Atrás",
    cancel: "Cancelar",
    viewDetails: "Ver detalles",
  },
  textFields: {
    fname: "Nombre completo",
    email: "Correo electrónico",
    phone: "Teléfono",
    businessName: "Nombre comercial",
    positionWithinCompany: "Puesto dentro de la empresa",
    website: "Sitio web",
    address: "DIRECCIÓN",
    addressOne: "Dirección Línea 1",
    addressTwo: "Línea de dirección 2",
    state: "Estado",
    city: "Ciudad",
    landMark: "Marca",
    search: "Buscar",
    categoryName: "Nombre de categoría",
    productImage: "Agregar imagen del producto",
    selectCategory: "Seleccionar categoría",
    selectTag: "Seleccionar etiqueta",
    addTag: "Agregar etiqueta",
    editTag: "Editar etiqueta",
    warrntyPeriod: "Período de garantía",
    tax: "Impuesto",
    documentCost: "Costo del documento",
    insuranceCost: "Costo del seguro",
    minimumDownPayment: "Pago inicial mínimo",
    maximumDownPayment: "Pago inicial máximo",
    selectLoanTenure: "Seleccione la tenencia del préstamo",
    selectInterestRate: "Seleccionar tasa de interés",
    addCriteria: "Agregar criterios",
    editCriteria: "Criterios de edición",
    addInterestRate: "Agregar tasa de interés",
    editInterestRate: "Editar tasa de interés",
    selectStep: "Seleccionar paso",
    addLoanTerm: "Agregar plazo de préstamo",
    editLoanTerm: "Editar plazo del préstamo",

    name: "Nombre",
    title: "Título",
    description: "Descripción",
    bannerTitle: "Título del banner",
    banner: "Bandera",
    notificationTitle: "Título de la notificación",
    notificationTitleSpanish: "Título de la Notificación (Español)",
    notificationMessage: "Mensaje de notificación",
    notificationMessageSpanish: "Mensaje de notificación (español)",
    selectReceiver: "Seleccionar receptor",
    sendToAll: "Enviar a todos",
    firstName: "Nombre de pila",
    lName: "Apellido",
    addPaymentWay: "Agregar forma de pago",
    miminumDays: "Límite mínimo de días para volver a solicitar el préstamo",
    minimumCredit: "Puntaje de crédito mínimo",
  },
  header: {
    manageCustomer: "Administrar cliente",
    manageCategories: "Administrar categorías",
    manageTags: "Administrar etiquetas",
    loanReport: "Informe de préstamo",
    manageCars: "Administrar autos",
    manageLoans: "Administrar préstamos",
    creditScore: "Puntaje de crédito",
    manageInterest: "Administrar intereses",
    manageForm: "Administrar formularios",
    manageLoanTerm: "Administrar el plazo del préstamo",
    customerSupport: "Atención al cliente",
    manageTestimonials: "Gestionar testimonios",
    manageBanner: "Administrar banner",
    manageNotifications: "Administrar notificaciones",
    manageSubAdmin: "Administrar subadministrador",
    manageFaq: "Administrar preguntas frecuentes",
    paymentWays: "Administrar formas de pago",
    serviceStation: "Manage Service Station",
    manageCms: "Manage CMS",
      setting: "Ajustes"
  },
};
